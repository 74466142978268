import React, { Component } from 'react';

import Editable from '../Editable';
import Hideable from '../Hideable';
import HideableControl from '../Hideable/HideableControl';
import { ExportActions } from 'actions/export';

import Logo from '../../svg_icons/cs_full_logo.svg';

import './singleCompLandscape.nomodule.css';
import { CompType } from 'types/comp';
import { defaultTheme } from '@compstak/ui-kit';
import styled from 'styled-components';
import { SalesComp, LeaseComp, PortfolioItem } from 'types/comp';
import { ExportDataState } from 'reducers/export';
import { MapImage } from '@compstak/maps';
import { point } from '@turf/helpers';
import { formatCsv } from 'format';
import { isSaleComp } from 'utils/compHelpers';
import { ExportPDFPage } from 'Pages/Export/Components/UI';

class HtmlChild extends React.PureComponent {
	render() {
		// @ts-expect-error TS2339: Property 'children' does not e...
		if (typeof this.props.children === 'string') {
			return (
				<span
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
					className={this.props.className || ''}
					// @ts-expect-error TS2339: Property 'children' does not e...
					dangerouslySetInnerHTML={{ __html: this.props.children }}
				/>
			);
		} else {
			return (
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
				<span className={this.props.className || ''}>
					{/* @ts-expect-error TS2339: Property 'children' does not e... */}
					{this.props.children}
				</span>
			);
		}
	}
}

interface EditableItemWithLabelProps {
	exportActions: ExportActions;
	name: string;
	item: { name: string; displayName: string };
	leaseId: number;
}

interface EditableItemWithLabelState {
	isEditing: boolean;
}

class EditableItemWithLabel extends React.PureComponent<
	EditableItemWithLabelProps,
	EditableItemWithLabelState
> {
	constructor() {
		// @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
		super();

		this.state = {
			isEditing: false,
		};
	}

	startEditing = () => {
		this.setState({
			isEditing: true,
		});
	};

	endEditing = () => {
		this.setState({
			isEditing: false,
		});
	};

	render() {
		const item = this.props.item;
		let editingClass = '';
		if (this.state.isEditing) {
			editingClass = 'editing';
		}
		const displayName =
			this.props.name === 'Tenant'
				? item.displayName.replace('Tenant ', '')
				: item.displayName;

		return (
			<li className={`editable ${editingClass}`}>
				<div className="label">
					<span className="text">
						<HideableControl
							// @ts-expect-error ts-migrate(2769) FIXME: Property 'html' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
							html={displayName}
							target={item.name}
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'hiddenFields' does not exist on type 'Re... Remove this comment to see the full error message
							hiddenFields={this.props.hiddenFields}
							hideCustomExportField={
								this.props.exportActions.hideCustomExportField
							}
							showCustomExportField={
								this.props.exportActions.showCustomExportField
							}
						/>
					</span>
				</div>

				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Hideable
					className="data"
					hideableKey={item.name}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'hiddenFields' does not exist on type 'Re... Remove this comment to see the full error message
					hiddenFields={this.props.hiddenFields}
				>
					{/* @ts-expect-error TS2322: Type '{ children: any; classNa... */}
					<Editable
						className={
							item.name !== 'comments'
								? 'editable-align-right'
								: 'editable-align-left'
						}
						noStyle={true}
						name={item.name}
						section={this.props.name}
						leaseId={this.props.leaseId}
						updateValue={this.props.exportActions.updateCustomExportValue}
						onFocus={this.startEditing}
						onBlur={this.endEditing}
						placeholder="&mdash;"
					>
						{/* @ts-expect-error TS2339: Property 'children' does not e... */}
						{this.props.children}
					</Editable>
				</Hideable>
			</li>
		);
	}
}

interface SectionProps {
	name: string;
	editable: boolean;
	sectioned: unknown;
	hiddenFields: string[] | undefined;
	exportActions: ExportActions;
	raw: { id: number; [key: string]: unknown };
	leaseId?: number;
}

class Section extends React.PureComponent<SectionProps> {
	private get isBuilding(): boolean {
		return this.props.name === 'Building';
	}

	// @ts-expect-error TS7006: Parameter 'value' implicitly h...
	private valueOrdash(value) {
		if (value === undefined) {
			return this.props.editable ? '&mdash;' : <span>&mdash;</span>;
		}
		return value;
	}

	private overwriteBuildingAddress() {
		if (this.isBuilding) {
			return formatCsv(
				['buildingAddress', 'city', 'state', 'zip'].map((name) => {
					return this.props.raw[name];
				}) as string[]
			);
		} else {
			return '';
		}
	}

	private showCompData() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'sectioned' does not exist on type 'Reado... Remove this comment to see the full error message
		return this.props.sectioned[this.props.name].map((item, i) => {
			const displayName =
				this.props.name === 'Tenant'
					? item.displayName.replace('Tenant ', '')
					: item.displayName;

			if (this.isBuilding && item.name === 'buildingAddress') {
				return (
					<li key={i}>
						<div className="label">Address</div>
						<div className="data">{this.overwriteBuildingAddress()}</div>
					</li>
				);
			}
			if (this.isBuilding && ['city', 'state', 'zip'].includes(item.name)) {
				return false;
			}
			if (this.props.editable) {
				const value = item.value || '';
				return (
					// @ts-expect-error TS2322: Type '{ children: any; item: a...
					<EditableItemWithLabel
						key={i}
						{...this.props}
						item={item}
						leaseId={this.props.leaseId || this.props.raw.id}
					>
						{value}
					</EditableItemWithLabel>
				);
			}
			if (
				this.props.hiddenFields &&
				this.props.hiddenFields.includes(item.name)
			) {
				return false;
			}
			return (
				<li key={i}>
					<div className="label">{displayName}</div>
					{/* @ts-expect-error ts-migrate(2769) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message */}
					<HtmlChild className="data">{this.valueOrdash(item.value)}</HtmlChild>
				</li>
			);
		});
	}

	render() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'sectioned' does not exist on type 'Reado... Remove this comment to see the full error message
		if (!this.props.sectioned[this.props.name]) {
			return <span />;
		}

		return (
			<div
				className={
					'comp-detail-section section-' + this.props.name.toLowerCase()
				}
			>
				<h3>{this.props.name}</h3>
				{/* @ts-expect-error TS2339: Property 'children' does not e... */}
				{this.props.children}
				<ul className="comp-data-items">{this.showCompData()}</ul>
			</div>
		);
	}
}

// @ts-expect-error TS7031: Binding element 'sectioned' im...
function LeaseColumns({ sectioned, editable, exportData, exportActions, raw }) {
	return (
		<div>
			<div className="column">
				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Section
					name="Lease"
					sectioned={sectioned}
					editable={editable}
					hiddenFields={exportData.hiddenFields}
					exportActions={exportActions}
					leaseId={raw.id}
				/>
			</div>

			<div className="column">
				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Section
					name="Dates"
					sectioned={sectioned}
					editable={editable}
					hiddenFields={exportData.hiddenFields}
					exportActions={exportActions}
					leaseId={raw.id}
				/>
				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Section
					name="Notes"
					sectioned={sectioned}
					editable={editable}
					hiddenFields={exportData.hiddenFields}
					exportActions={exportActions}
					leaseId={raw.id}
				/>

				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Section
					name="Space"
					sectioned={sectioned}
					editable={editable}
					hiddenFields={exportData.hiddenFields}
					exportActions={exportActions}
					leaseId={raw.id}
				/>
				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Section
					name="Landlord"
					sectioned={sectioned}
					editable={editable}
					hiddenFields={exportData.hiddenFields}
					exportActions={exportActions}
					leaseId={raw.id}
				/>
			</div>

			<div className="column">
				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Section
					name="Tenant"
					sectioned={sectioned}
					editable={editable}
					hiddenFields={exportData.hiddenFields}
					exportActions={exportActions}
					leaseId={raw.id}
				/>
				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Section
					name="Tenant Brokerage"
					sectioned={sectioned}
					editable={editable}
					hiddenFields={exportData.hiddenFields}
					exportActions={exportActions}
					leaseId={raw.id}
				/>
			</div>
		</div>
	);
}

// @ts-expect-error TS7031: Binding element 'sectioned' im...
function SaleColumns({ sectioned, editable, exportData, exportActions, raw }) {
	return (
		<div>
			<div className="column">
				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Section
					name="Transaction Details"
					sectioned={sectioned}
					editable={editable}
					hiddenFields={exportData.hiddenFields}
					exportActions={exportActions}
					leaseId={raw.id}
				/>
			</div>
			<div className="column">
				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Section
					name="Buyer / Seller"
					sectioned={sectioned}
					editable={editable}
					hiddenFields={exportData.hiddenFields}
					exportActions={exportActions}
					leaseId={raw.id}
				/>
			</div>
			<div className="column">
				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Section
					name="Notes"
					sectioned={sectioned}
					editable={editable}
					hiddenFields={exportData.hiddenFields}
					exportActions={exportActions}
					leaseId={raw.id}
				/>
			</div>
		</div>
	);
}

export type PortfolioSalesComp = SalesComp &
	PortfolioItem & {
		indexInPortfolio: number;
		oneOfProperties: number;
	};

export type SinglePageComp = LeaseComp | PortfolioSalesComp;

interface SingleCompLandscapeProps<T> {
	editable: boolean;
	logoPicker: JSX.Element | unknown;
	compType: CompType;
	disclaimerText: string;
	exportData: ExportDataState;
	// lease is used like a comp(can be sale or lease)
	lease: T;
	raw: T;
	pinLabel: number;
	sectioned: unknown;
	exportActions: ExportActions;
}

export default class SingleCompLandscape extends Component<
	SingleCompLandscapeProps<SinglePageComp>
> {
	constructor(props: SingleCompLandscapeProps<SinglePageComp>) {
		super(props);
	}

	logo() {
		if (this.props.editable) {
			return this.props.logoPicker ? this.props.logoPicker : false;
		} else {
			return this.props.exportData.companyLogo ? (
				<img
					width="60px"
					height="60px"
					src={this.props.exportData.companyLogo}
				/>
			) : (
				false
			);
		}
	}

	renderMap() {
		const { lat, lon } = this.props.raw.geoPoint;
		return (
			<div className="map-wrap">
				<MapImage
					position={{
						type: 'center',
						latitude: lat,
						longitude: lon,
					}}
					pinPoints={[point([lon, lat])]}
					getPinLabel={() => {
						return this.props.pinLabel < 100
							? this.props.pinLabel.toString()
							: undefined;
					}}
					width={284}
					height={190}
				/>
			</div>
		);
	}

	render() {
		const lease = this.props.lease;

		let hiddenFields: unknown[] = [];
		// @ts-expect-error TS2339: Property 'executionQuarter' do...
		let summaryText = `${lease.executionQuarter} • ${lease.spaceType} • Class ${lease.buildingClass} • ${lease.transactionSize} @ ${lease.startingRent} PSF`;

		if (this.props.exportData.hiddenFields) {
			const summaryTextArr: string[] = [];

			const summaryFields = [
				'executionQuarter',
				'spaceType',
				'buildingClass',
				'transactionSize',
				'startingRent',
			];

			hiddenFields = this.props.exportData.hiddenFields;

			let transactionSizeOrStartingRentCovered = false;

			summaryFields.forEach((item) => {
				if (!hiddenFields.includes(item)) {
					if (item === 'buildingClass') {
						summaryTextArr.push('Class ' + lease.buildingClass);
					} else if (item === 'transactionSize' || item === 'startingRent') {
						if (transactionSizeOrStartingRentCovered === false) {
							if (
								!hiddenFields.includes('transactionSize') &&
								!hiddenFields.includes('startingRent')
							) {
								transactionSizeOrStartingRentCovered = true;
								summaryTextArr.push(
									// @ts-expect-error TS2339: Property 'startingRent' does n...
									`${lease.transactionSize} @ ${lease.startingRent} PSF`
								);
							} else if (
								!hiddenFields.includes('transactionSize') &&
								hiddenFields.includes('startingRent')
							) {
								transactionSizeOrStartingRentCovered = true;
								summaryTextArr.push(`${lease.transactionSize} SqFt`);
							} else {
								transactionSizeOrStartingRentCovered = true;
								// @ts-expect-error TS2339: Property 'startingRent' does n...
								summaryTextArr.push(`${lease.startingRent} PSF`);
							}
						}
					} else {
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						summaryTextArr.push(lease[item]);
					}
				}
			});

			summaryText = summaryTextArr.join(' • ');
		}

		return (
			<ExportPDFPage className="page">
				<header>
					<div className="left">
						{this.props.editable ? (
							// @ts-expect-error TS2322: Type '{ children: string | und...
							<Editable
								name="title"
								updateValue={
									this.props.exportActions.updateCustomExportMetaData
								}
								className="inline"
							>
								{this.props.exportData.title}
							</Editable>
						) : (
							<HtmlChild>{this.props.exportData.title}</HtmlChild>
						)}
					</div>
					{/* @ts-expect-error TS2322: Type '{}' is not assignable to... */}
					<div className="right">{this.logo()}</div>
				</header>

				<div className="intro-block">
					<div className="info">
						<div>
							{/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"tenantName"' is not assignable ... Remove this comment to see the full error message */}
							{hiddenFields.includes('tenantName') ? '' : lease.tenantName}
						</div>
						<div>
							{hiddenFields.includes('buildingName') ? '' : lease.buildingName}
						</div>
						{this.props.compType === 'lease' ? (
							<div>
								<div>
									{lease.buildingAddress} | {lease.city}, {lease.state}{' '}
									{lease.zip}
								</div>
								<BlueText>Property #{this.props.pinLabel}</BlueText>
							</div>
						) : (
							<div>
								<div>{lease.buildingAddress}</div>
								<div>
									{lease.city}, {lease.state} {lease.zip}
								</div>
								{isSaleComp(this.props.raw) && this.props.raw.isPortfolio ? (
									<BlueText>
										{this.props.raw.indexInPortfolio} of{' '}
										{this.props.raw.oneOfProperties} properties (Property #
										{this.props.pinLabel})
									</BlueText>
								) : (
									<BlueText>Property #{this.props.pinLabel}</BlueText>
								)}
							</div>
						)}
					</div>
					{this.props.compType === 'lease' ? (
						<div className="summary">{summaryText}</div>
					) : null}
				</div>

				<div className="columns">
					<div className="column">
						<Section
							name={this.props.compType === 'lease' ? 'Building' : 'Property'}
							editable={this.props.editable}
							sectioned={this.props.sectioned}
							raw={this.props.raw}
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'exportData' does not exist on type 'Read... Remove this comment to see the full error message
							hiddenFields={this.props.exportData.hiddenFields}
							exportActions={this.props.exportActions}
						>
							{this.renderMap()}
						</Section>
					</div>
					{this.props.compType === 'lease' ? (
						<LeaseColumns {...this.props} />
					) : (
						<SaleColumns {...this.props} />
					)}
				</div>
				<footer>
					<div className="footer-content">
						<span className="text">Data provided by</span>
						<a href="http://compstak.com/">
							<Logo height="20px" />
						</a>
						<div className="disclaimer-text">{this.props.disclaimerText}</div>
					</div>
					<div className="id-link">ID: {this.props.raw.id}</div>
				</footer>
			</ExportPDFPage>
		);
	}
}

const BlueText = styled.div`
	color: ${defaultTheme.colors.blue.blue500};
`;
