import { useDeleteSavedSearchMutation } from 'api/savedSearches/useDeleteSavedSearchMutation';
import { SavedSearch } from 'api/savedSearches/useSavedSearchesQuery';
import { useModal } from 'providers/ModalProvider';
import {
	ModalTitle,
	ModalParagraph,
	ModalButtons,
	ModalButton,
} from 'Components/Modals/common/UI';
import { useToast } from 'providers/ToastProvider';
import IconTrashCan from 'ui/svg_icons/trash_can.svg';
import styled from 'styled-components';

type Props = {
	savedSearch: SavedSearch;
};
export const DeleteSavedSearchModal = ({ savedSearch }: Props) => {
	const { mutate: deleteSavedSearch } = useDeleteSavedSearchMutation();

	const { closeModal } = useModal();

	const { addToast } = useToast();

	return (
		<div>
			<ModalTitle>Delete Your Saved Search</ModalTitle>
			<ModalParagraph>
				Clicking Delete Search will permanently remove your saved search,{' '}
				<b>{savedSearch.name}</b>.
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Cancel
				</ModalButton>
				<ModalButton
					onClick={() => {
						deleteSavedSearch(
							{ id: savedSearch.id },
							{
								onSuccess: () => {
									closeModal();
									addToast({
										title: `Successfully deleted ${savedSearch.name}!`,
										type: 'info',
									});
								},
								onError: () => {
									addToast({
										title: `Error, saved search not deleted.`,
										type: 'error',
									});
								},
							}
						);
					}}
					variant="error"
					icon={<StyledIconTrashCan />}
				>
					Delete Search
				</ModalButton>
			</ModalButtons>
		</div>
	);
};

const StyledIconTrashCan = styled(IconTrashCan)`
	fill: ${({ theme }) => theme.colors.white.white};
	width: 1rem;
	height: 1rem;
	margin-right: 4px;
`;
