import { styled } from 'styled-components';
import { BenchmarkingViewHeader } from './BenchmarkingViewHeader';
import { BenchmarkingTable } from './BenchmarkingTable/BenchmarkingTable';
import { useState } from 'react';
import { PortfolioFiltersProvider } from 'PortfolioAnalytics/PortfolioFiltersProvider';

export const BenchmarkingView = () => {
	const [elementToExport, setElementToExport] = useState<HTMLDivElement | null>(
		null
	);

	return (
		<PortfolioFiltersProvider portfolioMarkets={[]}>
			<Container ref={setElementToExport}>
				<BenchmarkingViewHeader elementToExport={elementToExport} />
				<BenchmarkingTable />
			</Container>
		</PortfolioFiltersProvider>
	);
};

const Container = styled.div`
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	height: 100%;
	width: 100%;
	background-color: ${(p) => p.theme.colors.white.white};

	overflow: auto;
`;
