import { Portfolio, PortfolioV2 } from '../../types';

export const convertPortfolioToPortfolioV2 = (
	portfolio: Portfolio
): PortfolioV2 => {
	let propertyCount = 0;
	let activeLeaseCount = 0;

	portfolio.markets.map((market) => {
		propertyCount += market.propertyCount;
		activeLeaseCount += market.activeLeaseCount;
	});

	const markets = portfolio.markets.map((market) => {
		return {
			marketId: market.marketId,
			market: market.market,
			submarkets: market.submarkets,
		};
	});

	const convertedPortfolio = {
		portfolio: {
			id: portfolio.id,
			name: portfolio.name,
			description: portfolio.description,
		},
		metrics: {
			activeLeaseCount: activeLeaseCount,
			propertyCount,
		},
		markets,
	};

	return convertedPortfolio;
};
