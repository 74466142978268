import { useEffect } from 'react';
import { SEARCH_BY_VIEW_ROUTES, replace } from 'router';
import {
	filtersFromServerJSON,
	filtersToQueryString,
} from 'models/filters/util';
import { CompType } from 'types';
import { useMarkets } from 'hooks/useMarkets';
import { Spinner } from '@compstak/ui-kit';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { RouteComponentProps } from 'router/migration/types';
import { useUserQuery } from 'api';
import { useSavedSearchesQuery } from 'api/savedSearches/useSavedSearchesQuery';
import { useViewSavedSearchMutation } from 'api/savedSearches/useViewSavedSearchMutation';
import { useSavedSearchContext } from 'Components/SavedSearches/SavedSearchProvider';

type SearchRedirectProps = RouteComponentProps<
	{},
	{ savedSearchId?: string }
> & { route: { compType: CompType } };

const DEFAULT_VIEW = 'list';

export const SearchRedirectV2 = ({
	route: { compType },
	location,
}: SearchRedirectProps) => {
	const savedSearchId = location.query.savedSearchId;
	const { data: savedSearches } = useSavedSearchesQuery();
	const { data: user } = useUserQuery();
	const markets = useMarkets();

	const { mutate: viewSavedSearch } = useViewSavedSearchMutation();

	const { setSavedSearch } = useSavedSearchContext();

	useEffect(() => {
		if (!savedSearches) return;

		const savedSearch = savedSearches?.find(({ id }) => id === savedSearchId);

		if (savedSearch) {
			setSavedSearch(savedSearch);

			let filters = filtersFromServerJSON(
				compType,
				markets,
				savedSearch.filters
			);
			filters = mergeFilters(filters, {
				sortDirection: savedSearch.sortAsc ? 'asc' : 'desc',
				sortField: savedSearch.sortProperty,
			});
			const queryString = filtersToQueryString(filters);

			viewSavedSearch({ id: savedSearch.id });

			replace(
				`${SEARCH_BY_VIEW_ROUTES[compType].toHref({ view: DEFAULT_VIEW })}?${queryString}`
			);
			return;
		}

		replace(
			`${SEARCH_BY_VIEW_ROUTES[compType].toHref({ view: DEFAULT_VIEW })}${location.search}`
		);
	}, [
		savedSearchId,
		savedSearches,
		user,
		location.pathname,
		location.search,
		compType,
		markets,
		viewSavedSearch,
		setSavedSearch,
	]);

	return <Spinner size="xl" isCentered />;
};
