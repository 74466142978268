import { AddToPortfolioDialogSelector } from 'Components/AddToPortfolioDialog/AddToPortfolioDialogSelector';
import { useState } from 'react';
import { PropertyComp } from 'types';
import { CompPopupActionButton } from './UI';

type Props = {
	property: PropertyComp;
};

export const AddToPortfolioButton = ({ property }: Props) => {
	const [isAddToPortfolioDialogOpen, setIsAddToPortfolioDialogOpen] =
		useState(false);

	return (
		<>
			<CompPopupActionButton
				data-qa-id="add-to-portfolio-btn"
				className="blue single-child"
				onClick={() => setIsAddToPortfolioDialogOpen(true)}
			>
				Add to Portfolio
			</CompPopupActionButton>
			{isAddToPortfolioDialogOpen && (
				<AddToPortfolioDialogSelector
					closeDialog={() => setIsAddToPortfolioDialogOpen(false)}
					propertyIds={[property.id]}
				/>
			)}
		</>
	);
};
