import { Flex } from '@compstak/ui-kit';
import { useEffect, useMemo, useState } from 'react';

import {
	StyledAnalysis,
	StyledChip,
	StyledIconThumbUp,
	StyledIconThumbDown,
	ReadMoreButton,
	SpaceTypesButtonsContainer,
	SpaceTypeButton,
	StyledIconDownArrow,
	FooterSpan,
	StyledSpinner,
	SectionContainer,
	HeaderFlex,
} from './UI';
import { useExchangeDashboardMarketSummary } from 'ExchangeDashboard/hooks/marketSummary/useExchangeDashboardMarketSummary';
import { SPACE_TYPE_ID_TO_NAME, useUserQuery } from 'api';
import { ThumbStatus } from 'types/marketSummary';
import { MarketSummaryModal } from './Modals';
import Linkify from 'linkify-react';
import { useModal } from 'providers/ModalProvider';
import { OurCalculationsModal } from 'Components/Modals/OurCalculationsModal/OurCalculationsModal';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { useIsExchange } from 'hooks';
import { ExchangePanel } from 'ExchangeDashboard/UI';

const MAX_CHARACTERS = 1000;
const SEARCH_VALUE = '<host>';

export const MarketSummary = () => {
	const { filters } = useFilterFieldContext();
	const marketName = filters.market.displayName;
	const marketId = filters.market.id;
	const { data: user } = useUserQuery();
	const { openModal } = useModal();

	const isExchange = useIsExchange();

	const urlPrefix = isExchange
		? 'exchange.compstak.com'
		: 'enterprise.compstak.com';

	const {
		summaryWithFeedback,
		useDeleteMarketSummaryFeedbackMutation,
		useUpdateMarketSummaryFeedbackMutation,
	} = useExchangeDashboardMarketSummary({
		marketId,
		userId: user.id,
	});
	const { data, isLoading, isError } = summaryWithFeedback;

	const marketSummariesData = data?.summaries ?? [];

	const spaceTypeItems =
		filters.spaceTypeId &&
		filters.spaceTypeId.map((id) => SPACE_TYPE_ID_TO_NAME[id]);

	const feedbacks = useMemo(
		() => (isError ? [] : data?.feedbacks),
		[data?.feedbacks, isError]
	);

	const { mutate: updateFeedbackMutation } =
		useUpdateMarketSummaryFeedbackMutation();

	const { mutate: deleteFeedbackMutation } =
		useDeleteMarketSummaryFeedbackMutation();

	const [isExpanded, setIsExpanded] = useState(false);
	const [thumbStatus, setThumbStatus] = useState<ThumbStatus>(undefined);
	const [activeSummaryIndex, setActiveSummaryIndex] = useState(0);

	// Example: If activeSummaryIndex is 2, and the user switch on the market that exist only one space type so marketSummaries[2] will return undefined, the default value will be marketSumamries[0]
	// With the fact that the activeSummaryIndex does not change its value, ie it remains -> 2 in this case
	const activeSummary =
		marketSummariesData[activeSummaryIndex] ?? marketSummariesData[0];

	const activeSpaceTypeFeedback = feedbacks?.find(
		(feedback) => feedback.spaceType === activeSummary?.spaceType
	);

	useEffect(() => {
		setThumbStatus(activeSpaceTypeFeedback?.positive);
	}, [activeSpaceTypeFeedback?.positive, feedbacks]);

	const content = activeSummary?.content ?? '';

	const showFirst1000Characters = content
		?.slice(0, MAX_CHARACTERS)
		.trimEnd()
		.concat('...')
		.split('\\n');

	const paragraphs = (
		isExpanded || (content && content.length <= MAX_CHARACTERS)
			? content.split('\\n')
			: showFirst1000Characters
	).filter((paragraph) => paragraph !== '');

	/** Function to expand/collapse market summary content */
	const handleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	const handleFeedbackStatus = (feedback: boolean) => {
		if (activeSpaceTypeFeedback?.positive === feedback) {
			setThumbStatus(undefined);

			deleteFeedbackMutation({
				marketId,
				spaceType: activeSummary.spaceType,
				userId: user.id,
			});

			return;
		}

		setThumbStatus(feedback);

		updateFeedbackMutation({
			marketId,
			spaceType: activeSummary.spaceType,
			userId: user.id,
			body: { positive: feedback },
		});
	};

	const handleChangeThumbStatus = (isThumbUp: boolean) => {
		handleFeedbackStatus(isThumbUp);

		if (isThumbUp !== thumbStatus) {
			openModal({
				modalContent: (
					<MarketSummaryModal
						marketId={marketId}
						spaceType={activeSummary.spaceType}
						updateFeedbackMutation={updateFeedbackMutation}
						userId={user.id}
						thumbStatus={isThumbUp}
					/>
				),
			});
		}
	};

	/**  Function to change the active market summary based on index */
	const handleChangeSpaceType = (index: number) => {
		setActiveSummaryIndex(index);
	};

	const shouldShowMarketSummary =
		!spaceTypeItems ||
		spaceTypeItems.length === 0 ||
		spaceTypeItems?.some((spaceType) =>
			marketSummariesData.some((summary) => summary.spaceType === spaceType)
		);

	const firstMatchingSpaceType = spaceTypeItems?.find((spaceType) =>
		marketSummariesData.some((summary) => summary.spaceType === spaceType)
	);

	useEffect(() => {
		const index = marketSummariesData.findIndex(
			(data) => data.spaceType === firstMatchingSpaceType
		);

		if (index >= 0) {
			handleChangeSpaceType(index);
		}
	}, [filters.spaceTypeId]);

	if (isLoading) {
		return <StyledSpinner isCentered />;
	}

	return marketSummariesData.length > 0 && shouldShowMarketSummary ? (
		<SectionContainer>
			<ExchangePanel
				id="market-summary"
				title={
					<HeaderFlex>
						<span>
							Q2 2024 Market Summary <StyledChip>BETA</StyledChip>
						</span>
						<h6>
							Written for {marketName}{' '}
							{activeSummary?.spaceType ?? marketSummariesData[0].spaceType}
						</h6>
					</HeaderFlex>
				}
				cta={
					<SpaceTypesButtonsContainer>
						{marketSummariesData.map(({ spaceType }, index) => (
							<SpaceTypeButton
								key={spaceType}
								name={spaceType}
								variant={
									activeSummary?.spaceType === spaceType
										? 'primary'
										: 'secondary2'
								}
								onClick={() => handleChangeSpaceType(index)}
							>
								{spaceType}
							</SpaceTypeButton>
						))}
					</SpaceTypesButtonsContainer>
				}
			>
				<StyledAnalysis>
					<Linkify options={{ target: '_blank' }}>
						{paragraphs?.map((paragraph, index) => (
							<p key={index}>{paragraph.replaceAll(SEARCH_VALUE, urlPrefix)}</p>
						))}
					</Linkify>
				</StyledAnalysis>
				<Flex className="footer">
					<Flex alignItems="center" gap="1.5rem">
						<Flex gap="0.5rem" justifyContent="normal">
							<StyledIconThumbUp
								thumbStatus={thumbStatus}
								onClick={() => handleChangeThumbStatus(true)}
							/>
							<StyledIconThumbDown
								thumbStatus={thumbStatus}
								onClick={() => handleChangeThumbStatus(false)}
							/>
						</Flex>
						<FooterSpan
							onClick={() =>
								openModal({
									modalContent: (
										<OurCalculationsModal context="marketSummary" />
									),
								})
							}
						>
							How did we generate this?
						</FooterSpan>
					</Flex>

					<div>
						{/* // Display the "Read More/Less" button only if the market summary content is longer than MAX_CHARACTERS
						// Allows the user to expand/collapse the content */}
						{content && content.length > MAX_CHARACTERS && (
							<ReadMoreButton onClick={handleExpand}>
								<Flex gap="0.25rem" alignItems="center">
									{isExpanded ? 'Read less' : 'Read more'}
									<StyledIconDownArrow isCollapsed={isExpanded} />
								</Flex>
							</ReadMoreButton>
						)}
					</div>
				</Flex>
			</ExchangePanel>
		</SectionContainer>
	) : null;
};

MarketSummary.displayName = 'MarketSummary';
