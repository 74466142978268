import { PortfolioFormFieldsV2, usePortfolioForm } from '../PortfolioForm';
import { useCreatePortfolioMutation } from 'api';
import {
	ModalButton,
	ModalButtons,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { SuccessView } from './SuccessView';
import { AddToPortfolioProps } from './AddToPortfolioDialog';
import { useDispatch } from 'react-redux';
import { addFeedback } from 'Singletons/Feedback/actions';

export const CreateAPortfolioDialogV2 = ({
	propertyIds,
	closeDialog,
	closeCreateAPortfolioDialog,
}: AddToPortfolioProps & { closeCreateAPortfolioDialog: NoArgCallback }) => {
	const dispatch = useDispatch();
	const onError = () => {
		closeDialog();
		dispatch(
			addFeedback(
				'Adding properties to portfolio was unsuccessful',
				'error',
				null,
				5000
			)
		);
	};

	const {
		mutateAsync: createPortfolio,
		isLoading: isLoadingCreatePortfolio,
		data: createPortfolioData,
	} = useCreatePortfolioMutation({ onError });

	const portfolioForm = usePortfolioForm({});

	const isSubmitDisabled =
		portfolioForm.isFormLoading ||
		!portfolioForm.isValid ||
		isLoadingCreatePortfolio;

	const onSubmit = async () => {
		createPortfolio({
			...portfolioForm.state,
			propertyIds,
		});
	};
	return (
		<div>
			{createPortfolioData ? (
				<SuccessView
					portfolioId={createPortfolioData.id}
					closeDialog={closeDialog}
				/>
			) : (
				<>
					<ModalTitle>Create a New Portfolio</ModalTitle>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							onSubmit();
						}}
					>
						<PortfolioFormFieldsV2 {...portfolioForm} action="create" />
						<ModalButtons>
							<ModalButton
								variant="secondary"
								type="button"
								onClick={closeCreateAPortfolioDialog}
							>
								Cancel
							</ModalButton>
							<ModalButton
								type="submit"
								variant="primary"
								disabled={isSubmitDisabled}
								isLoading={isLoadingCreatePortfolio}
							>
								Create Portfolio
							</ModalButton>
						</ModalButtons>
					</form>
				</>
			)}
		</div>
	);
};
