import { Feature, Point } from '@turf/helpers';
import { LeaseExpirationDataPoint } from 'api';
import {
	MapAnalyticsMetric,
	MapAnalyticsTooltipDescription,
	MapAnalyticsTooltipTitle,
} from 'maps';
import { LeaseComp } from 'types';
import number from 'util/comp-format/src/util/number';

type Props = {
	feature: Feature<Point, LeaseExpirationDataPoint>;
	metric: MapAnalyticsMetric;
	lease: LeaseComp;
};

export const LeaseExpirationTooltip = ({ feature, metric, lease }: Props) => {
	let description = '';

	if (metric === 'sqft') {
		description = `${number(feature.properties.transactionSize)} SqFt`;
	}
	//
	else if (metric === 'leaseCount') {
		const leaseCount = feature.properties.leaseCount;

		description = `${number(leaseCount)} ${
			leaseCount === 1 ? 'Lease' : 'Leases'
		}`;
	}

	description += ' Expiring';

	return (
		<>
			<MapAnalyticsTooltipTitle>
				{lease.buildingAddressAndCity}
			</MapAnalyticsTooltipTitle>
			<MapAnalyticsTooltipDescription>
				{description}
			</MapAnalyticsTooltipDescription>
		</>
	);
};
