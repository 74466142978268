import React, {
	ChangeEventHandler,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { Suggestion, SuggestionItem } from 'api/suggestions/suggestions';

import SuggestionContainer from './Suggestions/SuggestionContainer';
import { SearchInput } from './SearchInput';
import { useDebounce } from 'use-debounce/lib';
import { CompType } from 'types/comp';
import {
	getSuggestionItemAtIndex,
	getSuggestionItemTitle,
	getSuggestionAtIndex,
	isTopResultSuggestion,
} from 'Pages/Home/Components/SearchBar/Suggestions/helpers';
import { FiltersObject } from 'models/filters/types';
import { trimString } from 'util/trimString';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useSuggestionsV2Query } from 'api/suggestions/suggestionsV2/useSuggestionsV2Query';
import styled from 'styled-components';
import { useAppSelector } from 'util/useAppSelector';
import { AddToPortfolioDialogSelector } from 'Components/AddToPortfolioDialog/AddToPortfolioDialogSelector';
import { RecommendedSearchesLayout } from './RecommendedSearches/RecommendedSearchesLayout';

const PLACEHOLDERS = {
	lease: 'Address, tenant, landlord, space types and more...',
	sale: 'Address, building name, submarket, property types and more...',
	property: 'Address, city, zip code, property types and more...',
};

type SearchBarProps = {
	compType: CompType;
	filters: FiltersObject;
	onConfirmSelection: (
		suggestion: Suggestion,
		suggestionItem: SuggestionItem
	) => void;
};

export const SearchBar = ({
	compType,
	filters,
	onConfirmSelection,
}: SearchBarProps) => {
	const [value, setValue] = useState('');
	const [debounceValue] = useDebounce(value, 300);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [selectedSuggestionItem, setSelectedSuggestionItem] = useState<
		SuggestionItem | undefined
	>(undefined);
	const [isAddToPortfolioDialogOpen, setIsAddToPortfolioDialogOpen] =
		useState(false);
	const [isInputToggled, setIsInputToggled] = useState(false);

	const mapSearchResultsProperty = useAppSelector(
		(s) => s.mapSearchResultsV2.property
	);
	const focusTypeahead = useAppSelector(
		(state) => state.searchReducer.focusTypeahead
	);

	const { data: _suggestions, isFetching } = useSuggestionsV2Query(
		{
			compType,
			marketId: getFiltersMarkets(filters).map(({ id }) => id),
			query: debounceValue,
			filters,
		},
		{
			keepPreviousData: true,
		}
	);

	const suggestions = useMemo(() => {
		return _suggestions?.filter((suggestion) => {
			if (!suggestion.suggestions.length) {
				return false;
			}

			// TODO: AP-16256 - remove this clause once BE doesn't include this suggestion by default
			if (suggestion.field === 'spaceTypeOrPropertySubtype') {
				return false;
			}

			return true;
		});
	}, [_suggestions]);

	const suggestionsLength = !suggestions
		? 0
		: suggestions.reduce((acc, field) => field.suggestions.length + acc, 0);

	const inputRef = useRef<HTMLInputElement>(null);

	useLayoutEffect(() => {
		setValue('');
		setSelectedSuggestionItem(undefined);
		setSelectedIndex(-1);
		inputRef.current?.focus();
		setIsInputToggled(false);
	}, [focusTypeahead]);

	const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
		const currentValue = event.target.value;
		setValue(currentValue);
	};

	const handleConfirmSelection = (newIndex: number) => {
		const newSelectedIndex = Math.max(
			-1,
			Math.min(newIndex, suggestionsLength - 1)
		);
		const selectedSuggestion = getSuggestionAtIndex(
			suggestions,
			newSelectedIndex
		);

		let newSelectedSuggestionItem = getSuggestionItemAtIndex(
			suggestions,
			newSelectedIndex
		);
		if (suggestions && selectedSuggestion?.field && newSelectedIndex === -1) {
			// use top match
			newSelectedSuggestionItem = suggestions[0].suggestions[0];
		}

		if (selectedSuggestion?.field && newSelectedSuggestionItem) {
			onConfirmSelection(selectedSuggestion, newSelectedSuggestionItem);
		}

		setSelectedIndex(newSelectedIndex);
		setSelectedSuggestionItem(newSelectedSuggestionItem);
		inputRef.current?.focus(); // focusing on input because suggestion click causes the suggestion to be focused
		inputRef.current?.blur(); // so blur will be actually triggered here
	};

	const moveSelection = (moveBy: number) => {
		const newValue = selectedIndex + moveBy;
		const newIndex = Math.max(-1, Math.min(newValue, suggestionsLength - 1));
		setSelectedIndex(newIndex);
		setSelectedSuggestionItem(getSuggestionItemAtIndex(suggestions, newIndex));
	};

	let completion = '';
	const trimmedValue = trimString(value);
	const firstSuggestion = suggestions?.[0];
	const showSuggestions = trimmedValue.length >= 3;
	const showRecommendedSearches =
		!showSuggestions && isInputToggled && compType === 'lease';

	if (showSuggestions && selectedIndex !== -1 && selectedSuggestionItem) {
		completion = getSuggestionItemTitle(selectedSuggestionItem);
	} else if (showSuggestions && isTopResultSuggestion(firstSuggestion)) {
		completion = getSuggestionItemTitle(firstSuggestion.suggestions[0]);
	}

	return (
		<SearchBarWrap>
			<SearchInput
				ref={inputRef}
				value={value}
				currentIndex={selectedIndex}
				placeholder={PLACEHOLDERS[compType]}
				onChange={handleChange}
				completion={completion}
				moveSelection={moveSelection}
				onConfirmSelection={handleConfirmSelection}
				onClick={() => setIsInputToggled(true)}
			/>
			{showRecommendedSearches && (
				<RecommendedSearchesLayout compType={compType} />
			)}
			{showSuggestions && (
				<SuggestionContainer
					suggestions={suggestions}
					search={trimmedValue}
					market={filters.market}
					selectedIndex={selectedIndex}
					onConfirmSelection={handleConfirmSelection}
					onAddPortfolio={() => setIsAddToPortfolioDialogOpen(true)}
					loading={isFetching}
				/>
			)}
			{isAddToPortfolioDialogOpen && (
				<AddToPortfolioDialogSelector
					closeDialog={() => setIsAddToPortfolioDialogOpen(false)}
					propertyIds={
						mapSearchResultsProperty ? [mapSearchResultsProperty.id] : []
					}
					isLoading={mapSearchResultsProperty == null}
				/>
			)}
		</SearchBarWrap>
	);
};

const SearchBarWrap = styled.div`
	flex: 1;
	&:focus-within {
		svg {
			opacity: 1;
		}
	}
	&:not(:focus-within) {
		> :not(:first-child) {
			display: none;
		}
	}
`;

export const SuggestionsContainer = styled.div`
	box-shadow: 0 2px rgba(48, 52, 65, 0.1);
	pointer-events: visiblePainted;
	max-height: 75vh;
	overflow-y: auto;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;

export const SuggestionSection = styled.div`
	h4 {
		padding: 0.5rem 1rem;
		font-weight: 500;
		letter-spacing: 0.15em;
		font-size: 10px;
		text-transform: uppercase;
		color: ${({ theme }) => theme.colors.gray.gray90};
		background: ${({ theme }) => theme.colors.gray.gray110};
	}
`;
