import { MQB, Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQueryV2 } from 'api/portfolio/portfolioById/usePortfolioByIdQueryV2';
import { routes } from 'router';
import styled from 'styled-components';
import { APP_HEADER_HEIGHT } from '../constants';
import { Dashboard } from './Dashboard';
import { NoPortfolios } from './NoPortfolios';
import { EmptyPortfolio } from './EmptyPortfolio';
import { ListView } from './ListView';
import { ListViewV2 } from './ListView/ListViewV2';
import { MapView } from './MapView';
import { MapViewV2 } from './MapView/MapViewV2';
import { PortfolioFiltersProvider } from './PortfolioFiltersProvider';
import { PortfolioSidebar } from './PortfolioSidebar';
import { useRedirectPortfolio } from './hooks/useRedirectPortfolio';
import { PortfolioLeasesFiltersProvider } from './LeaseActivity/PortfolioLeasesFiltersProvider';
import { PropertiesListAndMapViewsProvider } from './ListView/PropertiesListAndMapViewsProvider';
import { LeasesListAndMapViewProvider } from './ListView/LeasesListAndMapViewProvider';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { PortfolioAlertsStateProvider } from './PortfolioAlertsStateProvider';
import { PortfolioSidebarV2 } from './PortfolioSidebar/PortfolioSidebarV2';
import { isPortfolioEmpty } from './utils/isPortfolioEmpty';

export const PortfolioLayout = () => {
	const { portfolioRevampOverviewTabFF, portfolioRevampBenchmarkingFF } =
		useFeatureFlags();
	const ListViewComponent = portfolioRevampOverviewTabFF
		? ListViewV2
		: ListView;

	const MapViewComponent = portfolioRevampOverviewTabFF ? MapViewV2 : MapView;

	const params = routes.portfolioByIdView.useParams();

	const portfolioId = params.portfolioId
		? Number(params.portfolioId)
		: undefined;

	const { data: portfolioV2, isFetching: isFetchingPortfolioById } =
		usePortfolioByIdQueryV2({
			id: portfolioId,
		});

	const { isFetching: isRedirecting } = useRedirectPortfolio();

	const isFetching = isRedirecting || isFetchingPortfolioById;

	const content = (() => {
		if (isFetching) {
			return (
				<LoadingContainer>
					<Spinner />
				</LoadingContainer>
			);
		}

		if (!portfolioV2) {
			return <NoPortfolios />;
		}

		const portfolioId = portfolioV2.portfolio.id;

		if (isPortfolioEmpty(portfolioV2) && !portfolioRevampOverviewTabFF) {
			return <EmptyPortfolio portfolioV2={portfolioV2} />;
		}

		return (
			<PortfolioFiltersProvider
				key={portfolioId}
				portfolioMarkets={portfolioV2.markets}
			>
				<PortfolioLeasesFiltersProvider
					key={portfolioId}
					portfolioV2={portfolioV2}
				>
					<Content>
						{(params.viewType === 'map' || params.viewType === 'list') && (
							<PropertiesListAndMapViewsProvider portfolioId={portfolioId}>
								<LeasesListAndMapViewProvider portfolioId={portfolioId}>
									{params.viewType === 'map' && (
										<MapViewComponent portfolioId={portfolioId} />
									)}
									{params.viewType === 'list' && (
										<ListViewComponent portfolioId={portfolioId} />
									)}
								</LeasesListAndMapViewProvider>
							</PropertiesListAndMapViewsProvider>
						)}
						{(!params.viewType || params.viewType === 'dashboard') && (
							<Dashboard portfolio={portfolioV2} />
						)}
					</Content>
				</PortfolioLeasesFiltersProvider>
			</PortfolioFiltersProvider>
		);
	})();

	return (
		<PortfolioAlertsStateProvider>
			<Root>
				{portfolioRevampBenchmarkingFF ? (
					<PortfolioSidebarV2 portfolioId={portfolioId} />
				) : (
					<PortfolioSidebar portfolioId={portfolioId} />
				)}
				{content}
			</Root>
		</PortfolioAlertsStateProvider>
	);
};

const Root = styled.div`
	display: flex;
	height: calc(100vh - ${APP_HEADER_HEIGHT}px);
	// defaults
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	line-height: 1.2;
	box-sizing: border-box;

	font-size: 1rem;

	@media (max-width: ${MQB.D_1280}) {
		font-size: 0.875rem;
	}
`;

const Content = styled.div`
	flex: 1;
	height: 100%;
`;

const LoadingContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
