import {
	ModalInputMessage,
	ModalInputLabel,
} from 'Components/Modals/common/UI';
import { usePortfolioForm } from './usePortfolioForm';
import { Actions } from 'PortfolioAnalytics/modals/PortfolioModal';
import { Flex } from '@compstak/ui-kit';

import styled from 'styled-components';
import IconQuestionMark from '../../ui/svg_icons/question_mark_outline.svg';
import { RadioButton as RadioButtonOriginal } from 'Components/RadioButton/RadioButton';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { PortfolioNotificationFrequency } from 'api/portfolio/preferences/types';

type Props = ReturnType<typeof usePortfolioForm> & { action: Actions };

const NOTIFICATION_FREQUENCY_OPTIONS: {
	value: PortfolioNotificationFrequency;
	title: string;
}[] = [
	{
		value: PortfolioNotificationFrequency.Never,
		title: 'No email updates',
	},
	{
		value: PortfolioNotificationFrequency.RealTime,
		title: 'Every time a new comp is added',
	},
	{ value: PortfolioNotificationFrequency.Daily, title: 'Daily updates' },
	{ value: PortfolioNotificationFrequency.Weekly, title: 'Weekly updates' },
];

export const PortfolioFormFieldsV2 = ({
	errors,
	setIsTouched,
	setState,
	state,
	isTouched,
	action,
}: Props) => {
	const { portfolioRevampAlertsAndNotificationsFF } = useFeatureFlags();

	const isErrorName = (action === 'copy' || isTouched) && !!errors.name;
	return (
		<div>
			<ModalInputLabel isError={isErrorName}>
				Name <Asterisk>*</Asterisk>
				<input
					value={state.name}
					type="text"
					onChange={(e) => {
						setIsTouched(true);
						setState({ ...state, name: e.currentTarget.value });
					}}
				/>
				{isErrorName && (
					<ModalInputMessage isError>{errors.name}</ModalInputMessage>
				)}
			</ModalInputLabel>

			<ModalInputLabel isError={isTouched && !!errors.description}>
				Description
				<textarea
					value={state.description}
					onChange={(e) => {
						setIsTouched(true);
						setState({ ...state, description: e.currentTarget.value });
					}}
				/>
				{isTouched && !!errors.description && (
					<ModalInputMessage isError>{errors.description}</ModalInputMessage>
				)}
			</ModalInputLabel>

			{portfolioRevampAlertsAndNotificationsFF && (
				<Flex direction="column" alignItems="normal" gap="0.5rem">
					<Flex justifyContent="normal" gap="0.5rem">
						<strong>Notification Preferences</strong>
						<IconQuestionMark />
					</Flex>
					<Grid>
						{NOTIFICATION_FREQUENCY_OPTIONS.map((option) => (
							<RadioButton
								key={option.value}
								id={option.value}
								name="notificationFrequency"
								onChange={() => {
									setState((s) => ({
										...s,
										notificationFrequency: option.value,
									}));
								}}
								checked={state.notificationFrequency === option.value}
							>
								{option.title}
							</RadioButton>
						))}
					</Grid>
				</Flex>
			)}
		</div>
	);
};

const Asterisk = styled.span`
	color: ${({ theme }) => theme.colors.red.red500};
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.5rem;
`;

const RadioButton = styled(RadioButtonOriginal)`
	flex-direction: row-reverse;
	justify-content: space-between;
`;
