import IconDocument from 'ui/svg_icons/document.svg';
import { PopupActionButton, StyledArrowRightIcon } from './UI';
import { ButtonProps } from '@compstak/ui-kit';

type Props = {
	propertyId: number;
} & Partial<ButtonProps>;

export const PropertyPageLinkV2 = ({
	propertyId,
	children,
	...props
}: Props) => {
	return (
		<PopupActionButton
			href={`/property/${propertyId}`}
			icon={<IconDocument />}
			{...props}
		>
			{children}
			<StyledArrowRightIcon />
		</PopupActionButton>
	);
};
