import { MarketRentAnalyticsRentType } from 'api';
import {
	FormControl,
	FormControlOptionLabel,
	MapAnalyticsFormSection,
	RadioButton,
	RadioButtonLabel,
	MapAnalyticsSectionTitle,
} from '..';
import { useMarketRentAnalyticsState } from './MarketRentAnalyticsProvider';
import {
	MarketRentAnalyticsPeriod,
	MarketRentAnalyticsViewMode,
} from './types';

export const MarketRentAnalyticsSharedFormControls = () => {
	const [state, setState] = useMarketRentAnalyticsState();

	// @ts-expect-error TS7006: Parameter 'e' implicitly has a...
	const onChangeViewMode = (e) => {
		setState((s) => ({ ...s, viewMode: e.target.value }));
	};

	// @ts-expect-error TS7006: Parameter 'e' implicitly has a...
	const onChangeMetric = (e) => {
		setState((s) => ({ ...s, rentType: e.target.value }));
	};

	// @ts-expect-error TS7006: Parameter 'e' implicitly has a...
	const onChangePeriod = (e) => {
		setState((s) => ({ ...s, period: e.target.value }));
	};

	return (
		<>
			<MapAnalyticsFormSection>
				<MapAnalyticsSectionTitle>View Results By</MapAnalyticsSectionTitle>
				<FormControl>
					<RadioButton
						id={VIEW_RADIO_HEXAGON_ID}
						name={VIEW_RADIO_GROUP_NAME}
						value={MarketRentAnalyticsViewMode.HEXAGON}
						checked={state.viewMode === MarketRentAnalyticsViewMode.HEXAGON}
						onChange={onChangeViewMode}
						data-qa-id={VIEW_RADIO_HEXAGON_ID}
					/>
					<RadioButtonLabel htmlFor={VIEW_RADIO_HEXAGON_ID}>
						<FormControlOptionLabel>Hexagon Grid</FormControlOptionLabel>
					</RadioButtonLabel>
				</FormControl>
				<FormControl>
					<RadioButton
						id={VIEW_RADIO_SUBMARKET_ID}
						name={VIEW_RADIO_GROUP_NAME}
						value={MarketRentAnalyticsViewMode.SUBMARKET}
						checked={state.viewMode === MarketRentAnalyticsViewMode.SUBMARKET}
						onChange={onChangeViewMode}
						data-qa-id={VIEW_RADIO_SUBMARKET_ID}
					/>
					<RadioButtonLabel htmlFor={VIEW_RADIO_SUBMARKET_ID}>
						<FormControlOptionLabel>Submarkets</FormControlOptionLabel>
					</RadioButtonLabel>
				</FormControl>
			</MapAnalyticsFormSection>
			<MapAnalyticsFormSection>
				<MapAnalyticsSectionTitle>Metric</MapAnalyticsSectionTitle>
				<FormControl>
					<RadioButton
						id={METRIC_RADIO_STARTING_ID}
						name={METRIC_RADIO_GROUP_NAME}
						value={MarketRentAnalyticsRentType.STARTING}
						checked={state.rentType === MarketRentAnalyticsRentType.STARTING}
						onChange={onChangeMetric}
						data-qa-id={METRIC_RADIO_STARTING_ID}
					/>
					<RadioButtonLabel htmlFor={METRIC_RADIO_STARTING_ID}>
						<FormControlOptionLabel>Starting Rent</FormControlOptionLabel>
					</RadioButtonLabel>
				</FormControl>
				<FormControl>
					<RadioButton
						id={METRIC_RADIO_EFFECTIVE_ID}
						name={METRIC_RADIO_GROUP_NAME}
						value={MarketRentAnalyticsRentType.EFFECTIVE}
						checked={state.rentType === MarketRentAnalyticsRentType.EFFECTIVE}
						onChange={onChangeMetric}
						data-qa-id={METRIC_RADIO_EFFECTIVE_ID}
					/>
					<RadioButtonLabel htmlFor={METRIC_RADIO_EFFECTIVE_ID}>
						<FormControlOptionLabel>Effective Rent</FormControlOptionLabel>
					</RadioButtonLabel>
				</FormControl>
			</MapAnalyticsFormSection>
			<MapAnalyticsFormSection>
				<MapAnalyticsSectionTitle>Unit</MapAnalyticsSectionTitle>
				<FormControl>
					<RadioButton
						id={PERIOD_RADIO_MONTH_ID}
						name={PERIOD_RADIO_GROUP_NAME}
						value={MarketRentAnalyticsPeriod.YEAR}
						checked={state.period === MarketRentAnalyticsPeriod.YEAR}
						onChange={onChangePeriod}
						data-qa-id={PERIOD_RADIO_MONTH_ID}
					/>
					<RadioButtonLabel htmlFor={PERIOD_RADIO_MONTH_ID}>
						<FormControlOptionLabel>$/SqFt/YR</FormControlOptionLabel>
					</RadioButtonLabel>
				</FormControl>
				<FormControl>
					<RadioButton
						id={PERIOD_RADIO_YEAR_ID}
						name={PERIOD_RADIO_GROUP_NAME}
						value={MarketRentAnalyticsPeriod.MONTH}
						checked={state.period === MarketRentAnalyticsPeriod.MONTH}
						onChange={onChangePeriod}
						data-qa-id={PERIOD_RADIO_YEAR_ID}
					/>
					<RadioButtonLabel htmlFor={PERIOD_RADIO_YEAR_ID}>
						<FormControlOptionLabel>$/SqFt/MO</FormControlOptionLabel>
					</RadioButtonLabel>
				</FormControl>
			</MapAnalyticsFormSection>
		</>
	);
};

const VIEW_RADIO_GROUP_NAME = 'market-rent-radio-group-view-mode';
const VIEW_RADIO_HEXAGON_ID = 'market-rent-radio-view-hexagon';
const VIEW_RADIO_SUBMARKET_ID = 'market-rent-radio-view-submarket';

const METRIC_RADIO_GROUP_NAME = 'market-rent-radio-group-metric';
const METRIC_RADIO_STARTING_ID = 'market-rent-radio-metric-starting';
const METRIC_RADIO_EFFECTIVE_ID = 'market-rent-radio-metric-effective';

const PERIOD_RADIO_GROUP_NAME = 'market-rent-radio-group-period';
const PERIOD_RADIO_MONTH_ID = 'market-rent-radio-period-month';
const PERIOD_RADIO_YEAR_ID = 'market-rent-radio-period-year';
