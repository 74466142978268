import { useQuery } from '@tanstack/react-query';
import { useReferenceData } from 'Pages/Login/reducers';
import { QUERY_KEYS } from '../constants';
import { createFetchFn } from '../utils';

const fetchReferenceData = createFetchFn<void, ReferenceData>({
	method: 'get',
	getURL: () => `/api/referenceData`,
});

export const useReferenceDataQuery = () => {
	const refData = useReferenceData();

	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.REFERENCE_DATA],
		queryFn: (ctx) => fetchReferenceData()(ctx),
		initialData: refData,
	});

	return {
		...queryResult,
		data: queryResult.data!,
	};
};

export interface ReferenceData {
	spaceTypes: SpaceType[];
	spaceSubtypes: SpaceSubtype[];
	propertyTypes: PropertyType[];
	propertySubtypes: PropertySubtype[];
	leaseTypes: LeaseType[];
	transactionTypes: TransactionType[];
	buildingClasses: BuildingClass[];
	tenantIndustries: TenantIndustry[];
	tenantSectors: TenantSector[];
	// InsideView has been acquired by Demandbase.
	// Mentions of InsideView in the codebase will stay unchanged as decided by the team.
	// https://compstak.atlassian.net/browse/AP-17517
	insideviewStatus: DemandbaseStatus[];
	insideviewOwnership: DemandbaseOwnership[];
	filterableSpaceTypes: FilterableReferenceType<SpaceType>[];
	filterableSpaceSubtypes: FilterableReferenceType<SpaceSubtype>[];
	filterablePropertyTypes: FilterableReferenceType<PropertyType>[];
	filterablePropertySubtypes: FilterableReferenceType<PropertySubtype>[];
	specializations?: Specialization[];
	focuses?: Focus[];
	jobTypes?: JobType[];
	hierarchyLevels?: HierarchyLevel[];
}

export type SpaceType<T extends SpaceTypeId = SpaceTypeId> = {
	id: T;
	name: SpaceTypeIdToName[T];
};

export const SPACE_TYPE_ID_TO_NAME = {
	1: 'Office',
	2: 'Retail',
	6: 'Industrial',
	7: 'Flex/R&D',
	8: 'Land',
	13: 'Other',
	[-1]: 'Unknown',
} as const;

export const SPACE_TYPE_NAME_TO_ID = {
	Office: 1,
	Retail: 2,
	Industrial: 6,
	'Flex/R&D': 7,
	Land: 8,
	Other: 13,
	Unknown: -1,
} as const;

type SpaceTypeIdToName = typeof SPACE_TYPE_ID_TO_NAME;

export type SpaceTypeId = keyof SpaceTypeIdToName;

export type SpaceTypeName = SpaceTypeIdToName[SpaceTypeId];

export const SPACE_SUBTYPES = {
	21: {
		id: 21,
		name: 'Automotive',
	},
	22: {
		id: 22,
		name: 'Bank',
	},
	18: {
		id: 18,
		name: 'Big Box',
	},
	12: {
		id: 12,
		name: 'Cold Storage',
	},
	16: {
		id: 16,
		name: 'Convenience Store',
	},
	11: {
		id: 11,
		name: 'Data Center',
	},
	25: {
		id: 25,
		name: 'Discount Store',
	},
	2: {
		id: 2,
		name: 'Distribution',
	},
	30: {
		id: 30,
		name: 'Drive Thru',
	},
	19: {
		id: 19,
		name: 'Drug Store',
	},
	29: {
		id: 29,
		name: 'Educational/School',
	},
	24: {
		id: 24,
		name: 'Fast Food',
	},
	5: {
		id: 5,
		name: 'Flex',
	},
	26: {
		id: 26,
		name: 'Freestanding',
	},
	17: {
		id: 17,
		name: 'Gas Station',
	},
	20: {
		id: 20,
		name: 'Grocery Store',
	},
	14: {
		id: 14,
		name: 'Industrial Outdoor Storage',
	},
	27: {
		id: 27,
		name: 'Inline',
	},
	9: {
		id: 9,
		name: 'Lab',
	},
	8: {
		id: 8,
		name: 'Life Science',
	},
	4: {
		id: 4,
		name: 'Light Industrial',
	},
	3: {
		id: 3,
		name: 'Manufacturing',
	},
	10: {
		id: 10,
		name: 'Medical',
	},
	7: {
		id: 7,
		name: 'R&D',
	},
	23: {
		id: 23,
		name: 'Restaurant',
	},
	13: {
		id: 13,
		name: 'Self Storage',
	},
	6: {
		id: 6,
		name: 'Showroom',
	},
	28: {
		id: 28,
		name: 'Street/Storefront',
	},
	15: {
		id: 15,
		name: 'Truck Terminal/Transfer Facility',
	},
	1: {
		id: 1,
		name: 'Warehouse',
	},
} as const;

type SpaceSubtypes = typeof SPACE_SUBTYPES;

export type SpaceSubtypeId = keyof SpaceSubtypes;

export type SpaceSubtypeName = SpaceSubtypes[SpaceSubtypeId]['name'];

type SpaceSubtype<T extends SpaceSubtypeId = SpaceSubtypeId> = {
	id: T;
	name: SpaceSubtypes[T]['name'];
};

export const PROPERTY_TYPE_ID_TO_NAME = {
	1: 'Office',
	2: 'Multi-Family',
	7: 'Industrial',
	8: 'Land',
	14: 'Mixed-Use',
	16: 'Retail',
	17: 'Hotel',
	18: 'Other',
} as const;

export const PROPERTY_TYPE_NAME_TO_ID = {
	Office: 1,
	'Multi-Family': 2,
	Industrial: 7,
	Land: 8,
	'Mixed-Use': 14,
	Retail: 16,
	Hotel: 17,
	Other: 18,
} as const;

type PropertyTypeIdToName = typeof PROPERTY_TYPE_ID_TO_NAME;

export type PropertyTypeId = keyof PropertyTypeIdToName;

export type PropertyTypeName = PropertyTypeIdToName[PropertyTypeId];

export type PropertyType<T extends PropertyTypeId = PropertyTypeId> = {
	id: T;
	name: PropertyTypeIdToName[T];
};

export const PROPERTY_SUBTYPE_ID_TO_NAME = {
	4: 'Business Park',
	5: 'Mixed-Use',
	6: 'Flex/R&D',
	8: 'Manufacturing',
	9: 'Warehouse/Distribution',
	10: 'Light Industrial',
	11: 'Super-Regional Center/Mall',
	13: 'Community Shopping Center',
	14: 'Neighborhood Shopping Center',
	15: 'Convenience/Strip Center',
	18: 'Outlet',
	19: 'Freestanding',
	24: 'Showroom',
	25: 'Educational/School',
	27: 'Communication/Data Center',
	30: 'Community/Recreation Center',
	33: 'Assembly/Meeting Place',
	34: 'Automotive',
	41: 'Fuel & Service Station',
	44: 'Bank',
	45: 'Condominium',
	47: 'Day Care Facility',
	51: 'Department Store',
	57: 'Street Retail/Storefront',
	78: 'Processing',
	89: 'Refrigerated/Cold Storage',
	103: 'Creative',
	139: 'Shopping Centers',
	152: 'Special Purpose',
	163: 'Transportation',
	166: 'Mobile Home Park',
	167: 'Inline',
	168: 'Drive Thru',
	169: 'Industrial Outdoor Storage',
	170: 'Live/Work',
	172: 'Heavy Industrial',
	173: 'Senior Housing',
	174: 'Student Housing',
	175: 'Hospitality Related',
	176: 'Sports & Recreation',
	177: 'Municipality/Public Service',
	178: 'General Retail',
	179: 'Special Industrial',
	183: 'Funeral/Mortuary',
	184: 'Restaurant/Bar',
	185: 'Housing',
	186: 'Hospital/Healthcare Facility',
	187: 'Life Science/Lab',
	188: 'Medical/Healthcare',
	292: 'Apartments',
	304: 'Financial Building',
	305: 'Professional Building',
	306: 'Parking',
	311: 'Vacant Land',
	322: 'Under Construction',
	323: 'Structures on Leased Land',
	324: 'Self-Storage',
	[-1]: 'Unknown',
} as const;

type PropertySubtypeIdToName = typeof PROPERTY_SUBTYPE_ID_TO_NAME;

export type PropertySubtypeId = keyof PropertySubtypeIdToName;

export type PropertySubtypeName = PropertySubtypeIdToName[PropertySubtypeId];

type PropertySubtype<T extends PropertySubtypeId = PropertySubtypeId> = {
	id: T;
	name: PropertySubtypeIdToName[T];
};

export interface LeaseType {
	id: number;
	description: string;
	name: string;
	uiOrder: number;
}

export interface TransactionType {
	id: number;
	code: string;
	description: string;
	name: string;
}

export interface BuildingClass {
	id: number;
	name: string;
}

export interface TenantIndustry {
	id: number;
	name: string;
	description: string;
	sectorId: number;
}

export interface TenantSector {
	id: number;
	name: string;
}

interface DemandbaseStatus {
	id: number;
	name: string;
}

interface DemandbaseOwnership {
	name: string;
	id: number;
}

export interface Specialization {
	id: number;
	name: string;
}

export interface Focus {
	id: number;
	name: string;
}

export interface JobType {
	id: number;
	orderId: number;
	name: string;
}

export interface HierarchyLevel {
	id: number;
	name: string;
}

export type CommonReferenceType<T extends { id: number; name: string }> = {
	id: T['id'];
	name: T['name'];
};

export type OtherAndUnkownReferenceType = {
	name: 'Other';
	ids: number[];
	names: string[];
};

export type FilterableReferenceType<T extends { id: number; name: string }> =
	| CommonReferenceType<T>
	| OtherAndUnkownReferenceType;

export const BUILDING_CLASS_ID_TO_NAME = {
	1: 'A',
	2: 'B',
	3: 'C',
} as const;

export const BUILDING_CLASS_NAME_TO_ID = {
	A: 1,
	B: 2,
	C: 3,
} as const;

export type BuildingClassId = keyof typeof BUILDING_CLASS_ID_TO_NAME;

export type BuildingClassName = keyof typeof BUILDING_CLASS_NAME_TO_ID;
