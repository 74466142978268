import { API } from '@compstak/common';
import {
	UseMutationOptions,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { trackSegmentCreatePortfolio } from 'middleware/tracking/segment';
import {
	getPortfolioWithPreferencesPayload,
	getPortfolioWithPreferencesUrl,
} from 'api/portfolio/preferences/utils';
import { AllPortfoliosResponse } from '../allPortfolios';
import { WithPreferencesParams } from '../preferences/types';

export const useCreatePortfolioMutation = (
	options?: UseMutationOptions<
		CreatePortfolioResponse,
		Error,
		CreatePortfolioParams
	>
) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (_params: CreatePortfolioParams) => {
			const { description, ...otherParams } = _params;
			const params = {
				...otherParams,
				...(!!description && { description }),
			};
			const res = await API.post<CreatePortfolioResponse>(
				getPortfolioWithPreferencesUrl('/pa/v2/api/portfolios', params),
				getPortfolioWithPreferencesPayload(params)
			);
			return res.data;
		},
		onSuccess: (newPortfolio, params) => {
			const existingPortfolios =
				queryClient.getQueryData<AllPortfoliosResponse>([
					QUERY_KEYS.ALL_PORTFOLIOS,
				]);
			trackSegmentCreatePortfolio({
				existingPortfolios: existingPortfolios || [],
				newPortfolio: { id: newPortfolio.id },
				newPortfolioPropertyIds: params.propertyIds,
			});
			queryClient.invalidateQueries([QUERY_KEYS.ALL_PORTFOLIOS]);
		},
		...options,
	});
};

export type CreatePortfolioParams = WithPreferencesParams<{
	name: string;
	description?: string;
	propertyIds?: number[];
}>;

type CreatePortfolioResponse = {
	id: number;
};
