import { exportImage } from '@compstak/ui-kit';

type _Props = Parameters<typeof exportImage>[0];

type Props = Omit<_Props, 'element'> & { element: _Props['element'] | null };

export const exportPortfolio = ({ filename, element, ...props }: Props) => {
	if (!element) return;
	exportImage({
		element,
		ignoreElements: (node) => node.classList.contains('mapboxgl-ctrl'),
		imageTimeout: 1000,
		...props,
		filename: `Portfolio Analytics - ${filename}`,
	});
};
