import {
	BUILDING_CLASS_ID_TO_NAME,
	BuildingClassId,
	PROPERTY_TYPE_ID_TO_NAME,
	PropertyTypeId,
	SPACE_TYPE_ID_TO_NAME,
	SPACE_TYPE_NAME_TO_ID,
	SpaceTypeId,
} from 'api';
import {
	FilterDateInterval,
	FilterNumberInterval,
	FiltersObject,
} from 'models/filters/types';
import { formatSqft, formatCsv, formatDate } from 'format';
import { getLabelForFilterAttribute } from 'models/filters/util/filterHelpers';
import { Market } from '@compstak/common';
import { CompType } from 'types';
import { omit } from 'lodash';
import { getFiltersMarkets } from './getFiltersMarkets';

const formatDateInterval = (value: FilterDateInterval) => {
	return value.min && value.max
		? `between ${formatDate(value.min)} - ${formatDate(value.max)}`
		: value.min
			? `from ${formatDate(value.min)}`
			: value.max
				? `until ${formatDate(value.max)}`
				: '';
};

const formatSize = (value: FilterNumberInterval) => {
	return value.min && value.max
		? `between ${formatSqft(value.min)} - ${formatSqft(value.max)}`
		: value.min
			? `from ${formatSqft(value.min)}`
			: value.max
				? `up to ${formatSqft(value.max)}`
				: '';
};

export const getTooltip = (
	filtersObject: FiltersObject,
	compType: CompType
) => {
	const marketFilter = getFiltersMarkets(filtersObject);
	const marketsTooltip = getMarketsTooltip(marketFilter);

	const mostCommonFiltersTooltips = mostCommonFiltersPerType[compType]
		.map((filterKey) => {
			const tooltipFn = commonFiltersTooltipsFn[filterKey];
			const tooltip = filtersObject[filterKey]
				? tooltipFn(filtersObject[filterKey])
				: '';
			return tooltip
				? `${getLabelForFilterAttribute({ attribute: filterKey, compType })} is ${tooltip}`
				: '';
		})
		.filter(Boolean);

	const topFilters = [marketsTooltip, ...mostCommonFiltersTooltips];

	const allFilters = omit(filtersObject, [
		'sortDirection',
		'sortField',
		'hidden',
	]);

	const allFilterCount = Object.values(allFilters).filter(Boolean).length;

	const moreFiltersCount = allFilterCount - topFilters.length;

	return moreFiltersCount
		? [
				...topFilters,
				`${moreFiltersCount} more filter${moreFiltersCount === 1 ? '' : 's'}`,
			].join(' • ')
		: topFilters.join(' • ');
};

const getMarketsTooltip = (value: Market | Market[]) => {
	if (Array.isArray(value))
		return value.length === 1
			? getMarketTooltip(value[0])
			: `${value.length} Markets Selected`;

	return getMarketTooltip(value);
};

const getMarketTooltip = (market: Market) => {
	return `${market.displayName}
			(${market.states.map((state) => state.code).join(', ')})`;
};

const commonFiltersTooltipsFn: Record<keyof CommonFilters, (v: any) => string> =
	{
		spaceTypeId: (v: SpaceTypeId[]) =>
			v
				.filter((id) => id !== SPACE_TYPE_NAME_TO_ID['Unknown'])
				.map((id) => SPACE_TYPE_ID_TO_NAME[id])
				.join(', '),
		executionDate: (v: FilterDateInterval) => formatDateInterval(v),
		transactionSize: (v: FilterNumberInterval) => formatSize(v),
		buildingClassId: (v: BuildingClassId[]) =>
			formatCsv(v.map((id) => BUILDING_CLASS_ID_TO_NAME[id])),
		buildingSize: (v: FilterNumberInterval) => formatSize(v),
		buildingPropertyTypeId: (v: PropertyTypeId[]) =>
			formatCsv(v.map((id) => PROPERTY_TYPE_ID_TO_NAME[id])),
		buildingPropertySubtype: (v: string[]) => formatCsv(v),
		saleDate: (v: FilterDateInterval) => formatDateInterval(v),
	};

const mostCommonFiltersPerType: Record<CompType, (keyof CommonFilters)[]> = {
	lease: [
		'spaceTypeId',
		'executionDate',
		'transactionSize',
		'buildingClassId',
		'buildingSize',
	],
	sale: [
		'buildingPropertyTypeId',
		'buildingPropertySubtype',
		'saleDate',
		'buildingSize',
		'buildingClassId',
		'transactionSize',
	],
	property: [
		'buildingPropertyTypeId',
		'buildingPropertySubtype',
		'buildingClassId',
		'buildingSize',
	],
};

type CommonFilters = Pick<
	FiltersObject,
	| 'spaceTypeId'
	| 'executionDate'
	| 'transactionSize'
	| 'buildingClassId'
	| 'buildingSize'
	| 'buildingPropertyTypeId'
	| 'buildingPropertySubtype'
	| 'saleDate'
>;
