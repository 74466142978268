import { LeaseAndSaleAveragesResponse } from 'api';
import { formatInteger, formatMoney, formatMonths, formatRent } from 'format';
import { FiltersObject } from 'models/filters/types';
import { shouldUseMonthlyMetrics } from 'models/filters/util/shouldUseMonthlyMetrics';
import { ReactNode, useMemo } from 'react';

type Props = {
	averages: LeaseAndSaleAveragesResponse | undefined;
	filters: FiltersObject;
};

export const useLeasesSalesAveragesFormatting = ({
	averages,
	filters,
}: Props): Record<string, () => ReactNode> => {
	const isMonthly = useMemo(() => shouldUseMonthlyMetrics(filters), [filters]);

	if (!averages || Object.keys(averages).length === 0) return {};
	return {
		startingRent: () => formatRent(averages.startingRent, isMonthly),
		effectiveRent: () => formatRent(averages.effectiveRent, isMonthly),
		freeMonths: () => `${averages.freeMonths.toFixed(1)}m`,
		leaseTerm: () => formatMonths(averages.leaseTerm, { omitDays: true }),
		workValue: () => formatMoney(averages.ti),
		totalSalePrice: () => formatMoney(averages.totalSalePrice),
		transactionSize: () => `${formatInteger(averages.transactionSize)} SqFt`,
	};
};
