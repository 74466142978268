import { renderTableValue } from '@compstak/ui-kit';
import { useMufaUnitMixSummaryQuery } from 'api';
import { formatInteger, formatPercent, formatSqft } from 'format';
import { routes } from 'router';
import styled from 'styled-components';
import { TabContentTitle } from '../UI';
import { RealPage } from '../RealPage';

type Props = {
	isExportPDF?: boolean;
};

type CardData<Value> = {
	value: Value;
	formatter: (v: NonNullable<Value>) => React.ReactNode;
	label: string;
};

export const Summary = ({ isExportPDF }: Props) => {
	const params = routes.propertyById.useParams();
	const { data: summary } = useMufaUnitMixSummaryQuery({
		propertyId: params.propertyId,
	});

	if (!summary) return null;

	const cardData: CardData<number | null | undefined>[] = [
		{
			value: summary.totalUnits,
			formatter: (v) => formatInteger(v, { shorten: false }),
			label: 'Total Units',
		},
		{
			value: summary.marketRateUnits,
			formatter: (v) => formatInteger(v, { shorten: false }),
			label: 'Market Rate Units',
		},
		{
			value: summary.averageUnitSqFt,
			formatter: (v) => formatSqft(v),
			label: 'Average Unit SqFt',
		},
		{
			value: summary.occupancy,
			formatter: (v) => formatPercent(v, 1),
			label: 'Occupancy',
		},
		{
			value: summary.grossLivingArea,
			formatter: (v) => formatSqft(v),
			label: 'Gross Living Area',
		},
	];

	return (
		<div>
			{!isExportPDF && <SummaryTitle>Multifamily Overview</SummaryTitle>}
			<CardsContainer>
				{cardData.map((card) => (
					<Card key={card.label}>
						<CardValue isExportPdf={isExportPDF}>
							{renderTableValue(card.value, card.formatter)}
						</CardValue>
						<CardLabel>{card.label}</CardLabel>
					</Card>
				))}
			</CardsContainer>
			<RealPage />
		</div>
	);
};

const SummaryTitle = styled(TabContentTitle)`
	margin-bottom: 1.25rem;
`;

const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 1rem;
	margin-bottom: 0.75rem;
`;

const Card = styled.div`
	height: 110px;
	padding: 1.5rem 1rem;
	border-radius: 0.25rem;
	background-color: ${(p) => p.theme.colors.neutral.n10};
	box-shadow: 0px 1px 2px 0px rgba(51, 51, 51, 0.1);
`;

const CardValue = styled.div<{ isExportPdf: boolean | undefined }>`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: ${(props) => (props.isExportPdf ? '1.5rem' : '2rem')};
	font-weight: 500;
	line-height: 1.25;
	text-transform: uppercase;
`;

const CardLabel = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.5;
	letter-spacing: 0.025em;
`;
