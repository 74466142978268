import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { AddToPortfolioDialog } from './AddToPortfolioDialog';
import { AddToPortfolioDialogV2 } from './AddToPortfolioDialogV2';
import { AddToPortfolioProps } from './AddToPortfolioDialog';

export const AddToPortfolioDialogSelector = ({
	propertyIds,
	closeDialog,
	isLoading,
}: AddToPortfolioProps) => {
	const { portfolioRevampFF } = useFeatureFlags();
	return portfolioRevampFF ? (
		<AddToPortfolioDialogV2
			propertyIds={propertyIds}
			closeDialog={closeDialog}
			isLoading={isLoading}
		/>
	) : (
		<AddToPortfolioDialog
			propertyIds={propertyIds}
			closeDialog={closeDialog}
			isLoading={isLoading}
		/>
	);
};
