import React from 'react';

import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsExportData from 'highcharts/modules/export-data';
import highchartsExport from 'highcharts/modules/exporting';
import highchartsOfflineExport from 'highcharts/modules/offline-exporting';
highchartsMore(Highcharts);
highchartsExport(Highcharts);
highchartsExportData(Highcharts);
highchartsOfflineExport(Highcharts);

import { getAxisId, NO_NAME } from './util';

import { ShapeDataLoader } from './ShapeDataLoader';

import axisGenerator from './configGenerators/axes';
import histogramSeries from './configGenerators/histogramSeries';
import lineOrPointSeries from './configGenerators/lineOrPointSeries';

import { Market } from '@compstak/common';
import { ChartType, Shape, Timespan } from 'Pages/Analytics/analytics';
import { attributesHashFromShape } from 'Pages/Analytics/Builder/chartBuilderHelpers';
import { renderToStaticMarkup } from 'react-dom/server';
import { CHART_MARGIN_TOP, TOOLTIP_WIDTH } from './config';
import { Tooltip } from './Tooltip';

const style = {
	position: 'absolute',
	left: 0,
	right: 0,
	bottom: 0,
	top: 0,
};

// @ts-expect-error TS7006: Parameter 'attribute' implicit...
const makeTitle = (attribute, market) => {
	if (getAxisId(attribute.name) === 'rent') {
		return market.monthly
			? 'Rent, Monthly, ($/SqFt)'
			: 'Rent, Annual, ($/SqFt)';
	}
	if (attribute.postUnit) {
		return `${attribute.shortName} (${attribute.postUnit})`;
	} else {
		return `${attribute.shortName}`;
	}
};

interface GraphProps {
	data: { data: unknown; shape: Shape }[];
	shapes: Shape[];
	chartType?: ChartType;
	indexesToDisplay: number[];
	market: Market;
	legend: unknown;
	timespan: Timespan;
	width: unknown;
	height: unknown;
	minBarWidth: number;
	hasNewDesign: boolean;
}

export class Graph extends React.Component<GraphProps> {
	// @ts-expect-error TS2724: '"/home/matija/.yarn/berry/cac...
	chart: Highcharts.chart;
	el: unknown;

	static defaultProps = {
		timespan: 120,
		title: '',
		minBarWidth: 10,
	};

	componentDidUpdate(oldProps: GraphProps) {
		switch (true) {
			case this.props.data.length === 0:
				return;
			case oldProps.shapes !== this.props.shapes:
			case oldProps.chartType !== this.props.chartType:
			case oldProps.timespan !== this.props.timespan:
			case oldProps.indexesToDisplay !== this.props.indexesToDisplay:
				this.renderGraph();
				break;
			default:
				this.chart.update(this.generateSettings());
				this.chart.redraw(false);
		}

		if (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'exportRequest' does not exist on type 'R... Remove this comment to see the full error message
			this.props.exportRequest &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'exportRequest' does not exist on type 'R... Remove this comment to see the full error message
			this.props.exportRequest !== oldProps.exportRequest
		) {
			const title =
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'exportRequest' does not exist on type 'R... Remove this comment to see the full error message
				this.props.exportRequest.title && this.props.exportRequest.title.text;
			const exportOptions = {
				type: 'image/jpeg',
				filename: title ? title.replace(/[^\w ]/g, '') : '',
			};
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'chart' does not exist on type 'Graph'.
			this.chart.exportChartLocal(exportOptions, this.props.exportRequest);
		}

		if (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'copyRequest' does not exist on type 'Rea... Remove this comment to see the full error message
			this.props.copyRequest &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'copyRequest' does not exist on type 'Rea... Remove this comment to see the full error message
			this.props.copyRequest !== oldProps.copyRequest
		) {
			const csv = this.formatCSV(this.chart.getCSV(true));
			this.copyToClipboard(csv);
		}
	}

	// @ts-expect-error TS7006: Parameter 'csv' implicitly has...
	copyToClipboard = (csv) => {
		const el = document.createElement('textarea');
		// @ts-expect-error ts-migrate(2322) FIXME: Type '0' is not assignable to type 'string'.
		el.style.opacity = 0;
		el.style.position = 'absolute';
		document.body.appendChild(el);
		el.value = csv;
		el.select();
		// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
		document.execCommand('copy', false, null);
		document.body.removeChild(el);
	};

	getTitles = (shape: Shape): { x: string; y: string } => {
		try {
			const attributes = attributesHashFromShape(shape);
			if (this.props.chartType === 'histogram') {
				return {
					// @ts-expect-error TS7015: Element implicitly has an 'any...
					x: makeTitle(attributes[shape.y], this.props.market),
					y: 'Total Transaction Size (SqFt)',
				};
			}
			return {
				// @ts-expect-error TS7015: Element implicitly has an 'any...
				x: makeTitle(attributes[shape.x], this.props.market),
				// @ts-expect-error TS7015: Element implicitly has an 'any...
				y: makeTitle(attributes[shape.y], this.props.market),
			};
		} catch (err) {
			console.error(err);
			return {
				x: 'X-Axis title error',
				y: 'Y-Axis title error',
			};
		}
	};

	generateSettings() {
		// @ts-expect-error TS2322: Type '{} | { padding: number; ...
		const legend: Highcharts.LegendOptions =
			this.props.legend === undefined
				? {
						padding: 10,
						margin: 20,
					}
				: this.props.legend;

		let tooltipOptions: Highcharts.TooltipOptions = {
			split: true,
			animation: false,
			shared: true,
			xDateFormat: '%B %Y',
			backgroundColor: 'rgba(20, 20, 20, 0.8)',
			borderColor: 'rgba(20,20,20, 1)',
			style: {
				color: 'white',
			},
			headerFormat: '{point.key}',
			pointFormatter: function () {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'series' does not exist on type '{ split:... Remove this comment to see the full error message
				const formatter = this.series.yAxis.userOptions.labels.formatter;
				if (formatter && this.series.name !== NO_NAME) {
					return `<span style="color:${this.color}">●</span> ${
						this.series.name
						// @ts-expect-error TS2554: Expected 2 arguments, but got ...
					}: <b>${formatter.call({
						value: this.y,
					})}</b><br/>`;
				}
				if (formatter) {
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'y' does not exist on type '{ split: bool... Remove this comment to see the full error message
					return formatter.call({ value: this.y });
				}
				if (this.series.name !== NO_NAME) {
					return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${this.y}</b><br/>`;
				}
				return String(this.y);
			},
		};

		let plotOptions: Highcharts.PlotOptions = {};

		if (this.props.hasNewDesign) {
			tooltipOptions = {
				animation: false,
				shadow: false,
				split: false,
				shared: true,
				padding: 0,
				borderWidth: 0,
				useHTML: true,
				shape: 'square',
				backgroundColor: 'transparent',
				style: {
					color: 'white',
					boxSizing: 'border-box',
					boxShadow:
						'0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);',
				},
				formatter(tooltip) {
					return renderToStaticMarkup(
						<Tooltip context={this} tooltip={tooltip} />
					);
				},
				positioner(labelWidth, labelHeight, point) {
					let x = point.plotX + this.chart.plotLeft;

					// if no space on the right - tooltip goes left
					if (point.plotX + TOOLTIP_WIDTH > this.chart.plotWidth) {
						x -= TOOLTIP_WIDTH;
					}
					return {
						x,
						y: CHART_MARGIN_TOP,
					};
				},
			};

			plotOptions = {
				series: {
					states: {
						inactive: {
							opacity: 1,
						},
					},
				},
			};
		}

		const shapesToShow = Array.isArray(this.props.indexesToDisplay)
			? this.props.shapes.filter((_shape, index) =>
					this.props.indexesToDisplay.includes(index)
				)
			: this.props.shapes;

		const dataToShow = Array.isArray(this.props.indexesToDisplay)
			? this.props.data.filter((_, index) =>
					this.props.indexesToDisplay.includes(index)
				)
			: this.props.data;

		const { xAxes, yAxes } = axisGenerator(
			this.props.market,
			shapesToShow,
			this.props.chartType,
			this.props.timespan,
			this.getTitles,
			dataToShow,
			this.props.hasNewDesign
		);

		const options: Highcharts.Options = {
			chart: {
				zoomType: 'xy',
				// @ts-expect-error TS2322: Type 'unknown' is not assignab...
				height: this.props.height,
				// @ts-expect-error TS2322: Type 'unknown' is not assignab...
				width: this.props.width,
				animation: false,
				marginTop: CHART_MARGIN_TOP,
				style: {
					fontFamily: '"Gotham", "Open Sans", Helvetica, Arial, sans-serif',
					// @ts-expect-error TS2322: Type 'number' is not assignabl...
					fontWeight: 500,
				},
			},

			plotOptions,

			boost: {
				enabled: true,
				useGPUTranslations: true,
				// @ts-expect-error TS2322: Type '{ enabled: true; useGPUT...
				usePreAllocated: true,
			},

			xAxis: xAxes,

			yAxis: yAxes,

			title: {
				// @ts-expect-error TS2322: Type 'null' is not assignable ...
				text: null,
			},

			legend,

			tooltip: tooltipOptions,

			credits: {
				enabled: false,
			},

			exporting: {
				// @ts-expect-error TS2322: Type '{ decimalPoint: true; en...
				decimalPoint: true,
				enabled: false,
			},
		};

		return options;
	}

	generateSeries() {
		let shapesToShow;
		let dataToShow;

		if (this.props.indexesToDisplay) {
			// get only shapes and data that correspond to visible datasets
			shapesToShow = this.props.shapes.filter((_, idx) =>
				this.props.indexesToDisplay.includes(idx)
			);
			dataToShow = this.props.data.filter((_, idx) =>
				this.props.indexesToDisplay.includes(idx)
			);
		} else {
			shapesToShow = this.props.shapes;
			dataToShow = this.props.data;
		}
		let allSeries: Highcharts.SeriesOptionsType[];
		if (this.props.chartType === 'histogram') {
			allSeries = histogramSeries(
				this.props.timespan,
				shapesToShow,
				dataToShow,
				// @ts-expect-error TS2571: Object is of type 'unknown'....
				this.el.offsetWidth,
				this.props.minBarWidth,
				this.props.market
			);
		} else {
			// @ts-expect-error TS2740: Type '{ data: unknown; shape: ...
			allSeries = dataToShow.reduce((series, data, i) => {
				return lineOrPointSeries(
					series,
					shapesToShow[i],
					data.data,
					this.props.market,
					this.props.hasNewDesign
				);
			}, []);
		}
		return allSeries;
	}

	renderGraph() {
		if (this.el === null || this.props.data.length === 0) {
			return;
		}
		const allSeries = this.generateSeries();
		const chartSettings = this.generateSettings();
		// @ts-expect-error TS2769: No overload matches this call....
		this.chart = Highcharts.chart(this.el, {
			...chartSettings,
			series: allSeries,
		});
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'chart' does not exist on type 'Window & ... Remove this comment to see the full error message
		window.chart = this.chart;
	}

	// @ts-expect-error TS7006: Parameter 'el' implicitly has ...
	setElAndRender = (el) => {
		this.el = el;
		this.renderGraph();
	};

	// @ts-expect-error TS7006: Parameter 'csv' implicitly has...
	formatCSV = (csv) => {
		const allRows = csv
			.replace(/,/g, '\t')
			.replace(/"([0-9-]+) ([0-9:]+)"\t/g, '"$1"\t')
			.split('\n');

		// @ts-expect-error TS7006: Parameter 'col' implicitly has...
		const columns = allRows[0].split('\t').map((col) => {
			// for datasets with fewer than 100 data points, highcharts generates
			// a csv with two columns (labeled as 'y' and 'z' axes) instead of one;
			// we want to keep the data from the 'y' column (without the label)
			// and remove the 'z' column altogether
			if (col.endsWith(' (y)"')) {
				return col.slice(0, col.length - 5) + '"';
			} else if (col.endsWith(' (z)"')) {
				return null;
			} else {
				return col;
			}
		});

		const rows = allRows
			.slice(1)
			.reverse()
			// only export max of 121 rows
			.slice(0, 184);

		// @ts-expect-error TS7006: Parameter 'row' implicitly has...
		const rowsWithValidValues = rows.map((row) => {
			const values = row.split('\t');
			// @ts-expect-error TS7006: Parameter '_' implicitly has a...
			return values.filter((_, i) => columns[i]).join('\t');
		});

		// @ts-expect-error TS7006: Parameter 'c' implicitly has a...
		const titleRow = columns.filter((c) => c).join('\t');

		return [titleRow, ...rowsWithValidValues].join('\n');
	};

	render() {
		// @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"absolute... Remove this comment to see the full error message
		return <div style={style} ref={this.setElAndRender} />;
	}
}

// @ts-expect-error TS2345: Argument of type 'typeof Graph...
export default ShapeDataLoader(Graph);
