import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from '@compstak/ui-kit';
import { PortfolioV2 } from 'api';
import styled from 'styled-components';
import IconVerticalDots from 'ui/svg_icons/vertical-dots.svg';
import { PortfolioOptionsMenuV2 } from './PortfolioOptionsMenuV2';

type Props = {
	portfolioV2: PortfolioV2;
};

export const PortfolioOptionsButtonV2 = ({ portfolioV2 }: Props) => {
	return (
		<>
			<DropdownMenu.Root>
				<DropdownMenu.Trigger asChild>
					<OptionsButton variant={'ghost'} fitContent={true}>
						<IconVerticalDots />
					</OptionsButton>
				</DropdownMenu.Trigger>
				<PortfolioOptionsMenuV2 portfolio={portfolioV2} />
			</DropdownMenu.Root>
		</>
	);
};

const OptionsButton = styled(Button)`
	display: flex;
	align-items: center;
	&:hover {
		background-color: ${(p) => p.theme.colors.gray.gray200};
	}
`;
