import { Market } from '@compstak/common';
import dayjs from 'dayjs';
import {
	ChartType,
	DataSetType,
	Shape,
	Timespan,
} from 'Pages/Analytics/analytics';
import { dataSetTypeToFormattingAttr } from 'Pages/Analytics/Builder/chartBuilderConstants';
import { attributesHashFromShape } from 'Pages/Analytics/Builder/chartBuilderHelpers';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { AttributeType } from 'util/comp-format/attributes';
import { formatValue } from 'util/comp-format/src/format';
import styles from '../graphs.less';
import { dateTickPositioner, getAxisId } from '../util';
import { CompKeys } from 'types';

const formatLabel = (
	attribute: CompKeys,
	value: string,
	attributeType: AttributeType = 'lease'
) => {
	return formatValue(attribute, value, false, attributeType, undefined, {
		isYAxesFormatting: true,
	})
		?.toString()
		.replace('.00', '')
		.replace(/(\(Annual\))|(\(Monthly\))/, '')
		.trim();
};

const AXIS_STYLES = ['solid', 'dash', 'dot', 'dashdot'];

function addTitlesToAxes(
	// @ts-expect-error TS7006: Parameter 'axes' implicitly ha...
	axes,
	shapes: Shape[],
	xOrY: 'x' | 'y',
	market: Market
) {
	const titles: Record<string, string> = {};

	const shapesAndAttributes = shapes.map((shape) => ({
		shape,
		// @ts-expect-error TS7015: Element implicitly has an 'any...
		attribute: attributesHashFromShape(shape)[shape[xOrY]],
	}));

	shapesAndAttributes.forEach(({ attribute, shape }) => {
		if (!attribute?.name) {
			// TODO: Mufa ChartBuilder problems -> attribute should always have a name!
			console.error('Attribute name missing', { attribute, shape });
			return;
		}

		if (!titles[attribute.name]) {
			switch (attribute.name) {
				case 'startingRent':
					titles[attribute.name] = market.monthly
						? 'Starting Rent, Monthly, ($/SqFt)'
						: 'Starting Rent, Annual, ($/SqFt)';
					break;
				case 'effectiveRent':
					titles[attribute.name] = market.monthly
						? 'Effective Rent, Monthly, ($/SqFt)'
						: 'Effective Rent, Annual, ($/SqFt)';
					break;

				default:
					if (attribute.postUnit) {
						titles[attribute.name] =
							`${attribute.displayName} (${attribute.postUnit})`;
					} else {
						titles[attribute.name] = `${attribute.displayName}`;
					}
			}
		}
	});

	if (Object.keys(titles).length > 1) {
		shapesAndAttributes.forEach(({ shape, attribute }) => {
			if (!attribute?.name) {
				// Error is already logged in the previous attribute?.name check.
				return;
			}
			titles[attribute.name] =
				`<span style="color: ${shape.color}"> — </span> ` +
				titles[attribute.name];
		});
	}
	const axisTitles = {};

	Object.keys(titles).forEach((attribute) => {
		const axisId = getAxisId(attribute);
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (!axisTitles[axisId]) {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			axisTitles[axisId] = [titles[attribute]];
		} else {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			axisTitles[axisId].push(titles[attribute]);
		}
	});

	Object.keys(axisTitles).forEach((axisId) => {
		// @ts-expect-error TS7006: Parameter 'a' implicitly has a...
		const axis = axes.find((a) => a.id === axisId);
		if (axis && axis.title) {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			axis.title.text = axisTitles[axisId].join('<br />');
		}
	});
}

export const calculateMinDateBasedOnTimespan = (timespan: Timespan) =>
	dayjs().subtract(timespan, 'month').startOf('month');

export default function generateAxes(
	market: Market,
	shapes: Shape[],
	chartType: ChartType | undefined,
	timespan: Timespan,
	getTitles: (shape: Shape) => { x: string; y: string },
	data: any,
	hasNewDesign: boolean
) {
	// @ts-expect-error TS7034: Variable 'xAxes' implicitly ha...
	const xAxes = [];
	// @ts-expect-error TS7034: Variable 'yAxes' implicitly ha...
	const yAxes = [];

	shapes.forEach((shape, index) => {
		const xAxisId = getAxisId(chartType === 'histogram' ? shape.y : shape.x);
		const yAxisId =
			chartType === 'histogram' ? 'histogram' : getAxisId(shape.y);

		const formatSubject = function () {
			return formatLabel(
				shape.y as CompKeys,
				// @ts-expect-error TS2683: 'this' implicitly has type 'an...
				this.value,
				dataSetTypeToFormattingAttr[shape.dataSetType ?? DataSetType.LEASES]
			);
		};

		const formatOtherAxis = function () {
			if (chartType === 'histogram') {
				// @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
				return `${abbreviateNumber(this.value).replace(/\.0+/, '')} SqFt`;
			}

			return formatLabel(
				shape.y as CompKeys,
				// @ts-expect-error TS2683: 'this' implicitly has type 'an...
				this.value,
				dataSetTypeToFormattingAttr[shape.dataSetType]
			);
		};

		const axisTitles = getTitles(shape);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
		const xAxisIndex = xAxes.findIndex((axis) => axis.id === xAxisId);
		// @ts-expect-error TS7005: Variable 'xAxes' implicitly ha...
		let xAxis = xAxes[xAxisIndex];
		if (xAxis === undefined) {
			if (chartType === 'histogram') {
				xAxis = {
					title: {
						text: axisTitles.x,
						useHTML: true,
						margin: 12,
					},
					className: styles.xaxisContainer,
					labels: {
						formatter: formatSubject,
					},
					// there's a bug in highcharts that prevents multiple x axes from being shown.
					// https://github.com/highcharts/highcharts/issues/8557
					// if you can still see multiple x axes on the top and bottom
					// after uncommenting this line, do it!
					// opposite: xAxes.length % 2 === 1
				};
			} else {
				xAxis = {
					min: calculateMinDateBasedOnTimespan(timespan).valueOf(),
					max: dayjs().valueOf(),
					type: 'datetime',
					startOnTick: false,
					gridLineWidth: 1,
					opposite: xAxes.length % 2 === 1,
				};
			}

			if (hasNewDesign) {
				xAxis.crosshair = {
					dashStyle: 'dash',
					color: '#51576B',
					width: 2,
				};
			}

			xAxis.id = xAxisId;
			xAxes.push(xAxis);
		}

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
		const yAxisIndex = yAxes.findIndex((axis) => axis.id === yAxisId);
		// @ts-expect-error TS7005: Variable 'yAxes' implicitly ha...
		let yAxis = yAxes[yAxisIndex];
		if (yAxis === undefined) {
			let tickPositioner = null;
			if (
				chartType !== 'histogram' &&
				(shape.y === 'leaseTerm' || shape.y === 'freeMonths')
			) {
				tickPositioner = function () {
					// @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
					return dateTickPositioner(this.dataMin, this.dataMax);
				};
			}

			yAxis = {
				id: yAxisId,
				minPadding: 0,
				maxPadding: 0,
				title: {
					text: axisTitles.y,
					margin: 20,
				},
				labels: {
					formatter: formatOtherAxis,
				},
				gridLineDashStyle: AXIS_STYLES[yAxes.length % AXIS_STYLES.length],
				opposite: yAxes.length % 2 === 1,
				tickPositioner,
			};

			// very weird way to check if shape is shown as "bubbles"
			const isBubble = !!data?.[index]?.data?.points;

			// don't show grid lines for bubbles
			if (hasNewDesign && isBubble) {
				yAxis = {
					...yAxis,
					gridLineWidth: 0,
				};
			}

			yAxes.push(yAxis);
		}
	});

	// @ts-expect-error TS7005: Variable 'xAxes' implicitly ha...
	addTitlesToAxes(xAxes, shapes, chartType === 'histogram' ? 'y' : 'x', market);
	// @ts-expect-error TS7005: Variable 'yAxes' implicitly ha...
	addTitlesToAxes(yAxes, shapes, chartType === 'histogram' ? 'x' : 'y', market);

	// @ts-expect-error TS7005: Variable 'xAxes' implicitly ha...
	return { xAxes, yAxes };
}
