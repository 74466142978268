import * as Tabs from '@radix-ui/react-tabs';
import { PortfolioTab } from 'PortfolioAnalytics/Tabs/PortfolioTab';
import { MarketsTab } from 'PortfolioAnalytics/Tabs/MarketsTab';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DashboardHeader } from './DashboardHeader';
import { DashboardHeaderV2 } from './DashboardHeader/DashboardHeaderV2';
import { DashboardSidebar } from './DashboardSidebar';
import { PortfolioOverview } from './PortfolioOverview';
import { PortfolioOverviewV2 } from './PortfolioOverview/PortfolioOverviewV2';
import { LeaseActivityTab } from 'PortfolioAnalytics/Tabs/LeaseActivityTab';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { PortfolioLeasesProvider } from 'PortfolioAnalytics/LeaseActivity/PortfolioLeasesProvider';
import { PropertiesTab } from 'PortfolioAnalytics/Tabs/PropertiesTab';
import { PortfolioPropertiesProvider } from 'PortfolioAnalytics/Properties/PortfolioPropertiesProvider';
import { routes, useNavigate } from 'router';
import { EmptyPortfolioV2 } from 'PortfolioAnalytics/EmptyPortfolioV2';
import { PortfolioV2 } from 'api';
import { isPortfolioEmpty } from 'PortfolioAnalytics/utils/isPortfolioEmpty';

type Props = {
	portfolio: PortfolioV2;
};

export enum PortfolioTabEnum {
	OVERVIEW = 'overview',
	MARKETS = 'markets',
	LEASE_ACTIVITY = 'leaseActivity',
	PROPERTIES = 'properties',
}

export const Dashboard = ({ portfolio }: Props) => {
	const portfolioId = portfolio.portfolio.id;

	const { portfolioRevampOverviewTabFF, portfolioRevampPropertiesTabFF } =
		useFeatureFlags();

	const [portfolioSearchParams] = routes.portfolioByIdView.useSearchParams();

	const HeaderComponent = portfolioRevampOverviewTabFF
		? DashboardHeaderV2
		: DashboardHeader;
	const OverviewComponent = portfolioRevampOverviewTabFF
		? PortfolioOverviewV2
		: PortfolioOverview;
	const portfolioTabValue = portfolioRevampOverviewTabFF
		? PortfolioTabEnum.OVERVIEW
		: 'portfolio';

	const [tab, _setTab] = useState<PortfolioTabEnum | 'portfolio'>(
		portfolioRevampOverviewTabFF
			? portfolioSearchParams.tab ?? PortfolioTabEnum.OVERVIEW
			: portfolioTabValue
	);

	const setTab = (tab: PortfolioTabEnum) => {
		_setTab(tab);

		if (portfolioRevampOverviewTabFF) {
			navigate(
				routes.portfolioByIdView.toHref(
					{
						portfolioId,
						viewType: 'dashboard',
					},
					{ tab }
				)
			);
		}
	};

	const [contentEl, setContentEl] = useState<HTMLDivElement | null>(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (
			portfolioRevampOverviewTabFF &&
			(!Object.values(PortfolioTabEnum).includes(tab as PortfolioTabEnum) ||
				tab === PortfolioTabEnum.MARKETS)
		) {
			setTab(PortfolioTabEnum.OVERVIEW);
		}
	}, []);

	return (
		<Root>
			<Content ref={setContentEl}>
				<Tabs.Root
					value={tab}
					onValueChange={(value) => {
						setTab(value as PortfolioTabEnum);
					}}
				>
					<HeaderComponent
						portfolio={portfolio}
						dashboardElement={contentEl}
						tab={tab as PortfolioTabEnum}
					/>
					{isPortfolioEmpty(portfolio) ? (
						<EmptyPortfolioV2 />
					) : (
						<TabContentContainer>
							<Tabs.Content value={portfolioTabValue}>
								<OverviewComponent portfolioId={portfolioId} />
								<PortfolioTab portfolioId={portfolioId} />
							</Tabs.Content>
							{!portfolioRevampOverviewTabFF && (
								<Tabs.Content value={PortfolioTabEnum.MARKETS}>
									<OverviewComponent portfolioId={portfolioId} />
									<MarketsTab portfolioId={portfolioId} />
								</Tabs.Content>
							)}
							<Tabs.Content value={PortfolioTabEnum.LEASE_ACTIVITY}>
								<PortfolioLeasesProvider>
									<LeaseActivityTab />
								</PortfolioLeasesProvider>
							</Tabs.Content>
							<Tabs.Content value={PortfolioTabEnum.PROPERTIES}>
								<PortfolioPropertiesProvider portfolio={portfolio}>
									<PropertiesTab />
								</PortfolioPropertiesProvider>
							</Tabs.Content>
						</TabContentContainer>
					)}
				</Tabs.Root>
			</Content>
			{!portfolioRevampPropertiesTabFF && (
				<DashboardSidebar portfolioId={portfolioId} />
			)}
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	height: 100%;
`;

const Content = styled.div`
	height: 100%;
	flex: 1;
	overflow: auto;
`;

const TabContentContainer = styled.div`
	padding: 1.5rem;
	background-color: ${(p) => p.theme.colors.neutral.n20};
`;
