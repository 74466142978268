import { numberUtils } from '@compstak/ui-kit';
import { CompsetDTO } from 'api';
import { PropertyComp } from 'types';
import { ExcelColumn } from '../../utils';
import { getDistanceBetweenProperties } from '../../../../utils/getDistanceBetweenProperties';

export const excelColumns: ExcelColumn<
	CompsetDTO,
	{ subjectProperty: PropertyComp }
>[] = [
	{
		header: 'Building Name, Address',
		body: ({ row }) => getBuildingName(row.property),
	},
	{
		header: 'Submarket',
		body: ({ row }) => row.property.submarket,
	},
	{
		header: 'Distance From Subject Property',
		body: ({ row, extraData }) => {
			const distance = getDistanceBetweenProperties(
				extraData.subjectProperty,
				row.property
			);

			return numberUtils.formatNumber(distance, 'twoDecimals');
		},
	},
	{
		header: 'Market Starting Rent $/SqFt',
		body: ({ row }) =>
			row.property.propertyMarketStartingRent
				? numberUtils.formatNumber(
						row.property.propertyMarketStartingRent,
						'twoDecimals'
					)
				: '',
	},
	{
		header: 'Year Built',
		body: ({ row }) => row.property.buildingYear ?? '',
	},
	{
		header: 'Year Renovated',
		body: ({ row }) => row.property.buildingYearRenovated ?? '',
	},
	{
		header: 'Floors',
		body: ({ row }) => row.property.buildingFloorsCount ?? '',
	},
	{
		header: 'Size (SqFt)',
		body: ({ row }) => row.property.buildingSize ?? '',
	},
];

export const getBuildingName = (data: PropertyComp) => {
	const { buildingAddress, buildingName } = data;

	if (!buildingName && !buildingAddress) {
		return '';
	}

	if (!buildingName) {
		return buildingAddress;
	}

	if (!buildingAddress) {
		return buildingName;
	}

	return `${buildingName}, ${buildingAddress}`;
};
