import { API } from '@compstak/common';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import dayjs from 'dayjs';

export const usePortfolioInteractionsQuery = (
	options?: UseQueryOptions<PortfolioInteractions>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_INTERACTIONS],
		queryFn: async () => {
			const response = await API.get<ServerPortfolioInteractions>(
				'/pa/v2/api/portfolios/interactions/summary'
			);
			const data = response.data.reduce<PortfolioInteractions>((acc, pi) => {
				acc[pi.portfolioId] = {
					...pi,
					leaseActivityLastViewedAt: dayjs(
						pi.leaseActivityLastViewedAt
					).toDate(),
				};
				return acc;
			}, {});
			return data;
		},
		...options,
	});
};

type ServerPortfolioInteractions = {
	portfolioId: number;
	leaseActivityLastViewedAt?: string;
}[];

export type PortfolioInteractions = Record<
	number,
	{ portfolioId: number; leaseActivityLastViewedAt: Date }
>;
