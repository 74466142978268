import { MQB, TooltipV2 } from '@compstak/ui-kit';
import { CreateSavedSearchModal } from 'Components/SavedSearches/CreateSavedSearchModal';
import { getSetFilterKeysExceptMarkets } from 'models/filters/util/filterHelpers';
import { useModal } from 'providers/ModalProvider';
import { useMediaQuery } from 'react-responsive';
import { useFilters } from 'reducers/filtersReducer';
import { useCompType } from 'router/useCompType';
import IconBookmark from 'ui/svg_icons/bookmark.svg';
import buttonStyles from '../../../ui/styles/button.less';
import { useFindSavedSearchToEdit } from 'hooks/useFindSavedSearchToEdit';
import { EditSavedSearchModal } from 'Components/SavedSearches/EditSavedSearchModal';
import pluralizeCompType from 'util/pluralizeCompType';

export const SaveSearchButton = () => {
	const [filters] = useFilters();
	const hasSetFilters = getSetFilterKeysExceptMarkets(filters).length > 0;

	const isDisabled = !hasSetFilters;

	const isLargeScreen = useMediaQuery({ minWidth: MQB.D_1920 });

	const compType = useCompType();

	const { openModal } = useModal();

	const savedSearch = useFindSavedSearchToEdit();

	if (!compType) return null;

	const isButtonWide = compType !== 'property' || isLargeScreen;

	return (
		<TooltipV2 content={isButtonWide ? '' : 'Save Search'}>
			<button
				className={
					`${buttonStyles.button} button_export` +
					(isDisabled ? ' button_disabled' : '')
				}
				disabled={isDisabled}
				onClick={() => {
					openModal({
						modalContent: savedSearch ? (
							<EditSavedSearchModal savedSearch={savedSearch} />
						) : (
							<CreateSavedSearchModal
								savedSearch={{
									filters,
									searchType: pluralizeCompType(compType, true),
								}}
							/>
						),
					});
				}}
			>
				<IconBookmark />
				{isButtonWide && 'SAVE SEARCH'}
			</button>
		</TooltipV2>
	);
};
