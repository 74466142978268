import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { PortfolioV2, Portfolio, PortfolioFilterQuery } from '../../types';
import { composeEnabled } from '../../utils';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { API } from '@compstak/common';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { convertPortfolioToPortfolioV2 } from './convertPortfolioToPortfolioV2';

export const usePortfolioByIdQueryV2 = (
	params: PortfolioByIdParams,
	options?: UseQueryOptions<PortfolioByIdV2Response>
) => {
	const queryParams = getQueryStringFromPortfolioFilters(params.filters);
	const { portfolioRevampFF } = useFeatureFlags();

	return useQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_BY_ID_V2, params.id, queryParams],
		queryFn: () =>
			fetchPortfolio({
				portfolioId: params.id!,
				queryParams,
				portfolioRevampFF,
			}),
		...options,
		enabled: composeEnabled(params.id != null, options?.enabled),
	});
};

export type PortfolioByIdParams = {
	id: number | undefined;
	filters?: PortfolioFilterQuery;
};

export type PortfolioByIdV2Response = PortfolioV2;

export const fetchPortfolio = async ({
	portfolioId,
	queryParams,
	portfolioRevampFF,
}: {
	portfolioId: number;
	queryParams: string;
	portfolioRevampFF: boolean;
}) => {
	const endpoint = portfolioRevampFF
		? '/pa/v2/api/v2/portfolios'
		: '/pa/v2/api/portfolios';

	const response = await API.get<Portfolio | PortfolioV2>(
		`${endpoint}/${portfolioId}${queryParams}`
	);

	if (!portfolioRevampFF) {
		const convertedPortfolio = convertPortfolioToPortfolioV2(
			response.data as Portfolio
		);
		return convertedPortfolio;
	}
	return response.data as PortfolioV2;
};
