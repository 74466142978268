import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { MufaUnitMixSummaryResponse } from 'api';
import { formatInteger, formatNumber, formatPercent } from 'format';
import { renderSafeValue } from 'utils';
import { useTheme } from 'styled-components';
import { PdfRealPageLogoView } from '../PdfRealPageLogoView';
import { pdfStyle } from '../style';

export const SummaryPdf = ({
	summary,
}: {
	summary: MufaUnitMixSummaryResponse | undefined;
}) => {
	const theme = useTheme();

	const stylesCard = {
		backgroundColor: theme.colors.neutral.n10,
		color: theme.colors.neutral.n700,
		flex: 1,
		height: '110px',
		padding: '24px 16px',
		borderRadius: '4',
		boxShadow: '0px 1px 2px 0px rgba(51, 51, 51, 0.1)',
	};

	return (
		<>
			<View style={styles.cardContainer}>
				<View style={stylesCard}>
					<Text style={styles.value}>
						{renderSafeValue({
							value: summary?.totalUnits,
							format: (v) => formatInteger(v, { shorten: false }),
						})}
					</Text>
					<Text style={styles.label}>Total Units</Text>
				</View>
				<View style={stylesCard}>
					<Text style={styles.value}>
						{renderSafeValue({
							value: summary?.marketRateUnits,
							format: (v) => formatInteger(v, { shorten: false }),
						})}
					</Text>
					<Text style={styles.label}>Market Rate Units</Text>
				</View>
				<View style={stylesCard}>
					<Text style={styles.value}>
						{renderSafeValue({
							value: summary?.averageUnitSqFt,
							format: (v) => formatNumber(v, { shorten: false }) + ' SqFt',
						})}
					</Text>
					<Text style={styles.label}>Average Unit SqFt</Text>
				</View>
				<View style={stylesCard}>
					<Text style={styles.value}>
						{renderSafeValue({
							value: summary?.occupancy,
							format: (v) => formatPercent(v, 1),
						})}
					</Text>
					<Text style={styles.label}>Occupancy</Text>
				</View>
				<View style={stylesCard}>
					<Text style={styles.value}>
						{renderSafeValue({
							value: summary?.grossLivingArea,
							format: (v) => formatNumber(v, { shorten: false }) + ' SqFt',
						})}
					</Text>
					<Text style={styles.label}>Gross Living Area</Text>
				</View>
			</View>
			<View style={pdfStyle.multifamilyRpContainer}>
				<PdfRealPageLogoView />
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	cardContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: '16px',
	},
	label: {
		fontSize: '16px',
		fontWeight: 300,
		lineHeight: 1,
		letterSpacing: 0.025,
	},
	value: {
		fontSize: '16px',
		fontWeight: 500,
		lineHeight: 1.25,
		textTransform: 'uppercase',
	},
});
