import { useEffect } from 'react';
import { routes, useNavigate } from 'router';
import { usePortfoliosSortedByMostRecent } from './usePortfoliosSortedByMostRecent';
import { useSharedPortfolioByIdQuery } from 'api/portfolio/sharedPortfolio/useSharedPortfolioByIdQuery';
import { useCopySharedPortfolioMutation } from 'api/portfolio/sharedPortfolio/useCopySharedPortfolioMutation';

/**
 * if portfolio can not be found via `portfolioId` path variable, then redirects to:
 * - most recent portfolio from the list of all portfolios
 * - to portfolios home route if there are no portfolios
 *
 * if `sharedPortfolioId` search param is specified, then:
 * - on copy creation success of a shared portfolio redirects to it
 * - on copy creation error of a shared portfolio proceeds with the common logic
 */
export const useRedirectPortfolio = () => {
	const { portfolioId, viewType } = routes.portfolioByIdView.useParams();
	const { sharedPortfolioId } = routes.portfolioByIdView.useSearchParams()[0];

	const { sortedPortfolios, isFetching: isFetchingAllPortfolios } =
		usePortfoliosSortedByMostRecent();
	const { data: sharedPortfolio, isFetching: isFetchingSharedPortfolio } =
		useSharedPortfolioByIdQuery(
			{ id: sharedPortfolioId! },
			{ enabled: !!sharedPortfolioId && !portfolioId }
		);
	const {
		data: copiedSharedPortfolio,
		isLoading: isLoadingCopySharedPortfolio,
		isError: isErrorCopySharedPortfolio,
		mutate: copySharedPortfolio,
	} = useCopySharedPortfolioMutation();

	const navigate = useNavigate();

	const isFetching =
		isFetchingAllPortfolios ||
		isFetchingSharedPortfolio ||
		isLoadingCopySharedPortfolio;

	useEffect(() => {
		if (isFetching) return;

		if (sharedPortfolio && !isErrorCopySharedPortfolio) {
			if (!copiedSharedPortfolio) {
				copySharedPortfolio(sharedPortfolio);
			} else {
				navigate(
					routes.portfolioByIdView.toHref({
						portfolioId: copiedSharedPortfolio.id,
						viewType: 'dashboard',
					})
				);
			}
			return;
		}

		const mostRecentPortfolio = sortedPortfolios[0];

		if (!mostRecentPortfolio) {
			navigate(routes.portfolios.path);
			return;
		}

		let shouldRedirect = false;

		if (portfolioId == null) {
			shouldRedirect = true;
		} else {
			const portfolioExists = sortedPortfolios.find(
				(p) => p.id === Number(portfolioId)
			);
			shouldRedirect = !portfolioExists;
		}

		if (shouldRedirect) {
			navigate(
				routes.portfolioByIdView.toHref({
					portfolioId: mostRecentPortfolio.id,
					viewType: 'dashboard',
				})
			);
		}

		if (viewType && !['dashboard', 'list', 'map'].includes(viewType)) {
			navigate(
				routes.portfolioByIdView.toHref({ portfolioId, viewType: 'dashboard' })
			);
		}
	}, [
		sortedPortfolios,
		isFetching,
		portfolioId,
		navigate,
		sharedPortfolio,
		copiedSharedPortfolio,
		copySharedPortfolio,
		isErrorCopySharedPortfolio,
	]);

	return {
		isFetching,
	};
};
