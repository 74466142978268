import { Button, TooltipV2 } from '@compstak/ui-kit';
import { formatInteger } from 'format';
import { SelectionState } from 'utils';
import { useModal } from 'providers/ModalProvider';
import { PortfolioPropertiesRemovalModal } from 'PortfolioAnalytics/modals/PortfolioPropertiesRemovalModal';
import { PortfolioV2 } from 'api';

type Props = {
	portfolio: PortfolioV2;
	selectionState: SelectionState;
};

export const RemovePropertiesButton = ({
	portfolio,
	selectionState,
}: Props) => {
	const { selection, resetSelection } = selectionState;

	const { openModal, closeModal } = useModal();

	return (
		<TooltipV2
			content={
				selection.length === 0 ? 'Select properties to remove' : undefined
			}
		>
			<Button
				size="l"
				disabled={selection.length === 0}
				onClick={() =>
					openModal({
						modalContent: (
							<PortfolioPropertiesRemovalModal
								portfolio={portfolio}
								onClose={closeModal}
								onSuccess={resetSelection}
								removedPropertyIds={selection}
							/>
						),
					})
				}
			>
				&mdash; Remove from portfolio ({formatInteger(selection.length)})
			</Button>
		</TooltipV2>
	);
};
