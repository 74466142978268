import { nullValueText } from '@compstak/common';
import React from 'react';
import { SalesComp } from 'types';
import { PDFTable } from './UI';

const SalesTableRow = (props: SalesComp) => {
	return (
		<tr>
			<td>{props.portfolio?.[0]?.buildingAddress ?? nullValueText}</td>
			<td data-qa-id="sales-portfolio-city-data">
				{props.portfolio?.[0].city ?? nullValueText}
			</td>
			<td>{props.portfolio?.[0].submarket ?? nullValueText}</td>
			<td>{props.saleDate ?? nullValueText}</td>
			<td>{props.totalSalePrice ?? nullValueText}</td>
			<td>
				{props.transactionSize
					? String(props.transactionSize).replace(' SqFt', '')
					: nullValueText}
			</td>
			<td>{props.salePricePsf ?? nullValueText}</td>
		</tr>
	);
};

export const SalesTable = ({ salesComps }: { salesComps: SalesComp[] }) => {
	return (
		<PDFTable>
			<thead>
				<tr>
					<th>Address</th>
					<th>City</th>
					<th>Submarket</th>
					<th>Sale Date</th>
					<th>Total Sale Price</th>
					<th>Transaction Sqft</th>
					<th>Sale Price (PSF)</th>
				</tr>
			</thead>
			<tbody>
				{salesComps.slice(0, 10).map((comp) => (
					<SalesTableRow key={comp.id} {...comp} />
				))}
			</tbody>
		</PDFTable>
	);
};
