import { nullValueText } from '@compstak/common';
import { Pinpoint } from '@compstak/maps';
import {
	Flex,
	SimpleTableColumn,
	renderTableValue,
	TooltipV2,
	defaultTheme,
	Pending,
} from '@compstak/ui-kit';
import { formatInteger, formatMoney, formatPercent } from 'format';

import { Link, routes } from 'router';
import styled from 'styled-components';

import IconTrash from 'ui/svg_icons/delete.svg';
import { TitleWithTooltip } from './TitleWithTooltip';
import { useModal } from 'providers/ModalProvider';
import { RemovePortfolioModal } from '../RemovePortfolioModal';
import { PortfolioTabEnum } from 'PortfolioAnalytics/Dashboard';
import { useCallback } from 'react';
import { usePortfolioQueries } from 'api/portfolio/portfolioById/usePortfolioQueries';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';

export const useBenchmarkTableConfig = () => {
	const portfolioIds = [1030, 1031, 1032, 1024, 1023]; // This is a placeholder for the actual portfolio ids
	const { filters } = usePortfolioFilters();
	const results = usePortfolioQueries(
		portfolioIds.map((id) => ({ id, filters }))
	);

	const comparedPortfolios = results.map(({ data }) => {
		return {
			id: data?.portfolio.id,
			portfolioName: data?.portfolio.name,
			markets:
				data && data?.markets.length > 2 ? (
					<>
						<TooltipV2 content={data?.markets.map((m) => m.market).join(', ')}>
							<StyledSpan>{data?.markets.length} Markets</StyledSpan>
						</TooltipV2>
					</>
				) : (
					data?.markets.map((m) => m.market).join(', ')
				),
			propertiesInPortfolio: data && (
				<StyledLink
					to={routes.portfolioByIdView.toHref(
						{
							portfolioId: data.portfolio.id,
						},
						{ tab: PortfolioTabEnum.PROPERTIES }
					)}
					target="_blank"
				>
					{data.metrics.propertyCount}
				</StyledLink>
			),
			officeSpaceType: renderTableValue(null, (v) => formatPercent(v, 2)),
			retailSpaceType: renderTableValue(null, (v) => formatPercent(v, 2)),
			industrialSpaceType: renderTableValue(null, (v) => formatPercent(v, 2)),
			landSpaceType: renderTableValue(null, (v) => formatPercent(v, 2)),
			otherSpaceType: renderTableValue(null, (v) => formatPercent(v, 2)),
			activeLeaseAmount: renderTableValue(
				data?.metrics.activeLeaseAmount,
				(v) => formatInteger(v) + ' SF'
			),
			activeLeaseCount: data && (
				<StyledLink
					to={routes.portfolioByIdView.toHref(
						{
							portfolioId: data.portfolio.id,
						},
						{ tab: PortfolioTabEnum.LEASE_ACTIVITY }
					)}
					target="_blank"
				>
					{data?.metrics.activeLeaseCount}
				</StyledLink>
			),
			avgFreeRent: renderTableValue(
				data?.metrics.avgFreeRent,
				(v) => formatInteger(v) + ` mo`
			),
			avgLeaseTerm: renderTableValue(
				data?.metrics.avgLeaseTerm,
				(v) => formatInteger(v) + ` mo`
			),
			avgCurrentRent: renderTableValue(data?.metrics.avgCurrentRent, (v) =>
				formatPercent(v / 100, 2)
			),
			avgNetEffectiveRent: renderTableValue(
				data?.metrics.avgNetEffectiveRent,
				(v) => formatMoney(v)
			),
			avgTIImprovement: renderTableValue(data?.metrics.avgTIImprovement, (v) =>
				formatMoney(v)
			),
		};
	});

	const tableItems: {
		attribute: keyof (typeof comparedPortfolios)[0];
		header: string | React.ReactNode;
	}[] = [
		{ attribute: 'markets', header: 'Markets' },
		{ attribute: 'propertiesInPortfolio', header: 'Properties in Portfolio' },
		{ attribute: 'officeSpaceType', header: 'Office • Space Type' },
		{ attribute: 'retailSpaceType', header: 'Retail • Space Type' },
		{ attribute: 'industrialSpaceType', header: 'Industrial • Space Type' },
		{ attribute: 'landSpaceType', header: 'Land • Space Type' },
		{ attribute: 'otherSpaceType', header: 'Other • Space Type' },
		{
			attribute: 'activeLeaseAmount',
			header: (
				<TitleWithTooltip
					title="Active Leased SqFt"
					tooltipContent="totalSqFt"
				/>
			),
		},
		{
			attribute: 'activeLeaseCount',
			header: (
				<TitleWithTooltip
					title="Total Active Leases"
					tooltipContent="activeLeaseCount"
				/>
			),
		},
		{
			attribute: 'avgFreeRent',
			header: (
				<TitleWithTooltip
					title="Average Free Rent"
					tooltipContent="avgFreeRent"
				/>
			),
		},
		{
			attribute: 'avgLeaseTerm',
			header: (
				<TitleWithTooltip
					title="Average Lease Term"
					tooltipContent="avgLeaseTerm"
				/>
			),
		},
		{
			attribute: 'avgCurrentRent',
			header: (
				<TitleWithTooltip
					title="Average Current Rent"
					tooltipContent="avgCurrentRent"
				/>
			),
		},
		{
			attribute: 'avgTIImprovement',
			header: (
				<TitleWithTooltip
					title="Average T.I. Allowance"
					tooltipContent="avgTIImprovement"
				/>
			),
		},
		{
			attribute: 'avgNetEffectiveRent',
			header: (
				<TitleWithTooltip
					title="Average Net Effective Rent"
					tooltipContent="avgNetEffectiveRent"
				/>
			),
		},
	];

	const { openModal } = useModal();

	const handleOpenModal = useCallback(
		({ portfolioName, id }: { portfolioName: string; id: number }) => {
			openModal({
				modalContent: (
					<RemovePortfolioModal
						portfolioName={portfolioName}
						portfolioId={id}
					/>
				),
			});
		},
		[openModal]
	);

	const transposedColumns: SimpleTableColumn<
		(typeof transposedRows)[number]
	>[] = [
		{
			id: 'attribute',
			width: 160,
			header: '',
			body: ({ row }) => row.header,
			flex: 1,
		},
		...comparedPortfolios.map((portfolio, rowIndex) => ({
			id: tableItems[rowIndex].attribute,
			width: 160,
			header: () => {
				return portfolio.portfolioName && portfolio.id ? (
					<Flex justifyContent="normal">
						<Flex justifyContent="flex-start">
							<Pinpoint
								showCircle
								style={{ width: '1rem', height: '1.25rem' }}
								// TODO: update pinColor prop in map library, and allow to import other colors
								// PIN_COLORS_PALETTE = {
								// 	0: defaultTheme.colors.blue.blue500,
								// 	1: defaultTheme.colors.green.green500,
								// 	2: defaultTheme.colors.purple.purple700,
								// 	3: defaultTheme.colors.gray.n4,
								// 	4: defaultTheme.colors.dataSet.color5,
								// };
							/>
							<StyledLink
								to={routes.portfolioByIdView.toHref({
									portfolioId: portfolio.id,
								})}
								target="_blank"
								color={defaultTheme.colors.gray.gray400}
							>
								<u>{portfolio.portfolioName}</u>
							</StyledLink>
						</Flex>
						<StyledIconTrash
							onClick={() => {
								handleOpenModal({
									portfolioName: portfolio.portfolioName!,
									id: portfolio.id!,
								});
							}}
						/>
					</Flex>
				) : (
					<Pending bgColor={defaultTheme.colors.gray.gray100} />
				);
			},
			body: ({ row }: { row: (typeof transposedRows)[number] }) => {
				if (!row.values[rowIndex]) return nullValueText;

				return row.values[rowIndex];
			},
			flex: 2,
		})),
	];
	const transposedRows = tableItems.map((c) => ({
		attribute: c.attribute,
		header: c.header,
		values: comparedPortfolios.map((row) => {
			return row[c.attribute];
		}),
	}));

	return { transposedRows, transposedColumns };
};

const StyledSpan = styled.span`
	color: ${(p) => p.theme.colors.blue.blue500};
`;
const StyledIconTrash = styled(IconTrash)`
	width: 1.5rem;

	cursor: pointer;
	fill: ${(p) => p.theme.colors.red.red500};
`;

const StyledLink = styled(Link)<{ colors?: keyof typeof defaultTheme.colors }>`
	text-decoration: underline;

	u {
		color: ${(p) => p.theme.colors.gray.n100};
	}
`;
