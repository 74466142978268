import React, { useCallback, useState } from 'react';
import { PropertyPageTab } from '../types';
import { Button, Flex, Spinner } from '@compstak/ui-kit';

import IMAGE_SALES_DEMO_PATH from './sales-demo.jpg';
import IMAGE_LOANS_DEMO_PATH from './loans-demo.jpg';

import styled from 'styled-components';
import { useUserQuery } from 'api';
import { useModal } from 'providers/ModalProvider';
import { ThanksForDemoRequestModal } from 'Components/Modals/ThanksForDemoRequestModal/ThanksForDemoRequestModal';
import { useRequestSalesDemoMutation } from 'api/requestDemo/requestSalesDemo';

type Props = {
	tab: PropertyPageTab.LOANS | PropertyPageTab.SALES;
};
export const RequestDemo = ({ tab }: Props) => {
	const { data: user } = useUserQuery();

	const requestDemo = useRequestSalesDemoMutation();

	const [isRequested, setIsRequested] = useState(false);

	const { openModal } = useModal();

	const handleRequest = useCallback(() => {
		setIsRequested(true);
		requestDemo.mutate(
			{ usernameOrEmail: user.username },
			{
				onSuccess: () => {
					openModal({
						modalContent: (
							<ThanksForDemoRequestModal
								onClose={() => setIsRequested(false)}
							/>
						),
					});
				},
			}
		);
	}, [openModal, requestDemo, user.username]);

	return (
		<StyledFlex
			gap="64px"
			justifyContent="space-around"
			style={{ marginTop: 48 }}
		>
			<StyledDiv>
				<p>
					Interested in accessing more data sets? CompStak One provides
					comprehensive access to Lease Comps, Sale Comps, Property Information,
					Loan Data, and Analytics.
				</p>

				<Button
					variant="primary"
					type="button"
					onClick={handleRequest}
					disabled={isRequested}
				>
					{isRequested ? <Spinner size="xs" /> : 'REQUEST DEMO'}
				</Button>
			</StyledDiv>

			<img
				src={
					tab === PropertyPageTab.SALES
						? IMAGE_SALES_DEMO_PATH
						: IMAGE_LOANS_DEMO_PATH
				}
				alt="Demo"
				width={640}
			/>
		</StyledFlex>
	);
};

RequestDemo.displayName = 'DemoRequest';

const StyledDiv = styled.div`
	max-width: 432px;

	button {
		width: 100%;
	}
`;

const StyledFlex = styled(Flex)`
	flex-wrap: wrap;
`;
