import { Button, MQB } from '@compstak/ui-kit';
import styled from 'styled-components';

export const PageTitleWrap = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

export const PageTitle = styled.div`
	font-feature-settings:
		'liga' off,
		'clig' off;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 1.3em;
	color: ${(p) => p.theme.colors.gray.gray500};
	overflow: hidden;
`;

export const PageTitleRow = styled.div`
	font-style: normal;
	display: grid;
	gap: 3rem;
	grid-template-columns: minmax(50px, 1fr) auto;
	@media (max-width: ${MQB.D_1280}) {
		gap: 2.5rem;
	}
`;

export const PageTitleRowLeft = styled.div``;

export const PageTitleRowRight = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 0.5rem;
	width: 100%;
`;

export const PageTitleRowRightButtons = styled.div`
	display: flex;
	flex-direction: row;
	row-gap: 0.75rem;
	column-gap: 1rem;

	@media (max-width: ${MQB.D_1280}) {
		column-gap: 0.5rem;
	}
`;

export const PageTitleRowIconButton = styled(Button)`
	min-width: 0;
	padding: 0.5em;

	svg {
		@media (max-width: ${MQB.D_1280}) {
			width: 26px;
			height: 26px;
		}
	}
`;

export const MenuContainer = styled.div`
	margin: -10px;
	background: #303441;
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
	font-size: 0.8125rem;

	ul {
		position: relative;
		z-index: 2;

		li {
			display: flex;
			align-items: center;
			letter-spacing: 1px;
			line-height: 14px;
			color: #fff;
			text-transform: uppercase;
			font-weight: 300;
			padding: 1rem;
			text-align: left;
			font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
			&:hover {
				background: #464d5e;
				cursor: pointer;
			}
		}
	}
`;

export const TabCardItem = styled.div`
	flex: 1;
	background-color: #ffffff;
	padding: 1em;
	border-radius: 3px;
	position: relative;
	font-size: 1rem;
	@media (max-width: ${MQB.D_1280}) {
		font-size: 0.8125rem;
	}
`;

export const ChartsContainer = styled.div`
	min-width: 365px;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

export const ChartsContainerV2 = styled.div`
	min-width: 365px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem;
	${TabCardItem} {
		border: 1px solid ${({ theme }) => theme.colors.gray.gray100};
		box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
		min-width: 550px;
		@media (max-width: ${MQB.D_1280}) {
			min-width: 470px;
		}
	}
`;

export const MarketsChartsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	row-gap: 1.5rem;
`;

export const FilterDropdownContainer = styled.div<{
	width: number;
	top?: number;
	left?: number;
}>`
	position: absolute;
	padding: 8px 0 3px;
	top: ${(props) => (props.top ? `${props.top}px` : '-8px')};
	left: ${(props) => (props.left ? `${props.left}px` : '0')};
	min-height: 100px;
	max-height: 465px;
	width: ${(props) => `${props.width}px`};
	background-color: white;
	box-shadow:
		0px 4px 12px rgba(50, 50, 71, 0.08),
		0px 16px 24px rgba(50, 50, 71, 0.08);
	border-radius: 3px;
	z-index: 10;
	overflow: hidden;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	column-gap: 0.5rem;
	@media (min-width: 1535px) {
		gap: 1rem;
	}
`;

export const ChartHeadingAndDropDownContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	column-gap: 0.5rem;
`;

export const Heading = styled.div`
	align-items: center;
	gap: 6px;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 500;
	line-height: 0.75rem;
	text-transform: uppercase;
	color: hsl(226, 15%, 22%);
	@media (min-width: ${MQB.D_1536}) {
		font-size: 0.813rem;
	}
	@media (min-width: ${MQB.D_1920}) {
		font-size: 1rem;
	}
`;

export const Legend = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 2rem;
	margin: 1rem 0 0;
`;

export const LegendItem = styled.div`
	display: flex;
	flex-direction: row;
	margin: 0 0 1rem;
`;

export const ChartAndLegendContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 95%;
	padding: 1rem;
`;

export const LegendColor = styled.div<{ color: string }>`
	background-color: ${(props) => props.color};
	border-radius: 2px;
	margin: 0 0.5rem 0 0;
	min-width: 17px;
	max-width: 17px;
	min-height: 17px;
	max-height: 17px;
`;

export const LegendLabel = styled.span`
	font-family:
		Gotham,
		Roboto Mono;
	text-transform: uppercase;
	font-size: 0.6875em;
	line-height: 13px;
`;

export const MarketsHeadingWrapper = styled.div<{ marginBottom?: number }>`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${({ marginBottom }) =>
		marginBottom ? `${marginBottom}px` : '19px'};
`;

export const MarketsHeading = styled.div`
	display: flex;

	h4 {
		font-size: 1em;
		line-height: 28px;
		text-transform: uppercase;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: calc(100vw - 850px);
	}

	div {
		margin: 7.5px;
	}
`;

export const ChartContainer = styled.div`
	height: 320px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 0px;
`;
