import { nullValueText } from '@compstak/common';
import {
	renderTableValue,
	SimpleTableColumn,
	TooltipV2,
} from '@compstak/ui-kit';
// @ts-expect-error TS2305: Module '"api"' has no exported...
import { LeaseComp } from 'api';
import {
	formatCompQuarter,
	formatObfuscatedSizeRange,
	formatRentWithPostfix,
	formatSqft,
} from 'format';
import { IconLockGrey } from 'ui/UI';
import IconHelp from 'ui/svg_icons/help_v2.svg';
import dateFormat from 'util/comp-format/src/util/calendarDate';
import { getIsMonthlyMarket } from '../../../../utils';
import { TableCellGapper } from '../../Components';
import { getShouldShowLockIcon } from 'utils';
import { useIsExchange } from '../../../../hooks';
import { COMP_FIELD_TOOLTIPS } from '../../../../constants/tooltips';

export const useColumnsPortfolio = (): SimpleTableColumn<LeaseComp>[] => {
	const isExchange = useIsExchange();

	return [
		{
			id: 'executionQuarter',
			header: () => 'Trans. Qtr.',
			width: 92,
			body: ({ row }) => {
				return renderTableValue(row.executionQuarter, formatCompQuarter);
			},
			hAlign: 'center',
		},
		{
			id: 'buildingAddress',
			header: () => 'Address',
			width: 192,
			body: ({ row }) => {
				return renderTableValue(row.buildingAddress);
			},
		},
		{
			id: 'cityState',
			header: () => 'City, State',
			width: 120,
			body: ({ row }) => {
				return `${row.city}, ${row.state}`;
			},
		},
		{
			id: 'tenantName',
			header: () => 'Tenant',
			width: 200,
			body: ({ row }) => {
				return renderTableValue(row.tenantName);
			},
			flex: 1,
		},
		{
			id: 'transactionSize',
			header: () => (
				<TableCellGapper>
					Size
					<TooltipV2 content="Total SqFt reported. If Retail, total Main floor SqFt only.">
						<div>
							<IconHelp width={16} />
						</div>
					</TooltipV2>
				</TableCellGapper>
			),
			width: 105,
			body: ({ row }) => {
				return renderTableValue(row.transactionSize, (v: number) =>
					row.own ? formatSqft(v) : formatObfuscatedSizeRange(v)
				);
			},
			flex: 1,
		},
		{
			id: 'adjEffectiveRent',
			header: () => (
				<TableCellGapper>
					Net Effective Rent
					<TooltipV2 content={COMP_FIELD_TOOLTIPS.effectiveRent}>
						<div>
							<IconHelp width={16} />
						</div>
					</TooltipV2>
				</TableCellGapper>
			),
			width: 124,
			body: ({ row }) => {
				if (getShouldShowLockIcon(row.adjEffectiveRent, row, isExchange)) {
					return <IconLockGrey />;
				}
				const isMontly = getIsMonthlyMarket(row.marketId);
				return renderTableValue(row.adjEffectiveRent, (v) =>
					formatRentWithPostfix(v, isMontly)
				);
			},
			flex: 1,
		},
		{
			id: 'expirationDate',
			header: () => 'Expiration Date',
			width: 155,
			body: ({ row }) => {
				if (getShouldShowLockIcon(row.expirationDate, row, isExchange)) {
					return <IconLockGrey />;
				}

				return row.expirationDate
					? dateFormat(row.expirationDate, true, row.locale)
					: nullValueText;
			},
			flex: 1,
		},
	];
};
