import { API } from '@compstak/common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { SavedSearch } from './useSavedSearchesQuery';

export const useViewSavedSearchMutation = (
	options?: UseMutationOptions<unknown, unknown, UpdateSavedSearchParams>
) => {
	return useMutation({
		mutationFn: async (params: UpdateSavedSearchParams) => {
			const response = await API.put(
				`/api/savedSearches/${params.id}/actions/viewed`
			);
			return response.data;
		},
		...options,
	});
};

export type UpdateSavedSearchParams = Pick<SavedSearch, 'id'>;
