import { nullValueText } from '@compstak/common';
import { getPinLetter, Pinpoint } from '@compstak/maps';
import {
	numberUtils,
	renderTableValue,
	SimpleTableColumn,
} from '@compstak/ui-kit';
import { CompsetDTO, CompsetType, MufaCompsetDTO, useCompsetType } from 'api';
import { TableCellGapper } from 'Components/UI';
import { formatInteger, formatRent } from 'format';
import { PropertyComp } from 'types';
import { getDistanceBetweenProperties, getIsMonthlyMarket } from '../../utils';
import { useDeleteColumn } from './useDeleteColumn';

const PINPOINT_WIDTH = 18;

type Props = {
	propertyId: number;
	subjectProperty: PropertyComp | undefined;
	addDeleteColumn?: boolean;
	rows: CompsetDTO[];
};

export const useCompsetTableColumns = ({
	propertyId,
	subjectProperty,
	addDeleteColumn,
	rows,
}: Props) => {
	const { data: compsetType } = useCompsetType({ propertyId });
	const deleteColumn = useDeleteColumn({ propertyId, rows });

	if (!subjectProperty) return [];

	const commercialColumns: SimpleTableColumn<CompsetDTO>[] = [
		{
			id: 'buildingAddressAndCity',
			header: () => {
				return 'Building Name, Address';
			},
			body: ({ row, rowIndex }) => {
				let pin = (
					<Pinpoint
						style={{ width: PINPOINT_WIDTH }}
						text={getPinLetter(rowIndex - 1)}
					/>
				);

				if (row.property.id === propertyId) {
					pin = (
						<Pinpoint
							style={{ width: PINPOINT_WIDTH }}
							showCircle
							pinColor="gray"
						/>
					);
				}

				return (
					<TableCellGapper>
						{pin}
						{renderTableValue(row.property.buildingAddressAndCity)}
					</TableCellGapper>
				);
			},
			width: 155,
			flex: 2,
		},
		{
			id: 'submarket',
			header: () => {
				return 'Submarket';
			},
			body: ({ row }) => {
				return renderTableValue(row.property.submarket);
			},
			width: 90,
			hAlign: 'center',
			flex: 1,
		},
		{
			id: 'distance',
			header: () => {
				return 'Distance From Subject Property';
			},
			body: ({ row }) => {
				if (row.property.id === propertyId) return nullValueText;

				return renderTableValue(row.property.geoPoint, () => {
					const distance = getDistanceBetweenProperties(
						subjectProperty,
						row.property
					);
					return numberUtils.formatNumber(distance, 'twoDecimals') + ' mi';
				});
			},
			width: 110,
			hAlign: 'center',
			flex: 1,
		},
		{
			id: 'propertyMarketStartingRent',
			header: () => {
				return 'Market Starting Rent $/SqFt';
			},
			body: ({ row }) => {
				return renderTableValue(
					row.property.propertyMarketStartingRent,
					(v) => `$${numberUtils.formatNumber(v, 'twoDecimals')}`
				);
			},
			width: 110,
			hAlign: 'center',
			flex: 1,
		},
		{
			id: 'buildingYear',
			header: () => {
				return 'Year Built';
			},
			body: ({ row }) => {
				return renderTableValue(row.property.buildingYear);
			},
			width: 60,
			hAlign: 'center',
			flex: 1,
		},
		{
			id: 'buildingYearRenovated',
			header: () => {
				return 'Year Renovated';
			},
			body: ({ row }) => {
				return renderTableValue(row.property.buildingYearRenovated);
			},
			width: 60,
			hAlign: 'center',
			flex: 1,
		},
		{
			id: 'buildingFloorsCount',
			header: () => {
				return 'Floors';
			},
			body: ({ row }) => {
				return renderTableValue(row.property.buildingFloorsCount);
			},
			width: 60,
			hAlign: 'center',
			flex: 1,
		},
		{
			id: 'buildingSize',
			header: () => {
				return 'Size (SqFt)';
			},
			body: ({ row }) => {
				return renderTableValue(row.property.buildingSize, (v) =>
					formatInteger(v, { shorten: false })
				);
			},
			width: 70,
			hAlign: 'center',
			flex: 1,
		},
	];

	const mufaColumns: SimpleTableColumn<MufaCompsetDTO>[] = [
		...commercialColumns.slice(0, 3),
		{
			id: 'askingRentPerSqFt',
			header: () => 'Asking Rent $/SqFt',
			body: ({ row }) => {
				const isMonthly = getIsMonthlyMarket(row.property.marketId);
				return renderTableValue(row.property.askingRentPerSqFt, (v) =>
					formatRent(v, isMonthly)
				);
			},
			width: 70,
			flex: 1,
			hAlign: 'center',
		},
		{
			id: 'effectiveRentPerSqFt',
			header: () => 'Effective Rent $/SqFt',
			body: ({ row }) => {
				const isMonthly = getIsMonthlyMarket(row.property.marketId);
				return renderTableValue(row.property.effectiveRentPerSqFt, (v) =>
					formatRent(v, isMonthly)
				);
			},
			width: 70,
			flex: 1,
			hAlign: 'center',
		},
		{
			id: 'numberOfUnits',
			header: () => '# of Units',
			body: ({ row }) => {
				return renderTableValue(row.property.numberOfUnits, (v) =>
					numberUtils.formatNumber(v, 'integer')
				);
			},
			width: 60,
			flex: 1,
			hAlign: 'center',
		},
		...commercialColumns.slice(4),
	];

	const columns =
		compsetType === CompsetType.COMMERCIAL ? commercialColumns : mufaColumns;

	if (addDeleteColumn && rows.length > 0) {
		columns.push(deleteColumn);
	}

	return columns;
};
