import constate from 'constate';
import { useSelectionState } from 'utils/useSelectionState';
import {
	SPACE_TYPE_NAME_TO_ID,
	useCompsetAddresses,
	useLeasesInCompset,
} from 'api';
import {
	usePropertyLeasesOptionsState,
	usePropertyLeasesActive,
	usePropertyLeasesBySpaceType,
	usePropertyLeasesFiltered,
} from '../hooks/propertyLeases';
import { usePropertyLeasesUrl } from 'hooks/usePropertyLeasesUrl';

export const [LeasesInCompsetTableDataProvider, useLeasesInCompsetTableData] =
	constate(({ propertyId }: { propertyId: number }) => {
		const {
			checkedSpaceType,
			setCheckedSpaceType,
			checkedStatus,
			setCheckedStatus,
		} = usePropertyLeasesOptionsState();

		const { data: leasesData } = useLeasesInCompset({ propertyId });

		const leasesComps = leasesData?.comps ?? [];

		const leasesActive = usePropertyLeasesActive({
			leases: leasesComps,
		});

		const leasesByStatus =
			checkedStatus === 'Only Active Leases' ? leasesActive : leasesComps;

		const leasesGrouppedBySpaceType = usePropertyLeasesBySpaceType({
			leases: leasesComps,
		});

		const filteredLeases = usePropertyLeasesFiltered({
			leases: leasesByStatus,
			checkedSpaceType,
			checkedStatus,
		});

		const selectionState = useSelectionState(filteredLeases);

		const addresses = useCompsetAddresses({ propertyId: propertyId });

		const compsetLeasesUrl = usePropertyLeasesUrl({
			propertyId,
			addresses,
			spaceTypeId:
				checkedSpaceType === 'All Leases'
					? undefined
					: SPACE_TYPE_NAME_TO_ID[checkedSpaceType],
			isActiveLeasesOnly: checkedStatus === 'Only Active Leases',
		});

		return {
			leases: leasesComps,
			filteredLeases,
			leasesByStatus,
			leasesGrouppedBySpaceType,
			checkedSpaceType,
			setCheckedSpaceType,
			checkedStatus,
			setCheckedStatus,
			selectionState,
			leasesActive,
			compsetLeasesUrl,
		};
	});
