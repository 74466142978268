import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { ServerFilterItem } from 'types/serverFilters';
import { CompType, LeaseComp, PropertyComp, SalesComp } from 'types';
import { API } from '@compstak/common';
import {
	SavedSearchDigest,
	SavedSearchEmailFrequency,
	SavedSearchType,
} from './types';

export const SAVED_SEARCH_TYPE_TO_COMP_TYPE: Record<SavedSearchType, CompType> =
	{
		[SavedSearchType.LEASES]: 'lease',
		[SavedSearchType.SALES]: 'sale',
		[SavedSearchType.PROPERTIES]: 'property',
	};

export type SavedSearch<
	T extends SavedSearchType =
		| SavedSearchType.LEASES
		| SavedSearchType.SALES
		| SavedSearchType.PROPERTIES,
> = {
	id: string;
	lastRan: Date;
	filters: ServerFilterItem[];
	name: string;
	notifyByEmail: boolean;
	searchType: T;
	sortAsc: boolean;
	sortProperty: T extends SavedSearchType.PROPERTIES
		? keyof PropertyComp
		: T extends SavedSearchType.SALES
			? keyof SalesComp
			: keyof LeaseComp;
	userId: number;
	// marketId field required by BE
	marketId: number;
	dateSeen: Date;
	emailFrequency: SavedSearchEmailFrequency;
	digestEmailFrequency: SavedSearchDigest;
};

export const useSavedSearchesQuery = (
	options?: UseQueryOptions<SavedSearch[]>
) =>
	useQuery({
		queryKey: [QUERY_KEYS.SAVED_SEARCHES],
		queryFn: async () => {
			const response = await API.get<SavedSearch[]>(`/api/savedSearches`);
			return response.data;
		},
		...options,
	});
