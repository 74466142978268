import { UseQueryOptions, useQueries } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import {
	PortfolioByIdParams,
	PortfolioByIdV2Response,
	fetchPortfolio,
} from './usePortfolioByIdQueryV2';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { composeEnabled } from 'api/utils';

export const usePortfolioQueries = (
	multiParams: PortfolioByIdParams[],
	options?: UseQueryOptions<PortfolioByIdV2Response>
) => {
	const { portfolioRevampFF } = useFeatureFlags();

	return useQueries({
		queries: multiParams.map((params) => {
			const queryParams = getQueryStringFromPortfolioFilters(params.filters);

			return {
				queryKey: [QUERY_KEYS.PORTFOLIO_BY_ID_V2, params.id, queryParams],
				queryFn: () =>
					fetchPortfolio({
						portfolioId: params.id!,
						queryParams,
						portfolioRevampFF,
					}),
				...options,
				enabled: composeEnabled(params.id != null, options?.enabled),
			};
		}),
	});
};
