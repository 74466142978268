import { Button, ButtonProps, SemanticPaletteKeys } from '@compstak/ui-kit';
import { AddToPortfolioDialogSelector } from 'Components/AddToPortfolioDialog/AddToPortfolioDialogSelector';
import { MultiPropertyInfoModal } from 'Components/Modals/MultiPropertyModal/MultiPropertyModal';
import { useIsExchange } from 'hooks';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useModal } from 'providers/ModalProvider';
import { useState } from 'react';
import styled from 'styled-components';

import IconAddToPortfolio from 'ui/svg_icons/add_to_portfolio.svg';

type Props = {
	propertyIds: number[];
	isPortfolio?: boolean;
} & ButtonProps;

export const AddToPortfolioButton = ({
	propertyIds,
	isPortfolio,
	variant = 'primary2',
	...props
}: Props) => {
	const isEnterprise = !useIsExchange();

	const [isAddToPortfolioDialogOpen, setIsAddToPortfolioDialogOpen] =
		useState(false);

	const { openModal } = useModal();
	const { portfolioRevampFF } = useFeatureFlags();

	const handleClick = () => {
		if (isPortfolio) {
			openModal({
				modalContent: (
					<MultiPropertyInfoModal
						totalProperties={propertyIds.length}
						setIsAddToPortfolioDialogOpen={setIsAddToPortfolioDialogOpen}
					/>
				),
			});
		} else {
			setIsAddToPortfolioDialogOpen(true);
		}
	};

	return portfolioRevampFF && isEnterprise ? (
		<>
			<ButtonStyled
				variant={variant}
				onClick={handleClick}
				icon={<IconAddToPortfolio />}
				{...props}
			>
				Add to Portfolio
			</ButtonStyled>

			{isAddToPortfolioDialogOpen && (
				<AddToPortfolioDialogSelector
					closeDialog={() => setIsAddToPortfolioDialogOpen(false)}
					propertyIds={propertyIds}
				/>
			)}
		</>
	) : null;
};

const ButtonStyled = styled(Button)<{ variant: SemanticPaletteKeys }>`
	gap: 0.25rem;

	svg path {
		fill: ${({ theme, variant }) =>
			!['primary2', 'ghost'].includes(variant) ? theme.colors.white.white : ''};
	}
`;
