import {
	SavedSearchDigest,
	SavedSearchEmailFrequency,
} from 'api/savedSearches/types';
import { useCreateSavedSearchMutation } from 'api/savedSearches/useCreateSavedSearchMutation';
import { filtersToServerJSON } from 'models/filters/util';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useModal } from 'providers/ModalProvider';
import { ToastLink, useToast } from 'providers/ToastProvider';
import { routes } from 'router';
import { SavedSearchActionModal } from './SavedSearchActionModal';
import { SavedSearch } from 'api/savedSearches/useSavedSearchesQuery';
import { FiltersObject } from 'models/filters/types';

type Props = {
	savedSearch: {
		filters: FiltersObject;
	} & Pick<SavedSearch, 'searchType'>;
};

export const CreateSavedSearchModal = ({ savedSearch }: Props) => {
	const { closeModal } = useModal();

	const { mutateAsync: createSavedSearch, isLoading } =
		useCreateSavedSearchMutation();

	const { addToast, removeToast } = useToast();

	const { searchType, filters } = savedSearch;

	return (
		<SavedSearchActionModal
			actionType="create"
			isLoading={isLoading}
			onSave={(name, emailFrequency, digestEmailFrequency) => async () => {
				await createSavedSearch(
					{
						name,
						emailFrequency,
						digestEmailFrequency,
						filters: filtersToServerJSON(filters),
						marketId: getFiltersMarkets(filters)[0].id,
						notifyByEmail:
							emailFrequency !== SavedSearchEmailFrequency.Never ||
							digestEmailFrequency !== SavedSearchDigest.Never,
						searchType,
						sortAsc: filters.sortDirection === 'asc',
						sortProperty: filters.sortField,
					},
					{
						onSuccess: () => {
							closeModal();
							const toast = addToast({
								title: `Successfully created ${name}!`,
								description: (
									<>
										You can see comps that match your filter criteria any time
										by clicking{' '}
										<ToastLink
											to={routes.savedSearches.toHref()}
											onClick={() => {
												removeToast(toast.id);
											}}
										>
											Saved Searches
										</ToastLink>{' '}
										link in the header bar.
									</>
								),
							});
						},
						onError: () => {
							addToast({
								title: `Error, saved search not created.`,
								type: 'error',
							});
						},
					}
				);
			}}
		/>
	);
};
