import { useModal } from 'providers/ModalProvider';
import { useToast } from 'providers/ToastProvider';
import { SavedSearchActionModal } from 'Components/SavedSearches/SavedSearchActionModal';
import { SavedSearch } from 'api/savedSearches/useSavedSearchesQuery';
import { useUpdateSavedSearchMutation } from 'api/savedSearches/useUpdateSavedSearchMutation';
import {
	SavedSearchDigest,
	SavedSearchEmailFrequency,
} from 'api/savedSearches/types';

type Props = {
	savedSearch: SavedSearch;
};

export const EditSavedSearchModal = ({ savedSearch }: Props) => {
	const { closeModal } = useModal();

	const { mutate: updateSavedSearch, isLoading } =
		useUpdateSavedSearchMutation();

	const { addToast } = useToast();

	const { id, filters, marketId } = savedSearch;

	return (
		<SavedSearchActionModal
			actionType="update"
			savedSearch={savedSearch}
			isLoading={isLoading}
			onSave={(name, emailFrequency, digestEmailFrequency) => async () => {
				await updateSavedSearch(
					{
						id,
						body: {
							filters,
							notifyByEmail:
								emailFrequency !== SavedSearchEmailFrequency.Never ||
								digestEmailFrequency !== SavedSearchDigest.Never,
							marketId,
							name,
							emailFrequency,
							digestEmailFrequency,
						},
					},
					{
						onSuccess: () => {
							closeModal();
							addToast({
								title: `Successfully updated ${name}!`,
							});
						},
						onError: () => {
							addToast({
								title: `Error, saved search not updated.`,
								type: 'error',
							});
						},
					}
				);
			}}
		/>
	);
};
