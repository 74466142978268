import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { API } from '@compstak/common';
import { WithPreferencesParams } from './types';
import { UnionToIntersection } from 'types/utils';

export const usePortfolioPreferencesQuery = (
	params: PortfolioPreferencesParams,
	options?: UseQueryOptions<PortfolioPreferencesResponse>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_PREFERENCES, params],
		queryFn: async () => {
			const response = await API.get<PortfolioPreferencesResponse>(
				`/pa/v2/api/portfolios/${params.portfolioId}/preferences`
			);
			return response.data;
		},
		...options,
	});
};

export type PortfolioPreferencesParams = {
	portfolioId: number;
};

export type PortfolioPreferencesResponse = UnionToIntersection<
	WithPreferencesParams<{
		portfolioId: number;
	}>
>;
