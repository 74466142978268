import { PortfolioNotificationFrequency } from './types';

type PortfolioWithPreferencesPayload<T extends object> = T extends {
	notificationFrequency: PortfolioNotificationFrequency;
}
	? {
			portfolio: Omit<T, 'notificationFrequency'>;
			preferences: {
				notificationFrequency?: T['notificationFrequency'];
			};
		}
	: T;

export const getPortfolioWithPreferencesPayload = <T extends object>(
	params: T
): PortfolioWithPreferencesPayload<T> => {
	const withPreferences =
		'notificationFrequency' in params && !!params.notificationFrequency;

	if (!withPreferences) {
		return params as PortfolioWithPreferencesPayload<T>;
	}

	const { notificationFrequency, ...portfolio } = params;
	return {
		portfolio,
		preferences:
			notificationFrequency !== PortfolioNotificationFrequency.Never
				? { notificationFrequency }
				: {},
	} as PortfolioWithPreferencesPayload<T>;
};

export const getPortfolioWithPreferencesUrl = <T extends object>(
	url: string,
	params: T
) => {
	const withPreferences =
		'notificationFrequency' in params && !!params.notificationFrequency;

	return `${url}${withPreferences ? '/withPreferences' : ''}`;
};
