import { Button, Spinner } from '@compstak/ui-kit';
import { usePortfolioPropertiesQuery } from 'api';
import { usePortfolioByIdQueryV2 } from 'api/portfolio/portfolioById/usePortfolioByIdQueryV2';
import { Link } from 'router';
import { routes } from 'router';
import styled from 'styled-components';
import { APP_HEADER_HEIGHT } from '../../constants';
import { PortfolioStats } from '../Components';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { PortfolioMap } from '../PortfolioMap';
import {
	BackToDashboardArrowLeft,
	BackToDashboardLink,
	MapListLoadingContainer,
	Toolbar,
} from '../UI';

type Props = {
	portfolioId: number;
};

export const MapView = ({ portfolioId }: Props) => {
	const { filters } = usePortfolioFilters();

	const { data: portfolioV2, isFetching: isFetchingPortfolio } =
		usePortfolioByIdQueryV2({
			id: portfolioId,
			filters,
		});

	const { data: properties, isFetching: isFetchingPortfolioProperties } =
		usePortfolioPropertiesQuery({
			portfolioId,
			...filters,
		});

	const isFetching = isFetchingPortfolio || isFetchingPortfolioProperties;

	if (isFetching) {
		return (
			<MapListLoadingContainer>
				<Spinner />
			</MapListLoadingContainer>
		);
	}

	const totalActiveLeaseCount = portfolioV2?.metrics.activeLeaseCount ?? 0;

	return (
		<Root>
			<MapToolbar>
				<BackToDashboardLink
					to={routes.portfolioByIdView.toHref({
						portfolioId: portfolioId,
						viewType: 'dashboard',
					})}
				>
					<BackToDashboardArrowLeft />
					Back to Dashboard
				</BackToDashboardLink>
				<ControlsContainer>
					{portfolioV2 && properties && (
						<PortfolioStats
							portfolioId={portfolioId}
							portfolioName={portfolioV2.portfolio.name}
							propertyCount={properties.length}
							leaseCount={totalActiveLeaseCount}
						/>
					)}
					<Link
						to={`${routes.portfolioByIdView.toHref({
							portfolioId,
							viewType: 'list',
						})}${location.search ?? ''}`}
					>
						<Button variant="primary" size="l">
							VIEW PORTFOLIO LIST
						</Button>
					</Link>
				</ControlsContainer>
			</MapToolbar>
			<div>
				<PortfolioMap portfolioId={portfolioId} isMapView={true} />
			</div>
		</Root>
	);
};

const MAP_VIEW_TOOLBAR_HEIGHT = 84;

const Root = styled.div`
	display: grid;
	grid-template-rows: ${MAP_VIEW_TOOLBAR_HEIGHT}px auto;
	height: calc(100vh - ${APP_HEADER_HEIGHT}px);
	box-sizing: border-box;
	flex: 1;
	* {
		box-sizing: border-box;
	}
`;

const MapToolbar = styled(Toolbar)`
	padding: 0 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
`;

const ControlsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;
