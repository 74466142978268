import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useModal } from 'providers/ModalProvider';

export const RemovePortfolioModal = ({
	portfolioName,
	// TODO: Add portfolio id to the props
}: {
	portfolioName: string;
	portfolioId: number;
}) => {
	const { closeModal } = useModal();
	return (
		<>
			<ModalTitle>Remove portfolio from Comparison?</ModalTitle>
			<ModalParagraph>
				Click <b>CONFIRM</b> to remove {portfolioName} from the Comparison view.
				The portfolio can be reselected from the left panel if needed.
			</ModalParagraph>
			<ModalButtons>
				<ModalButton onClick={closeModal} variant="secondary2">
					Cancel
				</ModalButton>
				<ModalButton
					onClick={() => {
						// TODO fn for deleting portfolio from comparison
						// portfolio.id
					}}
				>
					Confirm
				</ModalButton>
			</ModalButtons>
		</>
	);
};
