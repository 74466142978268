import { API, APIClientNotOkResponseError } from '@compstak/common';
import {
	UseMutationOptions,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { PortfolioInteractions } from './usePortfolioInteractionsQuery';
import dayjs from 'dayjs';

export const useViewLeaseActivityMutation = (
	options?: UseMutationOptions<
		void,
		APIClientNotOkResponseError,
		ViewLeaseActivityParams
	>
) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({ portfolioId }: ViewLeaseActivityParams) => {
			const res = await API.post<void>(
				`/pa/v2/api/portfolios/${portfolioId}/interactions/leaseActivityViewed`
			);
			return res.data;
		},
		...options,
		onSuccess: (data, params, context) => {
			queryClient.setQueryData<PortfolioInteractions>(
				[QUERY_KEYS.PORTFOLIO_INTERACTIONS],
				(oldData) => {
					const portfolioInteraction = oldData?.[params.portfolioId];

					if (portfolioInteraction) {
						return {
							...oldData,
							[portfolioInteraction.portfolioId]: {
								...oldData[portfolioInteraction.portfolioId],
								leaseActivityLastViewedAt: dayjs().toDate(),
							},
						};
					}

					return oldData;
				}
			);
			options?.onSuccess?.(data, params, context);
		},
	});
};

type ViewLeaseActivityParams = {
	portfolioId: number;
};
