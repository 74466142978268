import {
	SimpleTable,
	SimpleTableHeaderCellRoot,
	defaultTheme,
} from '@compstak/ui-kit';
import { useBenchmarkTableConfig } from './useBenchmarkTableConfig';
import styled from 'styled-components';

const TABLE_ID = 'benchmark_table';

export const BenchmarkingTable = () => {
	const { transposedRows, transposedColumns } = useBenchmarkTableConfig();

	return (
		<SimpleTableWrapper>
			<SimpleTable
				columns={transposedColumns}
				rows={transposedRows}
				id={TABLE_ID}
				getBodyRowProps={({ rowIndex }) => ({
					style: {
						background:
							rowIndex % 2 === 0 ? 'none' : defaultTheme.colors.gray.gray200,
						width: 'fit-content',
					},
				})}
				headerHeight={60}
			/>
		</SimpleTableWrapper>
	);
};

const SimpleTableWrapper = styled.div`
	${SimpleTableHeaderCellRoot} {
		:first-child {
			width: 100%;
			height: 100%;
		}
	}
`;
