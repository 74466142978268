import { Button, MQB } from '@compstak/ui-kit';
import { useUserQuery } from 'api';
import { useIsExchange } from 'hooks';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, routes, useLocation } from 'router';
import styled from 'styled-components';
import { DesktopMenu } from './DesktopMenu';
import { MobileMenu } from './MobileMenu';
import { Header, RightContainer, StyledIconLogo } from './UI';
import { useCreditsLinks } from './useCreditsLinks';
import { usePromotionExperimentLinks } from './usePromotionExperimentLinks';
import { UserMenuButton } from './UserMenuButton';
import { useUpgradeLinks } from './useUpgradeLinks';
import { useModal } from 'providers/ModalProvider';
import { InviteACollegueModal } from 'Components/Modals/InviteACollegueModal/InviteACollegueModal';

export const AppHeader = () => {
	const { data: user } = useUserQuery();

	const isExchange = useIsExchange();
	const isEnterprise = !isExchange;

	const { openModal } = useModal();

	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const creditsLinks = useCreditsLinks();
	const promotionExperimentLinks = usePromotionExperimentLinks();
	const upgradeLinks = useUpgradeLinks();

	const isTablet = useMediaQuery({ maxWidth: MQB.T_834 });

	const location = useLocation();

	useEffect(() => {
		setIsMobileMenuOpen(false);
	}, [location]);

	return (
		<Header>
			{isTablet ? (
				<LogoButton
					onClick={() => {
						setIsMobileMenuOpen(true);
					}}
				>
					<StyledIconLogo />
				</LogoButton>
			) : (
				<Link to={routes.home.path}>
					<StyledIconLogo />
				</Link>
			)}
			{isTablet ? (
				<>
					{isMobileMenuOpen && (
						<MobileMenu closeMenu={() => setIsMobileMenuOpen(false)} />
					)}
				</>
			) : (
				<DesktopMenu />
			)}
			<RightContainer>
				{creditsLinks}
				{promotionExperimentLinks}
				{isEnterprise && (
					<InviteButton
						variant="secondary"
						onClick={() => {
							openModal({ modalContent: <InviteACollegueModal /> });
						}}
					>
						Invite a Colleague
					</InviteButton>
				)}
				{upgradeLinks}
				<UserMenuButton user={user} />
			</RightContainer>
		</Header>
	);
};

const LogoButton = styled.button`
	background-color: transparent;
	border: 0;
	padding: 0;
`;

const InviteButton = styled(Button)`
	font-weight: 400;
	text-transform: none;
`;
