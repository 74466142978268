import { MufaFloorPlan } from 'api';
import { PdfColumn } from '../types';
import { renderSafeValue } from 'utils';
import { formatInteger, formatMoneyMufa, formatPercent } from 'format';
import { nullValueText } from '@compstak/common';

export type FloorPlansColumn = PdfColumn<
	Record<string, string | number>,
	MufaFloorPlan
>;

export const getFloorPlansPdfColumns = () => {
	const columns: FloorPlansColumn[] = [
		{
			id: 'unitType',
			header: 'Unit Type',
			getValue: ({ row }) =>
				renderSafeValue({
					value: `${row.bedrooms} Bedroom/${row.bathrooms} Bath`,
				}),
			isVisible: true,
		},
		{
			id: 'unitSqFt',
			header: 'Unit Size (SqFt)',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.unitSqFt,
					format: (v) => formatInteger(v, { shorten: false }),
				}),
			isVisible: true,
			hAlign: 'center',
		},
		{
			id: 'unitCount',
			header: 'Total Units',
			getValue: ({ row }) => renderSafeValue({ value: row.unitCount }),
			isVisible: true,
			hAlign: 'center',
		},
		{
			id: 'askingRentPerUnit',
			header: 'Asking Rent $/Unit',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.askingRentPerUnit,
					format: (v) => formatMoneyMufa(v),
				}),
			isVisible: true,
			hAlign: 'center',
		},
		{
			id: 'askingRentPerSqFt',
			header: 'Asking Rent $/SqFt',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.askingRentPerSqFt,
					format: (v) => formatMoneyMufa(v),
				}),
			isVisible: true,
			hAlign: 'center',
		},
		{
			id: 'effectiveRentPerUnit',
			header: 'Effective Rent $/Unit',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.effectiveRentPerUnit,
					format: (v) => formatMoneyMufa(v),
				}),
			isVisible: true,
			hAlign: 'center',
		},
		{
			id: 'effectiveRentPerSqFt',
			header: 'Effective Rent $/SqFt',
			getValue: ({ row }) =>
				renderSafeValue({
					value: row.effectiveRentPerSqFt,
					format: (v) => formatMoneyMufa(v, 2),
				}),
			isVisible: true,
			hAlign: 'center',
		},
		{
			id: 'concessionValue',
			header: 'Concessions ($)',
			getValue: ({ row }) => {
				if (row.concessionValue === 0) return nullValueText;

				return renderSafeValue({
					value: row.concessionValue,
					format: (v) => formatMoneyMufa(v),
				});
			},
			isVisible: true,
			hAlign: 'center',
		},
		{
			id: 'concessionsPercentage',
			header: 'Concessions (%)',
			getValue: ({ row }) => {
				if (row.concessionsPercentage === 0) return nullValueText;

				return renderSafeValue({
					value: row.concessionsPercentage,
					format: (v) => formatPercent(v, 2),
				});
			},
			isVisible: true,
			hAlign: 'center',
		},
	];

	return columns;
};
