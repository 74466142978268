import React, { useCallback, useEffect } from 'react';
import {
	exportImage,
	FormSelect,
	NotificationType,
	SelectOnChangeParams,
	useNotifications,
	Pending,
	defaultTheme,
	Flex,
} from '@compstak/ui-kit';
import ExportIcon from 'ui/svg_icons/export_v2.svg';
import { useUser } from '../Login/reducers';
import { useMarkets } from 'hooks/useMarkets';
import {
	BetaChip,
	ButtonWrap,
	Description,
	ExportButton,
	FilterWrap,
	GlobalStyle,
	Header,
	HeaderMaxWidth,
	HeaderSection,
	MissingSomethingText,
	NoAccessibleMarketFound,
	PendingWrap,
	PrintHide,
	PrintWrap,
	SubHeader,
} from './styles';
import { FiltersObject } from '../../models/filters/types';
import { formatDate } from '../../format';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { z } from 'zod';
import userflow from 'userflow.js';
import { LEAGUE_TABLE_RIGHT_AREA_CLASSNAME } from '../LeagueTables/LeagueTableHeader';
import { FlowEndedEvent } from '../../services/impl/UserFlow';
import { PremierListContent } from './PremierListContent';
import {
	PremierListSpaceType,
	usePremierListCleanSectorsQuery,
} from '../../api/premierList/cleanSectors';

type MarketSelectItem = { value: string; title: string };
type PropertyTypeSelectItem = {
	value: PremierListSpaceType;
	title: PremierListSpaceType;
};

export type CommonFilters = Partial<FiltersObject> &
	Pick<FiltersObject, 'markets' | 'hidden' | 'partial'>;

export type PremierListTableProps = {
	commonFilters: CommonFilters;
	selectedSpaceTypeName: PremierListSpaceType;
};

const MARKET_SHAPE = z.string();

const DEFAULT_SPACE_TYPE_NAME: PremierListSpaceType = 'Office';
const SPACE_TYPE_SHAPE = z.union([
	z.literal('Office'),
	z.literal('Industrial'),
]);

const SPACE_TYPE_KEY = 'premier_list_space_type';
const MARKET_KEY = 'premier_list_market';

const USERFLOW_ID = 'bddf6af2-fad4-4355-a7b2-9add2883f1c0';

const PREMIER_LIST_FILTERS = 'premier-list-filters';
const PREMIER_LIST_EXPORT_BUTTON = 'premier-list-export-button';
const PREMIER_LIST_FEEDBACK_BLOCK = 'premier-list-feedback-block';

const SKIP_CLASSNAME_TO_EXPORT = [
	LEAGUE_TABLE_RIGHT_AREA_CLASSNAME,
	PREMIER_LIST_FILTERS,
	PREMIER_LIST_EXPORT_BUTTON,
	PREMIER_LIST_FEEDBACK_BLOCK,
];

export const PremierList = () => {
	const markets = useMarkets();
	const user = useUser();
	const exportRef = React.useRef<HTMLDivElement>(null);

	const { addNotification } = useNotifications();

	const initMarket = markets[user.primaryMarketId].name;

	const [selectedMarket, setSelectedMarket] = useLocalStorage<string>(
		MARKET_KEY,
		initMarket,
		MARKET_SHAPE.parse
	);

	const [selectedSpaceTypeName, setSpaceTypeName] =
		useLocalStorage<PremierListSpaceType>(
			SPACE_TYPE_KEY,
			DEFAULT_SPACE_TYPE_NAME,
			SPACE_TYPE_SHAPE.parse
		);

	const { data, isLoading } = usePremierListCleanSectorsQuery({
		onSuccess: ({ array, map }) => {
			if (!map[selectedMarket]) {
				const firstAccessibleSector = array[0];
				if (!firstAccessibleSector) {
					console.error(
						`No intersection of user's market with clean sectors. Why is the Premier list access enabled?`
					);
				} else {
					setSelectedMarket(markets[firstAccessibleSector.marketId].name);
					setSpaceTypeName(firstAccessibleSector.assetTypes[0]);
				}
			} else if (!map[selectedMarket].includes(selectedSpaceTypeName)) {
				setSpaceTypeName(map[selectedMarket][0]);
			}
		},
	});

	const { array: cleanSectorsResponse, map: cleanSectorsMap } = data || {
		array: [],
		map: {},
	};

	const marketItems = cleanSectorsResponse.map((sector) => ({
		value: markets[sector.marketId].name,
		title: markets[sector.marketId].displayName,
	}));

	const spaceTypeItems =
		cleanSectorsMap[selectedMarket]?.map((sector) => ({
			value: sector,
			title: sector,
		})) ?? [];

	const flowEndHandler = useCallback((e: FlowEndedEvent) => {
		if (e.flow.id === USERFLOW_ID && e.endReason === 'ACTION') {
			addNotification({
				type: NotificationType.BLUE,
				description: 'Thank you for your feedback!',
			});
		}
	}, []);

	useEffect(() => {
		userflow.on('flowEnded', flowEndHandler);
		return () => userflow.off('flowEnded', flowEndHandler);
	}, []);

	const handleMarketChange = (obj: SelectOnChangeParams<MarketSelectItem>) => {
		const value = obj.selectedItem?.value ?? '';
		setSelectedMarket(value);
		if (!cleanSectorsMap[value].includes(selectedSpaceTypeName)) {
			setSpaceTypeName(cleanSectorsMap[value][0]);
		}
	};

	const handleSpaceTypeChange = (
		obj: SelectOnChangeParams<PropertyTypeSelectItem>
	) => {
		const value = obj.selectedItem?.value as PremierListSpaceType | undefined;
		value && setSpaceTypeName(value);
	};

	const disabledMarketSelect = isLoading || marketItems.length === 0;
	const disabledSpaceTypeSelect =
		isLoading || spaceTypeItems.length === 0 || !selectedMarket;

	const marketDisplayName = markets[selectedMarket]?.displayName;
	const contentIsReadyToBeLoaded =
		selectedSpaceTypeName && marketDisplayName && marketItems.length > 0;

	const content = (
		<>
			<HeaderSection>
				<HeaderMaxWidth>
					<Header>
						<div>
							<Flex gap="0.5rem" justifyContent="initial">
								<span>Top of the Stak</span>
								<BetaChip>BETA</BetaChip>
							</Flex>
							{isLoading ? (
								<PendingWrap>
									<Pending
										margin="1px 0px"
										bgColor={defaultTheme.colors.gray.gray300}
									/>
								</PendingWrap>
							) : (
								contentIsReadyToBeLoaded && (
									<SubHeader>
										<strong>{selectedSpaceTypeName}</strong> in{' '}
										<strong>{marketDisplayName}</strong> as of{' '}
										{formatDate(new Date(), 'M/D/YY [at] h:mmA')}
									</SubHeader>
								)
							)}
						</div>
						<ButtonWrap className={PREMIER_LIST_EXPORT_BUTTON}>
							<ExportButton
								onClick={() => {
									if (!exportRef.current) return;
									exportImage({
										element: exportRef.current,
										hideTitle: true,
										filename: `Premier List ${selectedSpaceTypeName} in ${
											markets[selectedMarket].displayName
										} as of ${formatDate(new Date(), 'M/D/YY [at] h:mmA')}`,
										imageWidth: 2040,
										ignoreElements: (node) => {
											return SKIP_CLASSNAME_TO_EXPORT.some((className) =>
												node.classList.contains(className)
											);
										},
									});
								}}
								variant="primary"
								size="l"
								icon={<ExportIcon />}
								disabled={disabledMarketSelect || disabledSpaceTypeSelect}
							>
								<span>EXPORT</span>
							</ExportButton>
						</ButtonWrap>
					</Header>
					<Description>
						Explore real-time rankings of the largest and most active
						participants in your market with CompStak's{' '}
						<strong>Top of the Stak</strong>. Enter your desired market and
						asset class for instant results.
					</Description>
					<FilterWrap className={PREMIER_LIST_FILTERS}>
						<FormSelect
							items={marketItems}
							value={markets[selectedMarket]?.name}
							onChange={handleMarketChange}
							placeholder={
								isLoading
									? 'Loading...'
									: marketItems.length === 0
										? 'No items'
										: 'Select'
							}
							noResultMessage="There is no data..."
							showScrollbarOnHover
							isSearchable
							isLoading={isLoading}
							disabled={disabledMarketSelect}
						/>
						<FormSelect
							items={spaceTypeItems}
							value={selectedSpaceTypeName}
							onChange={handleSpaceTypeChange}
							placeholder={
								isLoading
									? 'Loading...'
									: spaceTypeItems.length === 0
										? 'No items'
										: 'Select market'
							}
							noResultMessage="There is no data..."
							showScrollbarOnHover
							isLoading={isLoading}
							disabled={disabledSpaceTypeSelect}
						/>
					</FilterWrap>
					<MissingSomethingText className={PREMIER_LIST_FEEDBACK_BLOCK}>
						Help us improve this feature,{' '}
						<strong
							onClick={() => {
								try {
									userflow.start(USERFLOW_ID);
								} catch (e) {
									console.error('Failed to open the flow', e);
								}
							}}
						>
							share your feedback
						</strong>
						.
					</MissingSomethingText>
				</HeaderMaxWidth>
			</HeaderSection>
			{!isLoading &&
				(contentIsReadyToBeLoaded ? (
					<PremierListContent
						selectedSpaceTypeName={selectedSpaceTypeName}
						selectedMarket={selectedMarket}
					/>
				) : (
					<NoAccessibleMarketFound>
						<h3>No accessible market found</h3>
					</NoAccessibleMarketFound>
				))}
		</>
	);

	return (
		<div>
			<GlobalStyle />
			{content}
			<PrintHide>
				<PrintWrap ref={exportRef}>{content}</PrintWrap>
			</PrintHide>
		</div>
	);
};
