import { API, APIClientNotOkResponseError } from '@compstak/common';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

export const usePortfolioDataRequestMutation = (
	options?: UseMutationOptions<
		void,
		APIClientNotOkResponseError,
		PortfolioDataRequestParams
	>
) => {
	return useMutation({
		mutationFn: async (params: PortfolioDataRequestParams) => {
			const res = await API.post<void, PortfolioDataRequestParams>(
				'/pa/v2/api/portfolios/dataRequest',
				params
			);
			return res.data;
		},
		...options,
	});
};

export type PortfolioDataRequestParams = {
	portfolioId: number;
	title: string;
	description: string;
};
