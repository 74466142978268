import { useEffect, useMemo, useState } from 'react';
import {
	Button,
	SimpleTable,
	Pending,
	defaultTheme,
	SimpleTableBodyRow,
	SimpleTableHeader,
} from '@compstak/ui-kit';
import {
	SavedSearch,
	useSavedSearchesQuery,
} from 'api/savedSearches/useSavedSearchesQuery';
import SavedSearchIcon from 'ui/svg_icons/saved_search.svg';
import styled from 'styled-components';
import { useMarketsHaveFeature } from 'api';
import { useSavedSearchesColumns } from './useSavedSearchesColumns';
import { useNavigate } from 'react-router';
import { SEARCH_ROUTES } from 'router';
import { NoDataMessage } from './Components/NoDataMessage';
import { useListSearchInput } from 'Components/ListSearchInput/ListSearchInput';
import { useDebounce } from 'use-debounce';
import { SavedSearchType } from 'api/savedSearches/types';

const ROW_COUNT = 12;
const DEFAULT_ROW_HEIGHT = 48;
const TABLE_HEIGHT = DEFAULT_ROW_HEIGHT * (ROW_COUNT + 1);

export const SavedSearchesV2 = () => {
	const [savedSearchType, setSavedSearchType] = useState<SavedSearchType>(
		SavedSearchType.LEASES
	);

	const { data: savedSearches, isFetching } = useSavedSearchesQuery();

	const savedSearchesByCompType = useMemo(() => {
		if (!savedSearches) {
			return {
				leases: [],
				sales: [],
				properties: [],
			};
		}

		return savedSearches.reduce<Record<SavedSearchType, SavedSearch[]>>(
			(acc, savedSearch) => {
				acc[savedSearch.searchType].push(savedSearch);
				return acc;
			},
			{
				leases: [],
				sales: [],
				properties: [],
			}
		);
	}, [savedSearches]);

	const { element: savedSearchInput, searchTerm } = useListSearchInput({
		inputDataQaId: 'savedSearchesSearchInputTestId',
		inputPlaceholder: 'Find a saved search',
		inputHeight: '2.5rem',
	});

	const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

	const filteredSavedSearches = useMemo(() => {
		if (!debouncedSearchTerm) {
			return savedSearchesByCompType[savedSearchType];
		}

		return savedSearchesByCompType[savedSearchType].filter((savedSearch) => {
			return savedSearch.name.toLowerCase().includes(debouncedSearchTerm);
		});
	}, [debouncedSearchTerm, savedSearchesByCompType, savedSearchType]);

	const { data: hasSalesAccess } = useMarketsHaveFeature({
		feature: 'salesAccess',
		method: 'some',
	});

	const savedSearchTypes = useMemo(() => {
		return hasSalesAccess
			? Object.values(SavedSearchType)
			: [SavedSearchType.LEASES, SavedSearchType.PROPERTIES];
	}, [hasSalesAccess]);

	const hasLeasesSearches = useMemo(
		() => savedSearchesByCompType.leases.length > 0,
		[savedSearchesByCompType.leases.length]
	);
	const hasSalesSearches = useMemo(
		() => savedSearchesByCompType.sales.length > 0,
		[savedSearchesByCompType.sales.length]
	);
	const hasPropertiesSearches = useMemo(
		() => savedSearchesByCompType.properties.length > 0,
		[savedSearchesByCompType.properties.length]
	);

	useEffect(() => {
		const searchType =
			[
				{ hasData: hasLeasesSearches, type: SavedSearchType.LEASES },
				{ hasData: hasSalesSearches, type: SavedSearchType.SALES },
				{ hasData: hasPropertiesSearches, type: SavedSearchType.PROPERTIES },
			].find((search) => search.hasData)?.type || SavedSearchType.LEASES;

		setSavedSearchType(searchType);
	}, [hasLeasesSearches, hasSalesSearches, hasPropertiesSearches]);

	const columns = useSavedSearchesColumns();

	const navigate = useNavigate();

	return (
		<div>
			<SavedSearchesHeader>
				<SavedSearchIcon />
				Saved Searches
			</SavedSearchesHeader>
			<Subtext>
				Get alerted when lease, sale, and property comps are added to CompStak.
			</Subtext>

			<ControlsContainer>
				{!!savedSearches?.length && (
					<SearchContainer>{savedSearchInput}</SearchContainer>
				)}
				<div style={{ marginLeft: 'auto' }}>
					{savedSearchTypes.map((type) => {
						return (
							<SavedSearchTypeButton
								key={type}
								onClick={() => setSavedSearchType(type)}
								variant={type === savedSearchType ? 'primary' : 'secondary2'}
							>
								{type}
							</SavedSearchTypeButton>
						);
					})}
				</div>
			</ControlsContainer>
			{isFetching ? (
				<div style={{ height: `${TABLE_HEIGHT}px` }}>
					<Pending times={1} />
				</div>
			) : (
				<TableWrapper>
					<SimpleTable
						id="saved-searches-table"
						columns={columns}
						rows={filteredSavedSearches}
						scrollContainerProps={{
							style: {
								minHeight: TABLE_HEIGHT,
								maxHeight: TABLE_HEIGHT,
								border: `1px solid ${defaultTheme.colors.gray.gray300}`,
								borderRadius: 4,
							},
						}}
						getBodyCellProps={({ row }) => ({
							onClick: () => {
								navigate(
									SEARCH_ROUTES[row.searchType].toHref(undefined, {
										savedSearchId: row.id,
									})
								);
							},
							style: {
								border: 'none',
							},
						})}
						headerHeight={DEFAULT_ROW_HEIGHT}
						bodyRowHeight={DEFAULT_ROW_HEIGHT}
						noDataMessage={<NoDataMessage />}
						isBodyHoverable
					/>
				</TableWrapper>
			)}
		</div>
	);
};

const SavedSearchesHeader = styled.div`
	align-items: center;
	display: flex;
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 2rem;
	margin-bottom: 0.5rem;
	text-align: left;

	svg {
		margin-right: 1rem;
	}
`;

const Subtext = styled.p`
	font-size: 1rem;
`;

const ControlsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	margin-bottom: 1rem;
	flex-wrap: wrap;
`;

const SavedSearchTypeButton = styled(Button)`
	width: 140px;
	height: 2.5rem;
	border-radius: 0;
	outline: 1px solid ${({ theme }) => theme.colors.gray.gray300};
`;

const TableWrapper = styled.div`
	${SimpleTableBodyRow}
	> div:last-child {
		&,
		div {
			padding: 0;
			width: 100%;
			height: 100%;
		}
	}
	& ${SimpleTableHeader} {
		cursor: default;
		& span {
			cursor: pointer;
		}
	}
`;

const SearchContainer = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.gray.gray300};
	border-radius: 4px;
	overflow: hidden;
	flex-grow: 1;
	height: fit-content;
	max-width: 400px;
	& svg {
		fill: ${({ theme }) => theme.colors.gray.gray700};
	}

	& div {
		background-color: ${({ theme }) => theme.colors.white.white};
	}

	& input,
	input:focus {
		background-color: ${({ theme }) => theme.colors.white.white};
		color: ${({ theme }) => theme.colors.gray.gray700};
	}
`;
