import React from 'react';

import { DropTarget } from 'react-dnd';
import { UNPLACED, PLACED } from './DragTypes';
// @ts-expect-error TS7016: Could not find a declaration f...
import { Watch } from 'scrollmonitor-react';

import FloorMenu from './Menus/Floor';

import ThreeDot from '../../ui/svg_icons/three-dot.svg';

import styles from './stacking.less';
import StackingPlanTenant from 'ui/Components/Export/StackingPlanTenant';
import { getActualLabel } from './util';

const spec = {
	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	hover: function (props, monitor) {
		if (!monitor.canDrop()) {
			return;
		}
		const originalSpace = monitor.getItem().space;
		const space = props.stackingPlan.spaces.find(
			// @ts-expect-error TS7006: Parameter 's' implicitly has a...
			(s) => s.id === originalSpace.id
		);

		if (originalSpace.placed && props.floor.label !== space.floor) {
			props.changeDraggedSpaceFloor(space, props.floor.label);
		}
	},
	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	canDrop: function (props, monitor) {
		const space = monitor.getItem().space;

		return space.placed || !space.floor;
	},
};

// @ts-expect-error TS7006: Parameter 'connect' implicitly...
function spaceCollect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	};
}

const VacantSpace = DropTarget(
	[PLACED, UNPLACED],
	spec,
	spaceCollect
)(
	class VacantSpace extends React.Component {
		openMenu = () => {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
			this.props.stackingPlanActions.openSpaceEditor({
				id: null,
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
				floor: this.props.floor.label,
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'vacantSpace' does not exist on type 'Rea... Remove this comment to see the full error message
				size: this.props.vacantSpace,
			});
		};
		render() {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'connectDropTarget' does not exist on typ... Remove this comment to see the full error message
			return this.props.connectDropTarget(
				<div
					className={styles.vacantSpace}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type 'Readonly<... Remove this comment to see the full error message
					style={this.props.style}
					onClick={this.openMenu}
				>
					{/*<Add width={30} height={30}data-tooltip="Add space to this floor" /> */}
				</div>
			);
		}
	}
);

export default Watch(
	class Floor extends React.PureComponent {
		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		showMenu = (event) => {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			const floor = this.props.stackingPlan.floors.find(
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
				(f) => f.label === this.props.floor.label
			);
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
			let vacantSpace = this.props.stackingPlan.spaces.reduce(
				// @ts-expect-error TS7006: Parameter 'sfLeft' implicitly ...
				(sfLeft, space) => {
					if (space.floor === floor.label && space.placed) {
						return sfLeft - space.size;
					} else {
						return sfLeft;
					}
				},
				floor.size
			);
			vacantSpace = Math.max(100, vacantSpace);
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
			this.props.menuActions.showMenu(
				FloorMenu,
				event.currentTarget,
				'onleft',
				{ ...this.props, floor, vacantSpace }
			);
		};

		render() {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
			const { floor, buildingWidth, spaceOccupied } = this.props;

			const widthStyle = {
				width: (floor.size / buildingWidth) * 100 + '%',
			};

			const vacantSpace = floor.size - spaceOccupied;

			const floorCellClassName = styles.floorCell;

			let vacantSpaceWarning;
			let overFlowStyle;
			let alert;
			let emptySpaceComponent = null;
			let vacantStyle = {
				width: '0%',
			};

			const jitter = floor.size * 0.15;
			if (spaceOccupied === 0) {
				emptySpaceComponent = (
					<div className={styles.floorDescription + ' ' + overFlowStyle}>
						{vacantSpace} SqFt <br />
						UNKNOWN
					</div>
				);
				vacantStyle = {
					width: '100%',
				};
			} else if (vacantSpace < -jitter) {
				vacantSpaceWarning =
					'Leases on this floor extend beyond the specified floor plate.';
				overFlowStyle = styles.floorDescriptionWarning;
				alert = <div className={styles.alert}>!</div>;

				emptySpaceComponent = (
					<div className={styles.floorDescription + ' ' + overFlowStyle}>
						{-vacantSpace} SqFt <br />
						OVER
					</div>
				);
				vacantStyle = {
					width: '0%',
				};
			} else if (vacantSpace > jitter) {
				emptySpaceComponent = (
					<div className={styles.floorDescription + ' ' + overFlowStyle}>
						{vacantSpace} SqFt <br />
						UNKNOWN
					</div>
				);
				vacantStyle = {
					width: (vacantSpace / floor.size) * 100 + '%',
				};
			} else {
				emptySpaceComponent = (
					<div className={styles.floorDescription + ' ' + overFlowStyle}>
						{spaceOccupied} SqFt <br />
					</div>
				);
			}

			const className = styles.floorRow;
			const stackingPlanID =
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
				'sp' + (this.props.stackingPlan.floors.length - 1 - this.props.index);

			let tenantsOrVacantSpace;

			// @ts-expect-error ts-migrate(2339) FIXME: Property 'printFormat' does not exist on type 'Rea... Remove this comment to see the full error message
			if (typeof this.props.printFormat === 'undefined') {
				tenantsOrVacantSpace = (
					<VacantSpace
						// @ts-expect-error ts-migrate(2322) FIXME: Property 'style' does not exist on type 'Intrinsic... Remove this comment to see the full error message
						style={vacantStyle}
						vacantSpace={vacantSpace}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
						stackingPlan={this.props.stackingPlan}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'originalStackingPlan' does not exist on ... Remove this comment to see the full error message
						originalStackingPlan={this.props.originalStackingPlan}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'floor' does not exist on type 'Readonly<... Remove this comment to see the full error message
						floor={this.props.floor}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
						stackingPlanActions={this.props.stackingPlanActions}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'changeDraggedSpaceFloor' does not exist ... Remove this comment to see the full error message
						changeDraggedSpaceFloor={this.props.changeDraggedSpaceFloor}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'spaceBeingDragged' does not exist on typ... Remove this comment to see the full error message
						spaceBeingDragged={this.props.spaceBeingDragged}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'endDraggingSpace' does not exist on type... Remove this comment to see the full error message
						endDraggingSpace={this.props.endDraggingSpace}
					/>
				);
			} else {
				let singleFloorTenants;
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'singleFloorTenants' does not exist on ty... Remove this comment to see the full error message
				if (typeof this.props.singleFloorTenants !== 'undefined') {
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'singleFloorTenants' does not exist on ty... Remove this comment to see the full error message
					singleFloorTenants = this.props.singleFloorTenants;

					if (Object.keys(singleFloorTenants).length > 0) {
						tenantsOrVacantSpace = [];
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
						const floors = this.props.stackingPlan.floors;

						for (let i = floors.length - 1; i >= 0; i--) {
							const floorLabel = getActualLabel(floors[i].label);

							if (typeof singleFloorTenants[floorLabel] !== 'undefined') {
								for (
									let j = 0;
									j < singleFloorTenants[floorLabel].length;
									j++
								) {
									const space = singleFloorTenants[floorLabel][j];

									tenantsOrVacantSpace.push(
										<StackingPlanTenant
											{...this.props}
											// @ts-expect-error ts-migrate(2769) FIXME: Property 'space' does not exist on type 'Intrinsic... Remove this comment to see the full error message
											space={space}
											key={space.id}
											positionLeft={space.meta.positionLeft}
											width={space.meta.width}
										/>
									);
								}
							}
						}
					}
				}
			}

			return (
				<div
					key={floor.label}
					id={stackingPlanID}
					className={className}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'setWatcher' does not exist on type 'Read... Remove this comment to see the full error message
					ref={this.props.setWatcher}
				>
					<div
						className={styles.yAxis}
						data-tooltip={vacantSpaceWarning}
						data-tooltipdelay="250"
					>
						<div className={styles.floorNumber + ' ' + overFlowStyle}>
							{floor.label} {alert}
						</div>
						{emptySpaceComponent}
					</div>
					<div className={floorCellClassName}>
						<div
							style={widthStyle}
							// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlan' does not exist on type 'Re... Remove this comment to see the full error message
							className={styles[this.props.stackingPlan.position + 'Align']}
						>
							{tenantsOrVacantSpace}
						</div>
					</div>
					<div className={styles.menuContainer}>
						<div
							className={styles.threeDotMenu}
							data-tooltip="Floor Menu"
							data-tooltipdelay="250"
							onClick={this.showMenu}
						>
							<ThreeDot />
						</div>
					</div>
				</div>
			);
		}
	}
);
