import React from 'react';

import styles from 'Pages/StackingPlans/stacking.less';
import {
	getSpaceClassName,
	getTenant,
	getSize,
	getExpirationQuarter,
} from 'Pages/StackingPlans/util';

export default class StackingPlanTenant extends React.PureComponent {
	subleaseOrNothing() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
		if (this.props.space.sublessor) {
			return (
				<span
					className={styles.subleaseDetails}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
					data-tooltip={`Subleased from ${this.props.space.sublessor}`}
				>
					S
				</span>
			);
		}
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
		if (this.props.space.sublease) {
			return (
				<span className={styles.subleaseDetails} data-tooltip={`Sublease`}>
					S
				</span>
			);
		}
		return null;
	}

	render() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
		const space = this.props.space;

		const sizeStyle = {
			position: 'absolute',
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'positionLeft' does not exist on type 'Re... Remove this comment to see the full error message
			left: `${this.props.positionLeft.toFixed(4)}%`,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'positionLeft' does not exist on type 'Re... Remove this comment to see the full error message
			right: `${(100 - this.props.positionLeft - this.props.width).toFixed(
				4
			)}%`,
		};

		const expirationQuarter = getExpirationQuarter(space);

		let hideStyle;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
		if (this.props.space.occupancy.toLowerCase() === 'unrentable') {
			hideStyle = {
				display: 'none',
			};
		}

		let unrentableTextStyle;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'space' does not exist on type 'Readonly<... Remove this comment to see the full error message
		if (getTenant(this.props.space).toLowerCase() === 'unrentable') {
			unrentableTextStyle = {
				textTransform: 'uppercase',
				opacity: '0.5',
			};
		}

		return (
			<div
				// @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"absolute... Remove this comment to see the full error message
				style={sizeStyle}
				className={
					styles[getSpaceClassName(space)] + ' ' + styles.floorCellVarWidth
				}
			>
				{/* @ts-expect-error TS2322: Type '{ textTransform: string;... */}
				<div style={unrentableTextStyle} className={styles.floorCellTenantName}>
					{getTenant(space)} {this.subleaseOrNothing()}
				</div>
				<div className={styles.floorCellTenantProps}>
					<div className={styles.floorCellKey}>Size</div>
					<div className={styles.floorCellValue}>{getSize(space)} SqFt</div>
					<div style={hideStyle} className={styles.floorCellKey}>
						Expn
					</div>
					<div style={hideStyle} className={styles.floorCellValue}>
						{expirationQuarter}
					</div>
				</div>
			</div>
		);
	}
}
