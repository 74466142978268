import { ReactElement, useCallback, SVGProps } from 'react';
import { useAppConfig, useMarketByNameOrId } from 'Pages/Login/reducers';
import { useFilters } from 'reducers/filtersReducer';
import {
	getSuggestionItemTitle,
	isGlobalSuggestionItem,
	isTopResultSuggestion,
	SUGGESTIONS_WITHOUT_MARKET_CHIP,
} from './helpers';
import { SuggestionItemProps } from './SuggestionItem';
import { ChipStyled } from './SuggestionItem.styles';
import styles from './suggestions.less';
import { SuggestionItem } from 'api/suggestions/suggestions';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Link, routes } from 'router';
import { usePropertyByMarketAndAddressQuery } from 'api';

import { Flex, Pending } from '@compstak/ui-kit';
import { qcRetryOnlyAuth } from 'api/utils/queryRetries';
import styled from 'styled-components';

type SuggestionItemImplProps = {
	Icon: (props: SVGProps<SVGElement>) => ReactElement | null;
	CTAText?: string;
	isProperty?: boolean;
};

export const SuggestionItemImpl = (
	Icon: SuggestionItemImplProps['Icon'],
	CTAText: SuggestionItemImplProps['CTAText'] = 'View Results',
	isProperty: SuggestionItemImplProps['isProperty'] = false
) => {
	// eslint-disable-next-line react/display-name
	return (props: SuggestionItemProps) => (
		<SuggestionItemImplContent
			{...props}
			Icon={Icon}
			CTAText={CTAText}
			isProperty={isProperty}
		/>
	);
};

type SuggestionItemImplContentProps = SuggestionItemImplProps &
	SuggestionItemProps;

const SuggestionItemImplContent = ({
	Icon,
	CTAText,
	isProperty,
	suggestion,
	suggestionItem,
	search,
	index,
	isSelected,
	onConfirmSelection,
	onAddPortfolio,
}: SuggestionItemImplContentProps) => {
	const { 'portfolio-analytics': portfolioAnalytics } = useFeatureFlags();
	const [filters] = useFilters();
	const confirmSelection = useCallback(() => {
		onConfirmSelection(index);
	}, [index, onConfirmSelection]);
	const { isEnterprise } = useAppConfig();

	let match = '';
	let suggestionItemTitle = getSuggestionItemTitle(suggestionItem);

	if (search) {
		const firstPart = suggestionItemTitle.slice(0, search.length);
		if (firstPart.toLowerCase() === search) {
			match = firstPart;
			suggestionItemTitle = suggestionItemTitle.slice(search.length);
		}
	}

	const className = isSelected ? styles.currentSuggestion : styles.suggestion;

	const showMarketNameChip =
		(getFiltersMarkets(filters).length > 1 &&
			!SUGGESTIONS_WITHOUT_MARKET_CHIP.includes(suggestion.field)) ||
		isGlobalSuggestionItem(suggestion, suggestionItem);

	const { data, isFetching: isFetchingSuggestion } =
		usePropertyByMarketAndAddressQuery(
			{
				marketId: suggestionItem.marketId,
				address: encodeURIComponent(suggestionItem.title),
			},
			{
				retry: qcRetryOnlyAuth,
				enabled: isProperty,
			}
		);

	return (
		<div className={className} onClick={confirmSelection}>
			<Flex gap="1rem">
				<Icon />
				<p>
					{match ? <strong>{match}</strong> : null}
					{suggestionItemTitle}
				</p>
				{showMarketNameChip && (
					<SuggestionItemMarketNameChip suggestionItem={suggestionItem} />
				)}
			</Flex>
			<Flex gap="1rem">
				{portfolioAnalytics &&
					isTopResultSuggestion(suggestion) &&
					isProperty &&
					isEnterprise && (
						<div className={styles.portfolioType} onClick={onAddPortfolio}>
							<span>Add To Portfolio</span>
						</div>
					)}
				<div className={styles.resultType}>
					{isProperty ? (
						isFetchingSuggestion ? (
							<PendingContainer>
								<Pending margin="0" times={4} />
							</PendingContainer>
						) : data ? (
							<Link
								to={routes.propertyById.toHref({
									propertyId: data.subjectProperty.id,
								})}
								onClick={(ev) => ev.stopPropagation()}
								className={styles.badge}
							>
								{CTAText}
							</Link>
						) : (
							<span className={styles.badge}>{CTAText}</span>
						)
					) : (
						<span className={styles.badge}>{CTAText}</span>
					)}
				</div>
			</Flex>
		</div>
	);
};

type SuggestionItemMarketNameChipProps = {
	suggestionItem: SuggestionItem;
};

const SuggestionItemMarketNameChip = ({
	suggestionItem,
}: SuggestionItemMarketNameChipProps) => {
	const marketDisplayName =
		useMarketByNameOrId(suggestionItem.marketId)?.displayName ?? '';

	return <ChipStyled>{marketDisplayName}</ChipStyled>;
};

const PendingContainer = styled.div`
	min-width: 90px;
	height: 1.5rem;
`;
