import { API, User } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { FeatureFlags } from 'api/featureFlags/types';
import { useAppSelector } from 'util/useAppSelector';
import { pick } from 'utils/objectUtils';
import type { LDContext } from 'launchdarkly-react-client-sdk';

export const DEFAULT_FEATURE_FLAG_VALUES: FeatureFlags = {
	'exchange-dashboard': false,
	'inside-view': false,
	'portfolio-analytics': false,
	'property-page-v-2': true,
	salesCompsAnalytics2: false,
	expensesNOI: false,
	LeaseCompUX23Q2: false,
	leagueTables: false,
	propertyPageInfoWidget: false,
	SearchTableRefactor: false,
	MarketSummaryFF: false,
	ffGiftbitViaUserService: false,
	additionalRecommendedSearchesFF: false,
	DashboardSearchTableRefactor: false,
	PropertyPageExport: false,
	chartBuilderCanExcludeComps: false,
	salesCompsCreditImprovements24Q2: false,
	PropertyPageCompstakEstimates: false,
	ExchangeLoans: false,
	DashboardExportPdfRefactor: false,
	TargetedPromotionIterationsAppsPhase2: false,
	portfolioRevampFF: false,
	PropertyPagePhase3: false,
	portfolioRevampOverviewTabFF: false,
	portfolioRevampPropertiesTabFF: false,
	portfolioRevampAlertsAndNotificationsFF: false,
	SavedSearchImprovements: false,
	portfolioRevampBenchmarkingFF: false,
	AdditionOfOccupancy: false,
};

export const useFeatureFlagsQuery = (
	user: User,
	options?: UseFeatureFlagsQueryOptions
) => {
	const reduxFeatureFlags = useAppSelector((state) => state.featureFlags);

	const query = useQuery({
		queryKey: [QUERY_KEYS.FEATURE_FLAGS],
		queryFn: async () => fetchLDFlags(user),
		cacheTime: Infinity,
		...options,
	});

	const data = query.data ?? reduxFeatureFlags ?? DEFAULT_FEATURE_FLAG_VALUES;

	// Here you can hardcode feature flags for debugging purposes:
	// data.someFeatureFlagToDebug = true;

	return {
		...query,
		data,
	};
};

export type UseFeatureFlagsQueryOptions = UseQueryOptions<FeatureFlags>;

export const fetchLDFlags = async (user: User) => {
	try {
		const featureFlagsResponse = await API.get<FeatureFlags>(
			getLDUrl({
				key: `${user.id}`,
				name: user.username,
				firstName: user.firstName,
				lastName: user.lastName,
				kind: 'user',
				email: user.email,
				custom: getCustomDataForFeatureFlagTargeting({ user }),
			}),
			{
				includeAuthHeader: false,
				credentials: 'omit',
			}
		);
		return featureFlagsResponse.data;
	} catch (err) {
		console.error(err);
		return DEFAULT_FEATURE_FLAG_VALUES;
	}
};

function getCustomDataForFeatureFlagTargeting({
	user,
}: {
	user: User;
}): LDContext['custom'] {
	const { appConfig } = window;

	const hostname = (() => {
		// Hostname can later be used for targeting rules.
		try {
			return window.location?.hostname;
		} catch (err) {
			return 'hostname-missing';
		}
	})();

	const userFieldsToPassToCustom = pick(
		user,
		'accountActiveAgeDays',
		'company',
		'companyName',
		'enterpriseId',
		'enterpriseName',
		'id',
		'isEnterpriseClient',
		'pointsAvailable',
		'primaryMarketId',
		'tags',
		'userType'
	);

	// https://docs.launchdarkly.com/home/users/custom-attributes
	const custom = {
		...userFieldsToPassToCustom,
		hostname,
		isExchangePlusClient: user.isExchangePlusClient ?? false,
		appClient: appConfig.isEnterprise
			? 'enterprise'
			: appConfig.isExchange
				? 'exchange'
				: 'unknown',
		dateCreated: maybeDateToMsSinceEpoch(user.dateCreated),
		dateFirstSession: maybeDateToMsSinceEpoch(user.dateFirstSession),
		dateLastSubmission: maybeDateToMsSinceEpoch(user.dateLastSubmission),
	};
	return custom;
}

function maybeDateToMsSinceEpoch(date?: string) {
	// https://docs.launchdarkly.com/sdk/concepts/flag-types/?q=date#using-datetime-and-semantic-version-operators
	if (!date) return null;
	try {
		return new Date(date).getTime();
	} catch {
		return null;
	}
}

function getLDUrl(context: LDContext) {
	const stringifiedData = JSON.stringify(context);
	const userHashPart = b64EncodeUnicode(stringifiedData);

	let ldRelayUrl = window._env_.REACT_APP_LAUNCH_DARKLY_RELAY_URL;
	ldRelayUrl = ldRelayUrl.endsWith('/') ? ldRelayUrl.slice(0, -1) : ldRelayUrl;
	const clientSideId = window._env_.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID;

	return `${ldRelayUrl}/sdk/eval/${clientSideId}/users/${userHashPart}`;
}

function b64EncodeUnicode(str: string) {
	// Helper function to encode Unicode strings to base64

	// Only btoa used to be used instead of b64EncodeUnicode, but it doesn't support non-Latin-1 characters:
	// https://compstak.atlassian.net/browse/AP-16168

	// Convert the string to UTF-8 bytes using TextEncoder
	const utf8Bytes = new TextEncoder().encode(str);
	// Convert the byte array to a binary string
	let binary = '';
	for (let i = 0; i < utf8Bytes.byteLength; i++) {
		binary += String.fromCharCode(utf8Bytes[i]);
	}

	// Encode the binary string using btoa
	return window.btoa(binary);
}
