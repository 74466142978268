import { BenchmarkingView } from './BenchmarkingView';
import styled from 'styled-components';
import { APP_HEADER_HEIGHT } from 'constants/sizes';
import { MQB } from '@compstak/ui-kit';
import { BenchmarkingSidebar } from './BenchmarkingSidebar/BenchmarkingSidebar';

export const BenchmarkingLayout = () => {
	return (
		<Root>
			<BenchmarkingSidebar />
			<BenchmarkingView />
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	height: calc(100vh - ${APP_HEADER_HEIGHT}px);
	line-height: 1.2;
	box-sizing: border-box;

	font-size: 1rem;

	@media (max-width: ${MQB.D_1280}) {
		font-size: 0.875rem;
	}
`;
