import {
	SAVE_SEARCH,
	DELETE_SAVED_SEARCH,
	LOAD_SAVED_SEARCHES,
	UPDATE_SAVED_SEARCH,
	DUPLICATE_SEARCH,
	SET_CURRENT_SAVED_SEARCH,
	CLEAR_CURRENT_SAVED_SEARCH,
	SavedSearchAction,
} from 'Pages/SavedSearches/actions';

import produce from 'immer';
import { useSelector } from 'react-redux';
import { AppState } from './root';
import { ServerFilterItem } from 'types/serverFilters';
import { getPromiseActionType } from 'types/redux-promise-middleware';
import { CompType, LeaseComp, PropertyComp, SalesComp } from 'types';

export enum SavedSearchType {
	LEASES = 'leases',
	SALES = 'sales',
	PROPERTIES = 'properties',
}

export const SAVED_SEARCH_TYPE_TO_COMP_TYPE: Record<SavedSearchType, CompType> =
	{
		[SavedSearchType.LEASES]: 'lease',
		[SavedSearchType.SALES]: 'sale',
		[SavedSearchType.PROPERTIES]: 'property',
	};

export type SavedSearch<
	T extends SavedSearchType =
		| SavedSearchType.LEASES
		| SavedSearchType.SALES
		| SavedSearchType.PROPERTIES,
> = {
	id: string;
	lastRan: Date;
	filters: ServerFilterItem[];
	name: string;
	notifyByEmail: boolean;
	searchType: T;
	sortAsc: boolean;
	sortProperty: T extends SavedSearchType.PROPERTIES
		? keyof PropertyComp
		: T extends SavedSearchType.SALES
			? keyof SalesComp
			: keyof LeaseComp;
	userId: number;
	// marketId field required by BE
	marketId: number;
};

export type SavedSearchesState = {
	savedSearchList: SavedSearch[] | null;
	currentSavedSearch: SavedSearch | null;
};

const initialState: SavedSearchesState = {
	savedSearchList: null,
	currentSavedSearch: null,
};

const savedSearchesReducer = (
	state: SavedSearchesState = initialState,
	action: SavedSearchAction
): SavedSearchesState => {
	return produce(state, (draftState) => {
		switch (action.type) {
			case getPromiseActionType(LOAD_SAVED_SEARCHES, 'REJECTED'):
				draftState.savedSearchList = [];
				return;
			case getPromiseActionType(LOAD_SAVED_SEARCHES, 'FULFILLED'):
			case getPromiseActionType(UPDATE_SAVED_SEARCH, 'FULFILLED'):
			case getPromiseActionType(DELETE_SAVED_SEARCH, 'FULFILLED'):
			case getPromiseActionType(DUPLICATE_SEARCH, 'FULFILLED'):
			case getPromiseActionType(SAVE_SEARCH, 'FULFILLED'): {
				draftState.savedSearchList = action.payload;
				return;
			}

			case SET_CURRENT_SAVED_SEARCH:
				draftState.currentSavedSearch = action.payload.savedSearch;
				return;

			case CLEAR_CURRENT_SAVED_SEARCH:
				draftState.currentSavedSearch = null;
				return;
		}
	});
};

export default savedSearchesReducer;

export const useSavedSearches = (): SavedSearchesState => {
	const savedSearches = useSelector<AppState, SavedSearchesState>(
		(state) => state.savedSearches
	);

	return savedSearches;
};
