import { formatInteger } from './formatInteger';

export const formatObfuscatedSizeRange = (value: number) => {
	let low: number;
	let high: number;

	let roundTo = 10_000;
	let divideBy = 1000;
	let suffix = 'K';

	if (value >= 1_000_000) {
		roundTo = 1_000_000;
		divideBy = 1_000_000;
		suffix = 'M';
	} else if (value >= 100_000) {
		roundTo = 50_000;
	} else if (value < 10_000) {
		low = 0;
		high = 10_000;
	}

	low = Math.floor(value / roundTo) * roundTo;
	high = low + roundTo;

	low = Math.floor(low / divideBy);
	high = Math.floor(high / divideBy);

	return `${formatInteger(low, { shorten: false })}-${formatInteger(high, { shorten: false })}${suffix} SqFt`;
};
