import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';

const deletePortfolio = async (params: DeletePortfolioParams) => {
	const res = await API.delete<DeletePortfolioResponse>(
		`/pa/v2/api/portfolios/${params.id}`
	);
	return res.data;
};

export const useDeletePortfolioMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (params: DeletePortfolioParams) => deletePortfolio(params),
		onSuccess: (_, { id }) => {
			queryClient.invalidateQueries([QUERY_KEYS.ALL_PORTFOLIOS]);
			queryClient.invalidateQueries({
				predicate(query) {
					return (
						(query.queryKey?.[0] === QUERY_KEYS.PORTFOLIO_BY_ID &&
							query.queryKey?.[1] === id) ||
						(query.queryKey?.[0] === QUERY_KEYS.PORTFOLIO_BY_ID_V2 &&
							query.queryKey?.[1] === id)
					);
				},
			});
			queryClient.invalidateQueries([QUERY_KEYS.PORTFOLIO_PREFERENCES]);
		},
	});
};

export type DeletePortfolioParams = {
	id: number;
};

type DeletePortfolioResponse = void;
