import React, { memo } from 'react';
import {
	VictoryAxis,
	VictoryChart,
	VictoryVoronoiContainer,
	VictoryLabel,
	VictoryLine,
	VictoryLabelProps,
	AnimatePropTypeInterface,
} from 'victory';
import dayjs, { Dayjs } from 'dayjs';
import { StartingRentData } from './hooks/startingRent/useExchangeDashboardStartingRent';

interface Props {
	startingRentData: StartingRentData;
	monthly: boolean;
	isHoverDisabled?: boolean;
	animate?: AnimatePropTypeInterface;
}

export const StartingRentChart = memo<Props>(
	({ startingRentData, monthly, isHoverDisabled, animate }) => {
		const currentYear = new Date().getFullYear();

		const data = startingRentData.map((d) => ({ x: d.date, y: d.value }));
		const yAxis = data.map((d) => d.y);
		const max = Math.ceil(Math.max(...yAxis));
		const min = Math.floor(Math.min(...yAxis));
		const highestDomainValue = monthly ? max : max + 10;
		const lowestDomainValue = monthly ? min : min - 10 < 0 ? 0 : min - 10;

		return (
			<VictoryChart
				width={528}
				height={420}
				domain={{ y: [lowestDomainValue, highestDomainValue] }}
				domainPadding={{ y: [50, 50] }}
				padding={{ left: 75, top: 50, bottom: 100, right: 50 }}
				style={{ parent: { maxWidth: '100%' } }}
				containerComponent={
					<VictoryVoronoiContainer
						labels={
							isHoverDisabled
								? null
								: ({ datum }: VictoryLabelProps) =>
										!!datum && `$${datum.y.toFixed(2)}`
						}
						style={{
							margin: '0 auto',
						}}
					/>
				}
			>
				<VictoryAxis
					axisLabelComponent={
						<VictoryLabel dy={-30} style={{ fontFamily: 'Gotham' }} />
					}
					dependentAxis
					label={`Starting Rent (Adjusted), ${monthly ? 'Monthly' : 'Annual'} ($/SqFt)`}
					tickLabelComponent={
						<VictoryLabel
							renderInPortal
							style={{ fontSize: 12, fontFamily: 'Gotham' }}
						/>
					}
					tickFormat={(v: string) => `$${v}`}
					style={yAxisStyles}
				/>
				<VictoryAxis
					axisLabelComponent={
						<VictoryLabel dy={16} style={{ fontFamily: 'Gotham' }} />
					}
					label="Years"
					scale={{ x: 'time' }}
					tickLabelComponent={
						<VictoryLabel
							renderInPortal
							style={{ fontSize: 12, fontFamily: 'Gotham' }}
						/>
					}
					tickValues={Array.from({ length: 10 }, (_, i) =>
						dayjs(`${currentYear - 9 + i}-01-01`)
					)}
					tickFormat={(t: Dayjs) => t.year()}
					tickCount={10}
					style={xAxisStyles}
				/>

				<VictoryLine
					y0={() => lowestDomainValue}
					data={data}
					style={{ data: { stroke: '#228fff' } }}
					animate={animate}
				/>
			</VictoryChart>
		);
	}
);

StartingRentChart.displayName = 'StartingRentChart';

const axisCommonStyles = {
	axis: { stroke: 'none' },
	axisLabel: {
		fontSize: 14,
		color: '#000',
		textTransform: 'capitalize',
		fontFamily: 'Gotham',
	},
	ticks: { stroke: '#F0F0F0' },
	tickLabels: {
		fontSize: 12,
		fontFamily: 'Gotham',
		color: '#000',
	},
};

const xAxisStyles = {
	...axisCommonStyles,
	grid: { stroke: 'none' },
};

const yAxisStyles = {
	...axisCommonStyles,
	grid: { stroke: 'rgba(102, 102, 102, 0.32)' },
};
