import constate from 'constate';
import { useCallback, useState } from 'react';
import { SavedSearch } from 'api/savedSearches/useSavedSearchesQuery';

export const [SavedSearchProvider, useSavedSearchContext] = constate(() => {
	const [savedSearch, setSavedSearch] = useState<SavedSearch | null>(null);

	const resetSavedSearch = useCallback(() => {
		setSavedSearch(null);
	}, []);

	return { savedSearch, setSavedSearch, resetSavedSearch };
});
