import { SPACE_TYPE_NAME_TO_ID, SpaceTypeId, usePropertyMarket } from 'api';
import { useMemo } from 'react';
import { createSearchLeasesUrl } from 'utils';

type Props = {
	propertyId: number;
	spaceTypeId?: SpaceTypeId;
	isActiveLeasesOnly?: boolean;
	addresses: string[];
};
export const usePropertyLeasesUrl = ({
	propertyId,
	spaceTypeId,
	isActiveLeasesOnly,
	addresses,
}: Props) => {
	const { data: market } = usePropertyMarket({ propertyId });

	const leasesUrl = useMemo(
		() =>
			createSearchLeasesUrl({
				market,
				address: addresses,
				spaceTypeId:
					spaceTypeId === SPACE_TYPE_NAME_TO_ID['Other']
						? [SPACE_TYPE_NAME_TO_ID['Unknown'], SPACE_TYPE_NAME_TO_ID['Other']]
						: spaceTypeId
							? [spaceTypeId]
							: undefined,
				expirationDate: isActiveLeasesOnly
					? {
							min: new Date(),
							max: null,
						}
					: null,
			}),
		[addresses, spaceTypeId, isActiveLeasesOnly, market]
	);

	return leasesUrl;
};
