import {
	PortfolioV2,
	UpdatePortfolioParams,
	useUpdatePortfolioMutation,
} from 'api';
import { PortfolioDialog } from '../Components';
import { PortfolioNotificationFrequency } from 'api/portfolio/preferences/types';

type Props = {
	portfolioV2: PortfolioV2;
};

export const UpdatePortfolioDialog = ({ portfolioV2 }: Props) => {
	const { mutateAsync: updatePortfolio, isLoading: isLoadingUpdatePortfolio } =
		useUpdatePortfolioMutation();
	const {
		id: portfolioId,
		description: portfolioDescription,
		name: portfolioName,
	} = portfolioV2.portfolio;

	return (
		<PortfolioDialog
			title="Name & Description"
			isLoadingMutation={isLoadingUpdatePortfolio}
			onSubmit={async (values) => {
				const body: UpdatePortfolioParams['body'] = {
					id: portfolioId,
					name: values.name,
					description: undefined,
				};
				if (values.description.length > 0) {
					body.description = values.description;
				}
				await updatePortfolio({ id: portfolioId, body });
			}}
			initialFormState={{
				name: portfolioName,
				description: portfolioDescription ?? '',
				notificationFrequency: PortfolioNotificationFrequency.Never,
			}}
			editedPortfolio={portfolioV2}
		/>
	);
};
