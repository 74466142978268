import { nullValueText } from '@compstak/common';
import { useMemo } from 'react';
import { MufaPropertyComp } from 'types';

export const usePropertyWithYearInfo = (property: MufaPropertyComp) => {
	return useMemo(() => {
		const { buildingYear, buildingYearRenovated } = property;
		const yearInfo = `${buildingYear ?? nullValueText} / ${buildingYearRenovated ?? nullValueText}`;

		return {
			...property,
			yearInfo,
		};
	}, [property]);
};
