import { PortfolioSidebarItem } from 'PortfolioAnalytics/PortfolioSidebar/PortfolioSidebarItem';
import { PortfolioShortWithAggregations } from 'PortfolioAnalytics/PortfolioSidebar/usePortfoliosWithAggregations';
import styled from 'styled-components';
import { BENCHMARKING_PORTFOLIOS_LIMIT } from '../constants';
import { Checkbox } from 'Components';
import { Button } from '@compstak/ui-kit';

type Props = {
	portfolios: PortfolioShortWithAggregations[];
};

export const BenchmarkingPortfoliosList = ({ portfolios }: Props) => {
	const selectedPortfolios: PortfolioShortWithAggregations[] = [];

	return (
		<List>
			<Category>
				Selected Portfolios ({selectedPortfolios.length}/
				{BENCHMARKING_PORTFOLIOS_LIMIT})
				<ResetButton variant="ghost" onClick={() => {}}>
					Reset
				</ResetButton>
			</Category>
			{selectedPortfolios.length === 0 && (
				<NoPortfoliosSelected>No portfolio selected.</NoPortfoliosSelected>
			)}
			{selectedPortfolios.map((p) => (
				<PortfolioSidebarItem
					key={p.id}
					portfolio={p}
					withAlerts={false}
					onClick={() => {}}
				>
					<Checkbox checked />
				</PortfolioSidebarItem>
			))}
			<Category>Other Portfolios</Category>
			{portfolios.map((p) => (
				<PortfolioSidebarItem
					key={p.id}
					portfolio={p}
					withAlerts={false}
					onClick={() => {}}
				>
					<Checkbox />
				</PortfolioSidebarItem>
			))}
		</List>
	);
};

const List = styled.ul`
	width: 100%;
	overflow-y: auto;
	height: 100%;
`;

const Category = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.875em;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.blue.blue500};
	padding: 0.5rem 0 0.5rem 1rem;
`;

const ResetButton = styled(Button)`
	color: ${({ theme }) => theme.colors.neutral.n50};
	font-size: 1em;
`;

const NoPortfoliosSelected = styled.div`
	display: flex;
	justify-content: center;
	font-size: 0.875em;
	padding: 0.5rem 1rem;
	color: ${({ theme }) => theme.colors.neutral.n50};
`;
