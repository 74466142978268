import {
	AngleArrow,
	Spinner,
	basicDropdownListItemStyles,
} from '@compstak/ui-kit';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { defaultDropdownContentCss } from 'Components';
import { useMarketsHaveFeature, usePropertyAverages } from 'api';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useFilters } from 'reducers/filtersReducer';
import { routes } from 'router';
import styled from 'styled-components';
import CompTypeIcon from 'ui/svg_icons/compTypeIcon.svg';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { useAppSelector } from 'util/useAppSelector';
import { filtersToQueryString } from '../../../models/filters/util';
import { CompType, PropertyComp } from '../../../types';
import buttonStyles from '../../../ui/styles/button.less';
import { EXPLICIT_SPACE } from '../../../utils';

const MAX_SELECTED_COMPS_LIMIT = 1000;

export const GoToCompTypeSection = () => {
	const selection = useAppSelector(
		(state) => state.selection.list
	) as PropertyComp[];

	const selectionIds = useMemo(() => {
		return selection.map((c) => c.id);
	}, [selection]);

	const [filters] = useFilters();

	const marketIds = useMemo(() => {
		return getFiltersMarkets(filters).map((m) => m.id);
	}, [filters]);

	const { data: averages, isFetching: isFetchingAverages } =
		usePropertyAverages({
			ids: selectionIds,
			marketIds,
		});
	const isMin1536Width = useMediaQuery({ minWidth: 1536 });

	const leaseCount = selection.length > 0 ? averages?.leases ?? 0 : 0;
	const saleCount = selection.length > 0 ? averages?.sales ?? 0 : 0;

	const addresses = selection.map(
		(property) => property.buildingAddressAndCity
	);

	const filterString = filtersToQueryString({
		address: addresses,
		sortField: 'address',
		sortDirection: 'asc',
	});

	const isZeroSelected = selection.length === 0;
	const leasesOverLimitSelected = leaseCount > MAX_SELECTED_COMPS_LIMIT;
	const salesOverLimitSelected = saleCount > MAX_SELECTED_COMPS_LIMIT;
	const leaseDisabled =
		isZeroSelected ||
		leasesOverLimitSelected ||
		isFetchingAverages ||
		leaseCount === 0;
	const saleDisabled =
		isZeroSelected ||
		salesOverLimitSelected ||
		isFetchingAverages ||
		saleCount === 0;

	const saleLabel = getLabel('SEE SALES', isFetchingAverages, saleCount);
	const leaseLabel = getLabel('SEE LEASES', isFetchingAverages, leaseCount);

	const leaseTooltip = getTooltip('lease', leaseDisabled);
	const saleTooltip = getTooltip('sale', saleDisabled);

	const { data: hasSalesAccess } = useMarketsHaveFeature({
		feature: 'salesAccess',
		method: 'some',
	});

	const { data: hasLeaseAccess } = useMarketsHaveFeature({
		feature: 'leaseAccess',
		method: 'some',
	});

	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return isMin1536Width ? (
		<>
			{hasLeaseAccess && (
				<div>
					<Link
						to={`${routes.searchLeasesByView.toHref({ view: 'list' })}?${filterString}`}
						className={getBtnClassName(leaseDisabled)}
						data-tooltip={leaseTooltip}
						data-tooltip-position={leasesOverLimitSelected ? 'below' : 'above'}
					>
						{leaseLabel}
					</Link>
				</div>
			)}
			{hasSalesAccess && (
				<div>
					<Link
						to={`${routes.searchSalesByView.toHref({ view: 'list' })}?${filterString}`}
						className={getBtnClassName(saleDisabled)}
						data-tooltip={saleTooltip}
						data-tooltip-position={salesOverLimitSelected ? 'below' : 'above'}
					>
						{saleLabel}
					</Link>
				</div>
			)}
		</>
	) : (
		<div>
			<DropdownMenu.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
				<DropdownMenu.Trigger asChild>
					<StyledDropdownButton className={getBtnClassName()}>
						<CompTypeIcon />
						<StyledAngleArrow rotate={isMenuOpen ? 180 : 0} />
					</StyledDropdownButton>
				</DropdownMenu.Trigger>
				<DropdownMenu.Portal>
					<DropdownContent>
						{hasSalesAccess && (
							<DropdownMenu.Item asChild>
								<StyledDropdownItemLink
									to={`${routes.searchLeasesByView.toHref({ view: 'list' })}?${filterString}`}
									disabled={leaseDisabled}
									data-tooltip={leaseTooltip}
									data-tooltip-position={
										leasesOverLimitSelected || salesOverLimitSelected
											? 'below'
											: 'above'
									}
									onClick={(e) => {
										if (leaseDisabled) {
											e.preventDefault();
										}
									}}
								>
									{leaseLabel}
								</StyledDropdownItemLink>
							</DropdownMenu.Item>
						)}
						{hasLeaseAccess && (
							<DropdownMenu.Item asChild>
								<StyledDropdownItemLink
									to={`${routes.searchSalesByView.toHref({ view: 'list' })}?${filterString}`}
									disabled={saleDisabled}
									data-tooltip={saleTooltip}
									data-tooltip-position={
										leasesOverLimitSelected || salesOverLimitSelected
											? 'below'
											: 'above'
									}
									onClick={(e) => {
										if (saleDisabled) {
											e.preventDefault();
										}
									}}
								>
									{saleLabel}
								</StyledDropdownItemLink>
							</DropdownMenu.Item>
						)}
					</DropdownContent>
				</DropdownMenu.Portal>
			</DropdownMenu.Root>
		</div>
	);

	function getTooltip(compType: CompType, disabled: boolean) {
		if (isFetchingAverages || !disabled) {
			return undefined;
		}

		if (
			(compType === 'lease' && leasesOverLimitSelected) ||
			(compType === 'sale' && salesOverLimitSelected)
		) {
			return `You can see a max of ${MAX_SELECTED_COMPS_LIMIT} comps from this set of properties. Please refine your filters or change your selected properties to view these comps`;
		}

		return `There are no ${compType} comps associated with the selected set of properties`;
	}
};

function getLabel(title: string, isAveragesLoading: boolean, count: number) {
	return (
		<>
			<span>
				{title}
				{EXPLICIT_SPACE}
			</span>
			{isAveragesLoading ? (
				<Spinner size="m" />
			) : (
				`(${abbreviateNumber(count)})`
			)}
		</>
	);
}

function getBtnClassName(goToCompTypeBtnsDisabled?: boolean) {
	return (
		`${buttonStyles.button} button_export` +
		(goToCompTypeBtnsDisabled ? ' button_disabled' : '')
	);
}

const StyledDropdownButton = styled.button`
	margin-right: 0;
`;

const StyledDropdownItemLink = styled(Link)<{ disabled: boolean }>`
	${basicDropdownListItemStyles};
	padding: 0 8px;
	height: 40px;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	color: ${({ theme }) => theme.colors.gray.gray0};
	display: flex;
	align-items: center;

	${({ disabled }) => (disabled ? 'opacity: 0.5;' : '')};

	&:hover {
		color: ${({ theme }) => theme.colors.gray.gray0};
	}
`;

const DropdownContent = styled(DropdownMenu.Content)`
	${defaultDropdownContentCss}
	border: 1px solid ${({ theme }) => theme.colors.neutral.n100};
`;

const StyledAngleArrow = styled(AngleArrow)`
	path {
		stroke: white;
	}
`;
