import { useSavedSearchContext } from 'Components/SavedSearches/SavedSearchProvider';
import { filtersToServerJSON } from 'models/filters/util';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';

export const useFindSavedSearchToEdit = () => {
	const { savedSearch } = useSavedSearchContext();

	const [filters] = useFilters();

	const editedSavedSearch = useMemo(() => {
		if (!savedSearch) return undefined;

		return savedSearch
			? {
					...savedSearch,
					filters: filtersToServerJSON(filters),
					sortAsc: filters.sortDirection === 'asc',
					sortProperty: filters.sortField,
					marketId: getFiltersMarkets(filters)?.[0]?.id,
				}
			: undefined;
	}, [savedSearch, filters]);

	return editedSavedSearch;
};
