import { useMarkets } from 'hooks/useMarkets';
import { Link } from 'react-router-dom';
import { PropertyComp } from 'types';
import { createSearchLeasesUrl } from 'utils';

export const NumberOfActiveLeasesCell = ({
	property,
}: {
	property: PropertyComp;
}) => {
	const markets = useMarkets();

	const searchLeasesUrl = createSearchLeasesUrl({
		market: markets[property.marketDisplayName],
		address: [`${property.buildingAddress}, ${property.city}`],
		expirationDate: {
			min: new Date(),
			max: null,
			allowFallback: false,
		},
	});
	return (
		<Link to={searchLeasesUrl} target="blank">
			{property.notExpiredLeaseCount}
		</Link>
	);
};
