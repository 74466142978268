import {
	CopyPortfolioParams,
	PortfolioV2,
	useCopyPortfolioMutation,
} from 'api';
import { routes, useNavigate } from 'router';
import { PortfolioDialog } from '../Components';
import { PortfolioNotificationFrequency } from 'api/portfolio/preferences/types';

type Props = {
	portfolioV2: PortfolioV2;
};

export const CopyPortfolioDialog = ({ portfolioV2 }: Props) => {
	const { mutateAsync: copyPortfolio, isLoading } = useCopyPortfolioMutation();
	const navigate = useNavigate();
	const {
		id: portfolioId,
		description: portfolioDescription,
		name: portfolioName,
	} = portfolioV2.portfolio;
	return (
		<PortfolioDialog
			title="Duplicate Portfolio"
			isLoadingMutation={isLoading}
			onSubmit={async (values) => {
				const copyPortfolioParams: CopyPortfolioParams = {
					portfolioId: portfolioId,
					name: values.name,
					description:
						values.description.length > 0 ? values.description : undefined,
				};
				await copyPortfolio(copyPortfolioParams);

				navigate(routes.portfolios.path);
			}}
			initialFormState={{
				name: `${portfolioName} copy`,
				description: portfolioDescription ?? '',
				notificationFrequency: PortfolioNotificationFrequency.Never,
			}}
		/>
	);
};
