import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { trackSegmentCreatePortfolio } from 'middleware/tracking/segment';
import {
	getPortfolioWithPreferencesPayload,
	getPortfolioWithPreferencesUrl,
} from 'api/portfolio/preferences/utils';
import { AllPortfoliosResponse } from '../allPortfolios';
import { WithPreferencesParams } from '../preferences/types';

export const useCopyPortfolioMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (_params: CopyPortfolioParams) => {
			const { description, ...otherParams } = _params;
			const params = {
				...otherParams,
				...(!!description && { description }),
			};

			const res = await API.post<CopyPorfolioResponse>(
				getPortfolioWithPreferencesUrl(`/pa/v2/api/portfolios/copy`, params),
				getPortfolioWithPreferencesPayload(params)
			);
			return res.data;
		},
		onSuccess: (newPortfolio) => {
			const portfolios = queryClient.getQueryData<AllPortfoliosResponse>([
				QUERY_KEYS.ALL_PORTFOLIOS,
			]);
			trackSegmentCreatePortfolio({
				existingPortfolios: portfolios || [],
				newPortfolio,
				duplicatePortfolio: true,
			});
			queryClient.invalidateQueries([QUERY_KEYS.ALL_PORTFOLIOS]);
		},
	});
};

export type CopyPortfolioParams = WithPreferencesParams<{
	portfolioId: number;
	name: string;
	description: string | undefined;
}>;

type CopyPorfolioResponse = {
	id: number;
};
