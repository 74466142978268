import { AddToPortfolioDialogSelector } from 'Components/AddToPortfolioDialog/AddToPortfolioDialogSelector';
import { setFilters } from 'models/filters/util/setFilters';
import { filtersToQueryString } from 'models/filters/util/urls';
import { createSendToAnalyticsHref } from 'Pages/Analytics/Builder/chartBuilderHelpers';
import { useAppConfig } from 'Pages/Login/reducers';
import { useMultiSelect } from 'Pages/Search/MultiSelectProvider';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideMenu } from 'Singletons/Menu/actions';
import { useNavigate } from 'router';
import { menuActions } from 'Singletons/Menu/actions';
import styled from 'styled-components';
import propertyService from '../../services/property';
import { CompType } from '../../types/comp';
import pluralizeCompType from '../../util/pluralizeCompType';
import MultiSelectMenu from './MultiSelectMenu';
import { useFilters } from 'reducers/filtersReducer';

type Props = {
	compType: CompType;
};

const MultiSelectOptionsButton = (props: Props) => {
	const navigate = useNavigate();
	const [filters] = useFilters();
	const appConfig = useAppConfig();

	const dispatch = useDispatch();
	const { selectedPropertyIds } = useMultiSelect();

	const [refineUrl, setRefineUrl] = useState('');
	const [analyticsUrl, setAnalyticsUrl] = useState('');

	const [isAddToPortfolioDialogOpen, setIsAddToPortfolioDialogOpen] =
		useState(false);

	const getUrls = async () => {
		const properties = await propertyService.loadMany(selectedPropertyIds);
		const propertiesAddresses = properties.map(
			// @ts-expect-error TS2339: Property 'subjectProperty' doe...
			({ subjectProperty }) => subjectProperty.buildingAddressAndCity
		);
		const propertyFilters = setFilters(filters, 'address', propertiesAddresses);
		const searchUrl =
			`/search/${pluralizeCompType(props.compType, false)}/list?` +
			filtersToQueryString(propertyFilters);
		const sendToAnalyticsUrl = createSendToAnalyticsHref({
			filters: propertyFilters,
		});
		setRefineUrl(searchUrl);
		setAnalyticsUrl(sendToAnalyticsUrl);
	};

	useEffect(() => {
		getUrls();
	}, [selectedPropertyIds]);

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	const showMultiSelectMenu = (event) => {
		if (selectedPropertyIds.length) {
			appConfig.isEnterprise && !props.compType.startsWith('sale')
				? dispatch(
						menuActions.showMenu(
							MultiSelectMenu,
							event.currentTarget,
							'below',
							{
								...props,
								refineUrl,
								analyticsUrl,
								propertyIds: selectedPropertyIds,
								openAddToPortfolio: () => {
									dispatch(hideMenu());
									setIsAddToPortfolioDialogOpen(true);
								},
							}
						)
					)
				: navigate(refineUrl);
		}
	};

	return (
		<MultiSelectToolbar>
			<ButtonContainer
				onClick={showMultiSelectMenu}
				className="results-button-wrapper"
				disabled={!selectedPropertyIds.length}
			>
				{appConfig.isEnterprise && !props.compType.startsWith('sale')
					? 'Options '
					: 'Refine '}
				{selectedPropertyIds.length ? (
					<span>({selectedPropertyIds.length})</span>
				) : null}
				<Arrow />
			</ButtonContainer>
			{isAddToPortfolioDialogOpen && (
				<AddToPortfolioDialogSelector
					closeDialog={() => setIsAddToPortfolioDialogOpen(false)}
					propertyIds={selectedPropertyIds}
				/>
			)}
		</MultiSelectToolbar>
	);
};

export default MultiSelectOptionsButton;

const MultiSelectToolbar = styled.div`
	display: flex;
	justify-content: flex-end;
	background-color: transparent;
	width: 100%;
	height: 100%;
`;

const ButtonContainer = styled.div<{ disabled?: boolean }>`
	box-sizing: border-box;
	font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	font-size: 16px;
	letter-spacing: 0.03em;
	justify-content: space-between;
	line-height: 48px !important;
	box-shadow: 0 2px fade(#303441, 10%);
	max-width: 256px;
	min-width: 256px;
	text-transform: uppercase;
	background-color: ${(props) => (props.disabled ? '#616a7f' : '#228fff')};
	color: #ffffff;
	align-self: flex-end;
	width: 260px;
	border-radius: 2px;
	height: 48px;
	text-align: center;
	font-weight: 400;
	pointer-events: all;
	position: relative;
	cursor: pointer;
	white-space: nowrap;
	vertical-align: middle;
	flex: 0;
`;

const Arrow = styled.div`
	position: absolute;
	right: 0;
	height: 48px;
	top: 50%;
	margin-top: -24px;

	&:after {
		content: '';
		display: block;
		border-bottom: 1px solid #ffffff;
		border-right: 1px solid #ffffff;
		transform: rotate(-45deg);
		width: 10px;
		height: 10px;
		margin: 19px 17px 0 0;
	}
`;
