import {
	PageTitleWrap,
	PageTitle,
	PageTitleRow,
	PageTitleRowIconButton,
	PageTitleRowLeft,
	PageTitleRowRight,
	PageTitleRowRightButtons,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import IconExportBlue from 'ui/svg_icons/export_blue.svg';
import styled from 'styled-components';
import { exportPortfolio } from 'PortfolioAnalytics/Export/exportPortfolio';
import { PortfolioFiltersV2 } from 'PortfolioAnalytics/Dashboard/DashboardHeader/Filters/PortfolioFiltersV2';

type Props = {
	elementToExport: HTMLElement | null;
};

export const BenchmarkingViewHeader = ({ elementToExport }: Props) => {
	return (
		<Container>
			<PageTitleRow>
				<PageTitleRowLeft>
					<PageTitleWrap>
						<PageTitle>Portfolio Comparison</PageTitle>
					</PageTitleWrap>
					<PortfolioFiltersV2 disabled={true} />
				</PageTitleRowLeft>
				<PageTitleRowRight>
					<PageTitleRowRightButtons>
						<PageTitleRowIconButton
							variant="ghost"
							icon={<IconExportBlue />}
							onClick={() => {
								exportPortfolio({
									filename: 'Portfolio Comparison',
									element: elementToExport,
								});
							}}
						/>
					</PageTitleRowRightButtons>
				</PageTitleRowRight>
			</PageTitleRow>
		</Container>
	);
};

const Container = styled.div``;
