import { Button, Spinner } from '@compstak/ui-kit';
import { SortDirection, useAllPortfoliosQuery } from 'api';
import { useState } from 'react';
import styled from 'styled-components';
import { DarkSidebar } from 'ui/UI';
import { NewPortfolioDialog } from './NewPortfolioDialog';
import { SortField } from './types';
import { usePortfoliosWithAggregations } from './usePortfoliosWithAggregations';
import { useSortedPortfolios } from './useSortedPortfolios';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useModal } from 'providers/ModalProvider';
import { PortfolioModal } from '../modals/PortfolioModal';

import { PortfolioSidebarItem } from './PortfolioSidebarItem';
import {
	ListSortButton,
	ListSortButtonsContainer,
	ListSortRowInfoText,
} from 'PortfolioAnalytics/UI';
import { SortFieldButton } from './SortFieldButton';
import { Triangle } from 'Components';
import { routes } from 'router';
import { useNavigate } from 'react-router';

type Props = {
	portfolioId?: number;
};

export const PortfolioSidebar = ({ portfolioId }: Props) => {
	const [sortField, setSortField] = useState<SortField>('dateCreated');
	const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

	const { data: portfolios, isFetching: isFetchingPortfolios } =
		useAllPortfoliosQuery();

	const portfoliosWithAggregates = usePortfoliosWithAggregations(portfolios);

	const sortedPortfolios = useSortedPortfolios({
		portfoliosWithAggregates,
		sortField,
		sortDirection,
	});

	const { portfolioRevampOverviewTabFF } = useFeatureFlags();
	const { openModal } = useModal();
	const navigate = useNavigate();

	const onNewPortfolioClick = () => {
		openModal({
			modalContent: portfolioRevampOverviewTabFF ? (
				<PortfolioModal action="create" title="Create a New Portfolio" />
			) : (
				<NewPortfolioDialog />
			),
		});
	};

	return (
		<DarkSidebar sideBarState="open">
			<NewPortfolioButtonContainer>
				<NewPortfolioButton
					variant="primary"
					size="l"
					onClick={onNewPortfolioClick}
				>
					New portfolio
				</NewPortfolioButton>
			</NewPortfolioButtonContainer>
			{!isFetchingPortfolios && (
				<ListSortContainer>
					<StyledListSortRowInfoText>
						{sortedPortfolios.length} portfolios
					</StyledListSortRowInfoText>
					<ListSortButtonsContainer>
						<SortFieldButton
							sortField={sortField}
							setSortField={setSortField}
						/>
						<ListSortButton
							onClick={() => {
								setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
							}}
						>
							<Triangle
								size={14}
								color="#fff"
								style={{
									transform:
										sortDirection === 'desc' ? 'rotate(180deg)' : undefined,
								}}
							/>
						</ListSortButton>
					</ListSortButtonsContainer>
				</ListSortContainer>
			)}
			{isFetchingPortfolios ? (
				<SpinnerContainer>
					<Spinner />
				</SpinnerContainer>
			) : (
				<List>
					{sortedPortfolios.map((p) => {
						const isSelected = portfolioId === p.id;

						const onClick = () => {
							navigate(
								routes.portfolioByIdView.toHref({
									portfolioId: p.id,
									viewType: 'dashboard',
								})
							);
						};

						return (
							<PortfolioSidebarItem
								key={p.id}
								isSelected={isSelected}
								portfolio={p}
								onClick={onClick}
							/>
						);
					})}
				</List>
			)}
		</DarkSidebar>
	);
};

const List = styled.ul`
	width: 100%;
	overflow-y: auto;
	height: 100%;
`;

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const NewPortfolioButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.25rem;
`;

const NewPortfolioButton = styled(Button)`
	width: 100%;
`;

const StyledListSortRowInfoText = styled(ListSortRowInfoText)`
	color: ${(p) => p.theme.colors.neutral.n50};
`;

const ListSortContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1.25rem;
`;
