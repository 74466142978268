import { Button, Spinner } from '@compstak/ui-kit';
import { useListSearchInput } from 'Components/ListSearchInput/ListSearchInput';
import { PortfolioSidebarV2SortControl } from 'PortfolioAnalytics/PortfolioSidebar/PortfolioSidebarV2SortControl';
import { SortField } from 'PortfolioAnalytics/PortfolioSidebar/types';
import { usePortfoliosWithAggregations } from 'PortfolioAnalytics/PortfolioSidebar/usePortfoliosWithAggregations';
import { useSortedPortfolios } from 'PortfolioAnalytics/PortfolioSidebar/useSortedPortfolios';
import { SortDirection, useAllPortfoliosQuery } from 'api';
import { useState } from 'react';
import { routes } from 'router';
import styled from 'styled-components';
import { DarkSidebar } from 'ui/UI';
import { trimString } from 'util/trimString';
import IconCross from 'ui/svg_icons/cross.svg';
import { BenchmarkingPortfoliosList } from './BenchmarkingPortfoliosList';

export const BenchmarkingSidebar = () => {
	const [sortField, setSortField] = useState<SortField>('dateCreated');
	const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

	const { element: searchInput, searchTerm } = useListSearchInput({
		inputPlaceholder: 'Search for portfolio',
		inputDataQaId: 'portfolio-search-input',
	});

	const { data: portfolios, isFetching: isFetchingPortfolios } =
		useAllPortfoliosQuery();

	const portfoliosWithAggregates = usePortfoliosWithAggregations(portfolios);

	const filteredPortfoliosWithAggregations = searchTerm
		? portfoliosWithAggregates.filter(({ name }) =>
				trimString(name).toLowerCase().includes(trimString(searchTerm))
			)
		: portfoliosWithAggregates;

	const sortedPortfolios = useSortedPortfolios({
		portfoliosWithAggregates: filteredPortfoliosWithAggregations,
		sortField,
		sortDirection,
	});

	return (
		<DarkSidebar sideBarState="open">
			<HeaderContainer>
				<Header>
					<HeaderTitle>Portfolios</HeaderTitle>
				</Header>
				<ExitBenchmarkingViewButton
					variant="primary3"
					size="l"
					disabled={isFetchingPortfolios}
					icon={<IconCross style={{ marginRight: '0.5em' }} />}
					href={routes.portfolios.path}
				>
					Exit Compare Mode
				</ExitBenchmarkingViewButton>
			</HeaderContainer>
			{!isFetchingPortfolios && (
				<>
					{searchInput}
					<PortfolioSidebarV2SortControl
						sortedPortfolios={sortedPortfolios}
						sortField={sortField}
						setSortField={setSortField}
						sortDirection={sortDirection}
						setSortDirection={setSortDirection}
					/>
				</>
			)}
			{isFetchingPortfolios ? (
				<SpinnerContainer>
					<Spinner />
				</SpinnerContainer>
			) : (
				<BenchmarkingPortfoliosList portfolios={sortedPortfolios} />
			)}
		</DarkSidebar>
	);
};

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	gap: 1rem;
`;

const Header = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const HeaderTitle = styled.h2`
	font-size: 1.25em;
	color: ${({ theme }) => theme.colors.white.white};
	font-weight: 400;
`;

const ExitBenchmarkingViewButton = styled(Button)`
	width: 100%;
`;
