import { ErrorMessage, Flex } from '@compstak/ui-kit';
import {
	SavedSearchDigest,
	SavedSearchEmailFrequency,
} from 'api/savedSearches/types';
import { useModal } from 'providers/ModalProvider';
import { ChangeEvent, useEffect, useId, useState } from 'react';
import styled from 'styled-components';
import IconFloppyDisk from 'ui/svg_icons/floppy-disk.svg';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from '../Modals/common/UI';
import { RadioButton } from '../RadioButton/RadioButton';
import { SavedSearch } from 'api/savedSearches/useSavedSearchesQuery';
import { Checkbox } from 'Components/Checkbox';

type Props = {
	onSave: (
		name: string,
		emailFrequency: SavedSearchEmailFrequency,
		digest: SavedSearchDigest
	) => () => Promise<void>;
	isLoading: boolean;
} & (
	| {
			actionType: 'create';
	  }
	| {
			actionType: 'duplicate' | 'update';
			savedSearch: SavedSearch;
	  }
);

export const SavedSearchActionModal = ({
	onSave,
	isLoading,
	...props
}: Props) => {
	const nameId = useId();
	const { closeModal } = useModal();

	const savedSearchName =
		props.actionType === 'create'
			? ''
			: props.actionType === 'duplicate'
				? `${props.savedSearch.name} (2)`
				: props.savedSearch.name;

	const [name, setName] = useState(savedSearchName);
	const [emailFrequency, setEmailFrequency] = useState(
		props.actionType === 'create'
			? SavedSearchEmailFrequency.Never
			: props.savedSearch.emailFrequency
	);
	const [digest, setDigest] = useState(
		props.actionType === 'create'
			? SavedSearchDigest.Daily
			: props.savedSearch.digestEmailFrequency
	);
	const [isTouched, setIsTouched] = useState(false);
	const [nameError, setNameError] = useState<string | null>(null);

	const trimmedName = name.trim();

	useEffect(() => {
		if (!isTouched) return;

		let nameError = null;

		if (trimmedName.length === 0) {
			nameError = 'Name is required.';
		}

		setNameError(nameError);
	}, [isTouched, trimmedName, setNameError]);

	const onChangeDigest = (e: ChangeEvent<HTMLInputElement>) => {
		setDigest(e.target.value as SavedSearchDigest);
	};

	const isDisabled = trimmedName === '' || isLoading;

	const isInDigest = digest !== SavedSearchDigest.Never;
	const receiveIndividualEmails =
		emailFrequency !== SavedSearchEmailFrequency.Never;

	return (
		<>
			<ModalTitle>{MODAL_TITLE[props.actionType]}</ModalTitle>
			<ModalParagraph>
				Track comps based on filters you’ve selected and receive alerts for
				updates that match your criteria.
			</ModalParagraph>
			<div style={{ marginBottom: '1.5rem' }}>
				<Label htmlFor={nameId}>
					Name<Required>*</Required>
				</Label>
				<Input
					id={nameId}
					value={name}
					onChange={(e) => {
						setName(e.target.value);
						setIsTouched(true);
					}}
					placeholder="Give your saved search a memorable name."
					onBlur={() => setIsTouched(true)}
				/>
				{nameError && <ErrorMessage>{nameError}</ErrorMessage>}
			</div>
			<Flex direction="column" gap="1rem">
				<OptionContainer isSelected={receiveIndividualEmails}>
					<StyledCheckbox
						name="emailFrequency"
						checked={receiveIndividualEmails}
						disabled={isLoading}
						onCheckedChange={() => {
							setEmailFrequency(
								receiveIndividualEmails
									? SavedSearchEmailFrequency.Never
									: SavedSearchEmailFrequency.RealTime
							);
						}}
						data-qa-id="select-email-frequency"
					/>
					<div>
						<OptionLabel>Get Instant Notifications</OptionLabel>
						<div>
							Receive an email as soon as comps that match your saved search
							criteria appear on CompStak.
						</div>
					</div>
				</OptionContainer>
				<OptionContainer isSelected={isInDigest}>
					<StyledCheckbox
						name="emailDigest"
						checked={isInDigest}
						disabled={isLoading}
						onCheckedChange={() => {
							setDigest(
								isInDigest ? SavedSearchDigest.Never : SavedSearchDigest.Daily
							);
						}}
						data-qa-id="select-digest"
					/>
					<div>
						<OptionLabel>Add to Digest</OptionLabel>
						<div>
							Include updates from this saved search in a consolidated email
							summary with your other saved searches. Emails will be sent in the
							morning.
						</div>
						{isInDigest && (
							<Grid>
								<StyledRadioButton
									id="digestDaily"
									name="digest"
									onChange={onChangeDigest}
									checked={digest === SavedSearchDigest.Daily}
									value={SavedSearchDigest.Daily}
								/>
								<label htmlFor="digestDaily">Include in daily digest</label>
								<StyledRadioButton
									id="digestWeekly"
									name="digest"
									onChange={onChangeDigest}
									checked={digest === SavedSearchDigest.Weekly}
									value={SavedSearchDigest.Weekly}
								/>
								<label htmlFor="digestWeekly">Include in weekly digest</label>
							</Grid>
						)}
					</div>
				</OptionContainer>
			</Flex>
			<ModalButtons>
				<ModalButton variant="secondary" type="button" onClick={closeModal}>
					Cancel
				</ModalButton>
				<ModalButton
					type="submit"
					variant="primary"
					disabled={isDisabled}
					isLoading={isLoading}
					icon={<IconFloppyDisk style={{ marginRight: '0.25rem' }} />}
					onClick={onSave(trimmedName, emailFrequency, digest)}
				>
					Save Search
				</ModalButton>
			</ModalButtons>
		</>
	);
};

const Label = styled.label`
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5;
	display: block;
	margin-bottom: 0.5rem;
`;

const Required = styled.span`
	color: ${(p) => p.theme.colors.red.red500};
`;

const Input = styled.input`
	padding: 0.75rem 1rem;
	width: 100%;
	border-radius: 0.25rem;
	border: 1px solid ${(p) => p.theme.colors.neutral.n50};
	background-color: ${(p) => p.theme.colors.white.white};
	height: 48px;
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: min-content auto;
	gap: 0.5rem;
	margin-top: 0.5rem;
`;

const OptionContainer = styled.div<{ isSelected?: boolean }>`
	display: flex;
	align-items: flex-start;
	gap: 1rem;
	width: 100%;
	padding: 1rem;
	border-radius: 4px;
	${({ isSelected, theme }) =>
		isSelected
			? `
			// TODO: add colors to ui-kit
        border: 1px solid #1864B2;
        background-color: #F4F9FF;
        color: #1864B2;
      `
			: `
        border: 1px solid ${theme.colors.gray.gray300};
        color: ${theme.colors.gray.gray700};
      `};
`;

const OptionLabel = styled.div`
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5rem;
`;

const StyledRadioButton = styled(RadioButton)`
	input {
		width: 1.25rem;
		height: 1.25rem;
	}
`;

const StyledCheckbox = styled(Checkbox)`
	margin-top: 0.25rem;
`;

const MODAL_TITLE = {
	create: 'Create a Saved Search',
	update: 'Edit Your Saved Search',
	duplicate: 'Duplicate Your Saved Search',
};
