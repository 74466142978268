import {
	SavedSearchDigest,
	SavedSearchEmailFrequency,
} from 'api/savedSearches/types';
import { useCreateSavedSearchMutation } from 'api/savedSearches/useCreateSavedSearchMutation';
import { useModal } from 'providers/ModalProvider';
import { useToast } from 'providers/ToastProvider';
import { SavedSearchActionModal } from 'Components/SavedSearches/SavedSearchActionModal';
import { SavedSearch } from 'api/savedSearches/useSavedSearchesQuery';

type Props = {
	savedSearch: SavedSearch;
};

export const DuplicateSavedSearchModal = ({ savedSearch }: Props) => {
	const { closeModal } = useModal();

	const { mutateAsync: createSavedSearch, isLoading } =
		useCreateSavedSearchMutation();

	const { addToast } = useToast();

	const { filters, searchType, marketId, sortAsc, sortProperty } = savedSearch;

	return (
		<SavedSearchActionModal
			actionType="duplicate"
			savedSearch={savedSearch}
			isLoading={isLoading}
			onSave={(name, emailFrequency, digestEmailFrequency) => async () => {
				await createSavedSearch(
					{
						name,
						emailFrequency,
						notifyByEmail:
							emailFrequency !== SavedSearchEmailFrequency.Never ||
							digestEmailFrequency !== SavedSearchDigest.Never,
						filters,
						searchType,
						marketId,
						sortAsc,
						sortProperty,
						digestEmailFrequency,
					},
					{
						onSuccess: () => {
							closeModal();
							addToast({
								title: `Successfully created ${name}!`,
							});
						},
						onError: () => {
							addToast({
								title: `Error, saved search not duplicated.`,
								type: 'error',
							});
						},
					}
				);
			}}
		/>
	);
};
