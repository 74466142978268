import { FormSelect, Spinner } from '@compstak/ui-kit';
import {
	CreatePortfolioParams,
	useAllPortfoliosQuery,
	useCreatePortfolioMutation,
	useUpdatePortfolioMutation,
} from 'api';
import { usePortfoliosWithAggregations } from 'PortfolioAnalytics/PortfolioSidebar/usePortfoliosWithAggregations';
import { useSortedPortfolios } from 'PortfolioAnalytics/PortfolioSidebar/useSortedPortfolios';
import { PORTFOLIO_LIMIT } from 'api/portfolio/constants';
import { PORTFOLIO_LIMIT_EXCEEDED_MESSAGE } from 'PortfolioAnalytics/constants';
import { useMemo, useState } from 'react';
import { PortfolioFormFields, usePortfolioForm } from '../PortfolioForm';
import { DialogFormError } from '../UI';
import { SuccessView } from './SuccessView';
import {
	Modal,
	ModalButton,
	ModalButtons,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { addFeedback } from 'Singletons/Feedback/actions';
import { useDispatch } from 'react-redux';

export type AddToPortfolioProps = {
	propertyIds: number[];
	closeDialog: () => void;
	isLoading?: boolean;
};

export const AddToPortfolioDialog = ({
	propertyIds,
	closeDialog,
	isLoading,
}: AddToPortfolioProps) => {
	const [selectedPortfolioId, setSelectedPortfolioId] = useState<
		string | undefined
	>();

	const { data: portfolios, isFetching: isFetchingPortfolios } =
		useAllPortfoliosQuery();
	const dispatch = useDispatch();
	const onError = () => {
		closeDialog();
		dispatch(
			addFeedback(
				'Adding properties to portfolio was unsuccessful',
				'error',
				null,
				5000
			)
		);
	};

	const {
		mutateAsync: updatePortfolio,
		isLoading: isLoadingUpdate,
		data: updatePortfolioData,
	} = useUpdatePortfolioMutation({ onError });

	const {
		mutateAsync: createPortfolio,
		isLoading: isLoadingCreate,
		data: createPortfolioData,
	} = useCreatePortfolioMutation({ onError });

	const selectedPortfolio = useMemo(() => {
		return portfolios?.find((p) => p.id === Number(selectedPortfolioId));
	}, [portfolios, selectedPortfolioId]);

	const isNewPortfolio = selectedPortfolioId === '-1';

	const portfolioForm = usePortfolioForm({});

	const onSubmit = async () => {
		if (isNewPortfolio) {
			const { name, description } = portfolioForm.state;
			const params: CreatePortfolioParams = {
				name,
			};
			if (description.length > 0) {
				params.description = description;
			}
			createPortfolio({
				...params,
				propertyIds,
			});
		} else {
			if (!selectedPortfolio) return;

			const portfolioId = Number(selectedPortfolioId);

			updatePortfolio({
				id: portfolioId,
				body: {
					id: portfolioId,
					name: selectedPortfolio.name,
					description: selectedPortfolio.description,
					newPropertyIds: propertyIds,
				},
			});
		}
	};

	const selectedPortfolioPropertyCount = useMemo(() => {
		if (!selectedPortfolio) return;

		return selectedPortfolio.markets.reduce(
			(sum, m) => sum + m.propertyCount,
			0
		);
	}, [selectedPortfolio]);

	const isPropertyCountValid =
		selectedPortfolioPropertyCount != null
			? selectedPortfolioPropertyCount + propertyIds.length <= PORTFOLIO_LIMIT
			: true;

	const portfoliosWithAggregates = usePortfoliosWithAggregations(portfolios);
	const sortedPortfolios = useSortedPortfolios({
		portfoliosWithAggregates,
		sortField: 'dateCreated',
		sortDirection: 'desc',
	});

	const options = useMemo(() => {
		return [
			{ value: '-1', title: 'Create A New Portfolio' },
			...sortedPortfolios.map((p) => ({ title: p.name, value: String(p.id) })),
		];
	}, [sortedPortfolios]);

	const isSubmitDisabled = isNewPortfolio
		? portfolioForm.isFormLoading || !portfolioForm.isValid || isLoadingCreate
		: selectedPortfolio == null || !isPropertyCountValid || isLoadingUpdate;

	const isSubmitLoading = isNewPortfolio ? isLoadingCreate : isLoadingUpdate;

	const mutationData = isNewPortfolio
		? createPortfolioData
		: updatePortfolioData;

	return (
		<Modal onClose={closeDialog}>
			{mutationData ? (
				<SuccessView portfolioId={mutationData.id} closeDialog={closeDialog} />
			) : (
				<form
					onSubmit={(e) => {
						e.preventDefault();
						onSubmit();
					}}
				>
					<ModalTitle>Add To Portfolio</ModalTitle>
					{isLoading ? (
						<Spinner />
					) : (
						<>
							<div style={{ marginBottom: '0.5rem' }}>
								{!isPropertyCountValid && (
									<DialogFormError>
										{PORTFOLIO_LIMIT_EXCEEDED_MESSAGE}
									</DialogFormError>
								)}
								<FormSelect
									label="Select A Portfolio"
									isSearchable
									isLoading={isFetchingPortfolios}
									value={selectedPortfolioId}
									items={options}
									onChange={({ selectedItem }) =>
										setSelectedPortfolioId(selectedItem?.value)
									}
									noResultMessage="No Portfolios Found"
								/>
							</div>
							{isNewPortfolio && <PortfolioFormFields {...portfolioForm} />}
							<ModalButtons>
								<ModalButton
									variant="secondary"
									type="button"
									onClick={closeDialog}
								>
									Cancel
								</ModalButton>
								<ModalButton
									type="submit"
									variant="primary"
									disabled={isSubmitDisabled}
									isLoading={isSubmitLoading}
								>
									Done
								</ModalButton>
							</ModalButtons>
						</>
					)}
				</form>
			)}
		</Modal>
	);
};
