import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { PortfolioV2, useUpdatePortfolioMutation } from 'api';

type Props = {
	portfolio: PortfolioV2;
	onClose: () => void;
	onSuccess?: () => void;
	removedPropertyIds: number[];
};

const REMOVE_BUTTON_TEXT = 'Remove Properties';

export const PortfolioPropertiesRemovalModal = ({
	portfolio,
	onClose,
	onSuccess: onSuccess,
	removedPropertyIds,
}: Props) => {
	const { mutate: updatePortfolio, isLoading: isLoadingUpdatePortfolio } =
		useUpdatePortfolioMutation({
			onSuccess: () => {
				onSuccess?.();
				onClose();
			},
		});

	const {
		id: portfolioId,
		name: portfolioName,
		description: portfolioDescription,
	} = portfolio.portfolio;

	const onRemove = () => {
		updatePortfolio({
			id: portfolioId,
			body: {
				id: portfolioId,
				name: portfolioName,
				description: portfolioDescription,
				removedPropertyIds: removedPropertyIds,
			},
		});
	};

	return (
		<div>
			<ModalTitle>Remove selected properties from portfolio?</ModalTitle>
			<ModalParagraph>
				Click <b>{REMOVE_BUTTON_TEXT}</b> to remove selected properties from the{' '}
				<b>{portfolioName}</b>.
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="secondary2" onClick={onClose}>
					Cancel
				</ModalButton>
				<ModalButton
					variant="error"
					onClick={onRemove}
					isLoading={isLoadingUpdatePortfolio}
				>
					{REMOVE_BUTTON_TEXT}
				</ModalButton>
			</ModalButtons>
		</div>
	);
};
