import { API } from '@compstak/common';
import {
	UseMutationOptions,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { SavedSearch } from './useSavedSearchesQuery';

const deleteSavedSearch = async (params: SavedSearchParams) => {
	return await API.delete(`/api/savedSearches/${params.id}`);
};

export const useDeleteSavedSearchMutation = (
	options?: UseMutationOptions<unknown, unknown, SavedSearchParams>
) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (params: SavedSearchParams) => deleteSavedSearch(params),
		onSuccess: (_, params) => {
			queryClient.setQueryData<SavedSearch[]>(
				[QUERY_KEYS.SAVED_SEARCHES],
				(oldData) => {
					if (!oldData) return [];
					return oldData.filter((savedSearch) => savedSearch.id !== params.id);
				}
			);
		},
		...options,
	});
};

export type SavedSearchParams = {
	id: string;
};
