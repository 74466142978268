import { API } from '@compstak/common';
import {
	useMutation,
	useQueryClient,
	UseMutationOptions,
} from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { SavedSearch } from './useSavedSearchesQuery';

const updateSavedSearch = async (params: UpdateSavedSearchParams) => {
	const res = await API.put<UpdateSavedSearchResponse>(
		`/api/savedSearches/${params.id}`,
		params.body
	);
	return res.data;
};

export const useUpdateSavedSearchMutation = (
	options?: UseMutationOptions<
		UpdateSavedSearchResponse,
		unknown,
		UpdateSavedSearchParams
	>
) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (params: UpdateSavedSearchParams) => updateSavedSearch(params),
		onSuccess: (data) => {
			queryClient.setQueryData([QUERY_KEYS.SAVED_SEARCHES], data);
		},
		...options,
	});
};

export type UpdateSavedSearchParams = Pick<SavedSearch, 'id'> & {
	body: Pick<
		SavedSearch,
		| 'name'
		| 'emailFrequency'
		| 'filters'
		| 'notifyByEmail'
		| 'marketId'
		| 'digestEmailFrequency'
	>;
};

type UpdateSavedSearchResponse = SavedSearch[];
