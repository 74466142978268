import { Flex, renderTableValue } from '@compstak/ui-kit';
import styled from 'styled-components';
import { PropertyComp } from 'types';
import { PortfolioPropertySummary } from '../../../api';
import {
	MarketRentAnalyticsPeriod,
	useMarketRentAnalyticsState,
} from '../../../maps';
import abbreviateNumber from '../../../ui/util/abbreviateNumber';
import money from '../../../ui/util/money';

type Props = {
	propertyWithSummary: PropertyComp & Partial<PortfolioPropertySummary>;
};

export const MarketRentMapTooltip = ({ propertyWithSummary }: Props) => {
	const [marketRentAnalyticsState] = useMarketRentAnalyticsState();

	const divideRentBy =
		marketRentAnalyticsState.period === MarketRentAnalyticsPeriod.MONTH
			? 12
			: 1;
	const unitPostfixLabel =
		marketRentAnalyticsState.period === MarketRentAnalyticsPeriod.MONTH
			? 'MO'
			: 'YR';

	return (
		<Container>
			<Flex>
				<Name>Property Name</Name>
				<Value>{propertyWithSummary.buildingAddress}</Value>
			</Flex>
			<Flex>
				<Name>Property Type</Name>
				<Value>{propertyWithSummary.buildingPropertyType}</Value>
			</Flex>
			<Flex>
				<Name>Building Class</Name>
				<Value>{propertyWithSummary.buildingClass}</Value>
			</Flex>
			<Flex>
				<Name>SQFT</Name>
				<Value>{abbreviateNumber(propertyWithSummary.totalSqFt)}</Value>
			</Flex>
			<Flex>
				<Name>Leases</Name>
				<Value>{propertyWithSummary.activeLeaseCount}</Value>
			</Flex>
			<Flex>
				<Name>Lease amount</Name>
				<Value>
					{money(propertyWithSummary.activeLeaseAmount ?? 0, 'USD', 2, true)}
				</Value>
			</Flex>
			<Flex>
				<Name>Current Rent</Name>
				<Value>
					{renderTableValue(
						propertyWithSummary.avgCurrentRent,
						(v) =>
							`${money(
								v / divideRentBy,
								'USD',
								2,
								false
							)} ($/SqFt/${unitPostfixLabel})`
					)}
				</Value>
			</Flex>
		</Container>
	);
};

export const Container = styled.div`
	font-size: 10px;
	line-height: 18px;
`;

export const Name = styled.div`
	width: 80px;
	margin-right: 8px;
`;

export const Value = styled.div`
	font-weight: 600;
`;
