import constate from 'constate';
import { useState } from 'react';

export const [PortfolioAlertsStateProvider, usePortfolioAlertsState] = constate(
	() => {
		const [viewedPortfolios, setViewedPortfolios] = useState<
			Record<number, boolean>
		>({});

		return { viewedPortfolios, setViewedPortfolios };
	}
);
