import { BuildingClassId, PortfolioV2Market, PropertyTypeId } from 'api';
import constate from 'constate';
import { pick } from 'lodash';
import { FilterNumberInterval } from 'models/filters/types';
import { useCallback, useMemo, useState } from 'react';
import { routes } from 'router';

type Props = {
	portfolioMarkets: PortfolioV2Market[];
};

type UsePortfolioFilters = {
	filters: FiltersState & { marketIds: number[] };
	setFilters: React.Dispatch<React.SetStateAction<FiltersState>>;
	resetFilters: () => void;
	portfolioMarkets: PortfolioV2Market[];
};

export const [PortfolioFiltersProvider, usePortfolioFilters] = constate(
	({ portfolioMarkets }: Props): UsePortfolioFilters => {
		const [searchParams, setSearchParams] =
			routes.portfolioByIdView.useSearchParams();
		const [filters, _setFilters] = useState<FiltersState>({
			...initialState,
			...pick(
				searchParams,
				'submarketIds',
				'propertyTypeIds',
				'buildingClassIds',
				'leaseExpirationsMonths',
				'buildingSize'
			),
		});

		const setFilters: React.Dispatch<React.SetStateAction<FiltersState>> =
			useCallback(
				(newFilters) => {
					_setFilters(newFilters);
					setSearchParams({
						...searchParams,
						...(typeof newFilters === 'function'
							? newFilters(filters)
							: newFilters),
					});
				},
				[setSearchParams]
			);

		const resetFilters = useCallback(() => {
			setFilters(initialState);
		}, []);

		const marketIds = useMemo(() => {
			return portfolioMarkets
				.filter((m) => {
					return m.submarkets.some((s) =>
						filters.submarketIds.includes(s.submarketId)
					);
				})
				.map((m) => m.marketId);
		}, [portfolioMarkets, filters.submarketIds]);

		return useMemo(
			() => ({
				filters: {
					...filters,
					marketIds,
				},
				setFilters,
				resetFilters,
				portfolioMarkets,
			}),
			[filters, setFilters, resetFilters, marketIds, portfolioMarkets]
		);
	}
);

const initialState: FiltersState = {
	submarketIds: [],
	propertyTypeIds: [],
	buildingClassIds: [],
	leaseExpirationsMonths: null,
	buildingSize: {
		min: null,
		max: null,
	},
};

export type FiltersState = {
	submarketIds: number[];
	propertyTypeIds: PropertyTypeId[];
	buildingClassIds: BuildingClassId[];
	leaseExpirationsMonths: LeaseExpirationsMonths;
	buildingSize: FilterNumberInterval | null | undefined;
};

export type LeaseExpirationsMonths = 12 | 24 | 36 | 48 | 60 | null;
