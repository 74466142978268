import { nullValueText } from '@compstak/common';
import React from 'react';
import { LeaseComp } from 'types';
import { PDFTable } from './UI';

const LeaseTableRow = (props: LeaseComp) => {
	return (
		<tr>
			<td>{props.tenantName ?? nullValueText}</td>
			<td>{props.buildingAddress ?? nullValueText}</td>
			<td>{props.city ?? nullValueText}</td>
			<td>{props.submarket ?? nullValueText}</td>
			<td>
				{props.transactionSize
					? String(props.transactionSize).replace(' SqFt', '')
					: nullValueText}
			</td>
			<td>{props.effectiveRent ?? nullValueText}</td>
			<td>{props.leaseTerm ?? nullValueText}</td>
		</tr>
	);
};

export const LeasesTable = ({ leaseComps }: { leaseComps: LeaseComp[] }) => {
	return (
		<PDFTable>
			<thead>
				<tr>
					<th>Tenant</th>
					<th>Address</th>
					<th>City</th>
					<th>Submarket</th>
					<th>SQFT</th>
					<th>Net Effective Rent</th>
					<th>Term</th>
				</tr>
			</thead>
			<tbody>
				{leaseComps.slice(0, 10).map((comp) => (
					<LeaseTableRow
						{...comp}
						key={comp.id}
						id={comp.id}
						own={comp.own}
						buildingAddress={comp.buildingAddress}
						buildingSize={comp.buildingSize}
						city={comp.city}
						effectiveRent={comp.effectiveRent}
						leaseTerm={comp.leaseTerm}
						submarket={comp.submarket}
						tenantName={comp.tenantName}
						transactionSize={comp.transactionSize}
					/>
				))}
			</tbody>
		</PDFTable>
	);
};
