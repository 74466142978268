import {
	SearchLeasesResponse,
	composeEnabled,
	useSearchLeasesQuery,
} from 'api';
import { filtersToServerJSON } from 'models/filters/util';
import { usePortfolioInteractionsQuery } from '../../api/portfolio/interactions/usePortfolioInteractionsQuery';
import { useViewLeaseActivityMutation } from '../../api/portfolio/interactions/useViewLeaseActivityMutation';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { usePortfolioAlertsState } from 'PortfolioAnalytics/PortfolioAlertsStateProvider';
import { UseQueryOptions } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

type Props = {
	portfolioId: number;
};

export const useNewLeasesInPortfolio = (
	{ portfolioId }: Props,
	options?: UseQueryOptions<SearchLeasesResponse>
) => {
	const { portfolioRevampAlertsAndNotificationsFF } = useFeatureFlags();
	const portfolioAlertsState = usePortfolioAlertsState();

	const enabled = composeEnabled(
		portfolioRevampAlertsAndNotificationsFF && !isEmpty(portfolioAlertsState),
		options?.enabled
	);

	const { data: portfoliosInteractions } = usePortfolioInteractionsQuery({
		enabled,
	});
	const selectedPortfolio = portfoliosInteractions?.[portfolioId];

	const { viewedPortfolios, setViewedPortfolios } = portfolioAlertsState ?? {
		viewedPortfolios: {},
		setViewedPortfolios: () => {},
	};

	const searchLeasesQuery = useSearchLeasesQuery(
		{
			filter: filtersToServerJSON({
				executionDate: {
					max: null,
					min: selectedPortfolio?.leaseActivityLastViewedAt ?? null,
				},
				portfolioId,
			}),
			limit: 1,
			offset: 0,
			order: 'asc',
			sort: 'executionDate',
		},
		{ enabled }
	);

	const viewPortfolio = (portfolioId: number) => {
		setViewedPortfolios((prevViewedPortfolios) => ({
			...prevViewedPortfolios,
			[portfolioId]: true,
		}));
	};

	const { mutate: mutateViewLeaseActivity } = useViewLeaseActivityMutation();

	return {
		isViewed: !!viewedPortfolios?.[portfolioId],
		viewPortfolio,
		mutateViewLeaseActivity,
		...searchLeasesQuery,
	};
};
