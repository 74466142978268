import { Portfolio, PortfolioMarket } from 'api';
import { formatInteger, formatMoney } from 'format';
import { PortfolioHelpIcon } from 'PortfolioAnalytics/Singletons/PortfolioHelpIcon';
import { ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { dashWhenNoValue } from '../../../utils';
import { Card } from './UI';

type Props = {
	portfolio: Portfolio;
};

export const Averages = ({ portfolio }: Props) => {
	const averages = useAverages(portfolio);

	return (
		<BottomRow>
			<Card>
				<CardTitle>
					Avg. Current Rent (yr/SqFt)
					<PortfolioHelpIcon tooltip={TOOLTIP_TEXT.avgCurrentRent} />
				</CardTitle>
				<CardValue>
					{dashWhenNoValue(averages.avgCurrentRent, formatMoney)}
				</CardValue>
			</Card>
			<Card>
				<CardTitle>
					Avg. Net Effective Rent (yr/SqFt)
					<PortfolioHelpIcon tooltip={TOOLTIP_TEXT.avgNetEffectiveRent} />
				</CardTitle>
				<CardValue>
					{dashWhenNoValue(averages.avgNetEffectiveRent, formatMoney)}
				</CardValue>
			</Card>
			<Card>
				<CardTitle>
					Avg. Free Rent (mo)
					<PortfolioHelpIcon tooltip={TOOLTIP_TEXT.avgFreeRent} />
				</CardTitle>
				<CardValue>
					{dashWhenNoValue(averages.avgFreeRent, formatMonths)}
				</CardValue>
			</Card>
			<Card>
				<CardTitle>
					Avg. TI Allowance (yr/SqFt)
					<PortfolioHelpIcon tooltip={TOOLTIP_TEXT.avgTIImprovement} />
				</CardTitle>
				<CardValue>
					{dashWhenNoValue(averages.avgTIImprovement, formatMoney)}
				</CardValue>
			</Card>
			<Card>
				<CardTitle>
					Avg. Lease Term (mo)
					<PortfolioHelpIcon tooltip={TOOLTIP_TEXT.avgLeaseTerm} />
				</CardTitle>
				<CardValue>
					{dashWhenNoValue(averages.avgLeaseTerm, formatMonths)}
				</CardValue>
			</Card>
			<Card>
				<CardTitle>
					24 Months Expiring SQFT
					<PortfolioHelpIcon tooltip={TOOLTIP_TEXT.expiringSqFt24Months} />
				</CardTitle>
				<CardValue>
					{dashWhenNoValue(averages.expiringSqFt24Months, formatInteger)}
				</CardValue>
			</Card>
			<Card>
				<CardTitle>
					Avg. Rent Discount (yr/%)
					<PortfolioHelpIcon tooltip={TOOLTIP_TEXT.avgDiscountRentInPercents} />
				</CardTitle>
				<CardValue>
					{dashWhenNoValue(averages.avgDiscountRentInPercents, (v) =>
						v.toFixed(1)
					)}
				</CardValue>
			</Card>
			<Card>
				<CardTitle>
					Avg. Transactional SQFT (yr/SqFt)
					<PortfolioHelpIcon tooltip={TOOLTIP_TEXT.avgTransactionalSqFt} />
				</CardTitle>
				<CardValue>
					{dashWhenNoValue(averages.avgTransactionalSqFt, formatInteger)}
				</CardValue>
			</Card>
		</BottomRow>
	);
};

const averagesKeys = [
	'avgCurrentRent',
	'avgNetEffectiveRent',
	'avgFreeRent',
	'avgTIImprovement',
	'avgLeaseTerm',
	'expiringSqFt24Months',
	'avgDiscountRentInPercents',
	'avgTransactionalSqFt',
] as const;

type AverageKey = (typeof averagesKeys)[number];

type Averages = Record<AverageKey, number | null>;

const useAverages = (portfolio: Portfolio) => {
	return useMemo(() => {
		const averages = {} as Averages;
		for (const key of averagesKeys) {
			averages[key] = getAverageForField(portfolio, key);
		}
		return averages;
	}, [portfolio]);
};

const getAverageForField = (portfolio: Portfolio, field: AverageKey) => {
	const marketsWithValue = portfolio.markets.filter((m) => m[field] != null);

	if (marketsWithValue.length === 0) return null;

	if (field === 'expiringSqFt24Months') {
		return marketsWithValue.reduce((total, market) => {
			return total + market.expiringSqFt24Months;
		}, 0);
	}

	const totalActiveLeaseCount = marketsWithValue.reduce((total, m) => {
		return total + m.activeLeaseCount;
	}, 0);

	if (totalActiveLeaseCount === 0) return null;

	let total = 0;

	for (const market of marketsWithValue) {
		total += market[field] * market.activeLeaseCount;
	}

	return total / totalActiveLeaseCount;
};

const formatMonths = (months: number) => months > 0 && months.toFixed(1);

const TOOLTIP_TEXT: Partial<Record<keyof PortfolioMarket, ReactNode>> = {
	avgCurrentRent: `Average Current Rent is calculated by taking the $/SqFt/YR in each active lease and dividing by the number of leases`,
	avgNetEffectiveRent: `Average Net Effective Rent is calculated by taking the $/SqFt/YR in each active lease and dividing by the number of leases`,
	avgFreeRent: `Average Free Rent is calculated by taking the number of months of free rent in each active lease and dividing by the number of leases`,
	avgTIImprovement: `Average Tenant Improvement Allowance is calculated by taking the $/SqFt/YR in each active lease and dividing by the number of leases`,
	avgLeaseTerm: `Average Lease Term is calculated by taking the lease term of each active lease and dividing by the number of leases`,
	expiringSqFt24Months: `24 Months Expiring SQFT is calculated as the sum of the square footage in active leases with an expiration date in the next 24 months`,
	avgDiscountRentInPercents: (
		<>
			<div>
				Average Rent Discount is calculated by taking the rate discount in each
				active lease and dividing by the number of active leases.
			</div>
			<div>
				Rent Discount on an individual lease is calculated as the % difference
				between Starting Rent and Effective Rent
			</div>
		</>
	),
	avgTransactionalSqFt: `Average Transactional SQFT is calculated by taking the SQFT in each active lease and dividing by the number of leases`,
};

const BottomRow = styled.div`
	display: grid;
	grid-template-columns: auto;
	gap: 0.5rem;
	@media (min-width: 1024px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (min-width: 1280px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const CardTitle = styled.div`
	text-transform: capitalize;
	font-size: 0.875rem;
	margin-bottom: 0.5rem;
	display: flex;
	gap: 0.5rem;
`;

const CardValue = styled.div`
	font-size: 1.5rem;
	font-weight: 400;
`;
