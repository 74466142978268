import styled from 'styled-components';
import { useCallback, useRef, useState } from 'react';
import SearchIcon from '../../ui/svg_icons/search.svg';
import CrossIcon from '../../ui/svg_icons/cross.svg';
import { trimString } from '../../util/trimString';

interface UseListSearchInputProps {
	inputHeight?: string;
	inputPlaceholder: string;
	inputDataQaId?: string;
	iconSize?: number;
	autoFocus?: boolean;
}

const DEFAULT_INPUT_HEIGHT = '3rem';
const DEFAULT_ICON_SIZE = 14;

export const useListSearchInput = ({
	inputHeight = DEFAULT_INPUT_HEIGHT,
	iconSize = DEFAULT_ICON_SIZE,
	inputPlaceholder,
	inputDataQaId = 'search-input',
	autoFocus = true,
}: UseListSearchInputProps) => {
	const [searchTerm, setSearchTerm] = useState('');
	const searchInputRef = useRef<HTMLInputElement>(null);

	const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		setSearchTerm(event.target.value);
	};

	const reset = useCallback(() => {
		setSearchTerm('');
	}, []);

	const trimmedSearchTerm = trimString(searchTerm).toLowerCase();

	const element = (
		<ListSearchInputStyled inputHeight={inputHeight}>
			<SearchIcon
				width={iconSize}
				height={iconSize}
				data-qa-id={searchIconTestId}
			/>
			<input
				ref={searchInputRef}
				data-qa-id={inputDataQaId}
				type="text"
				autoFocus={autoFocus}
				onChange={onChange}
				placeholder={inputPlaceholder}
				value={searchTerm}
			/>
			{searchTerm.length > 0 && (
				<StyledCrossIcon
					data-qa-id={crossIconTestId}
					width={10}
					height={10}
					onClick={(e) => {
						e.stopPropagation();
						reset();
						searchInputRef.current?.focus();
					}}
				/>
			)}
		</ListSearchInputStyled>
	);

	return { element, searchTerm: trimmedSearchTerm, reset };
};

export const searchIconTestId = 'searchIconTestId';
export const crossIconTestId = 'crossIconTestId';

export const StyledCrossIcon = styled(CrossIcon)`
	cursor: pointer;
	margin-right: 22px;
`;

export const ListSearchInputStyled = styled.div<{ inputHeight?: string }>`
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.gray.gray800};
	height: ${({ inputHeight = DEFAULT_INPUT_HEIGHT }) => inputHeight};
	line-height: ${({ inputHeight = DEFAULT_INPUT_HEIGHT }) => inputHeight};
	padding-left: 20px;
	font-size: 0.875rem;

	& svg {
		min-width: 14px;
		font-size: 14px;
		fill: white;
	}

	input {
		padding-left: 8px;
	}

	& input,
	input:focus {
		border: none;
		height: ${({ inputHeight = DEFAULT_INPUT_HEIGHT }) => inputHeight};
		background-color: ${({ theme }) => theme.colors.gray.gray800};
		color: white;
		margin: 0;
	}

	input::placeholder {
		font-style: italic;
	}
`;
