import { Dash, isVoid } from '@compstak/ui-kit';
import { PropertyTypeName } from 'api';
import { KeyValueTableLabel } from 'Components/KvTable';
import { memo } from 'react';
import styled from 'styled-components';
import { MufaPropertyComp, PropertyComp } from 'types';
import {
	commercialLabels,
	KeysToDisplay,
	MufaKeysToDisplay,
	mufaLabels,
} from './labels';
import { KeyInfoItems } from './KeyInfoItems';
import { RealPage } from '../RealPage';
import { useCompstakEstimatesData } from '../hooks/useCompstakEstimatesData';
import {
	AttributesTableField,
	AttributesTableHeader,
	AttributesTableSection,
	AttributesTableSectionsContainer,
	SECTION_GAP,
	SECTION_WIDTH,
} from '../LoansTab/UI';
import {
	OverviewKeysToDisplay,
	PropertyWithYearInfo,
} from '../pdf/OverviewPage/useEditableOverview';
import { usePropertyWithYearInfo } from '../pdf/usePropertyWithYearInfo';

type PropertyAttributesProps = {
	property: PropertyComp | MufaPropertyComp;
	isMufa: boolean;
};

export const ROW_COUNT = 5;

export const PropertyAttributes = memo(
	({ property, isMufa }: PropertyAttributesProps) => {
		const propertyAttributes = getPropertyAttributesKeys(
			isMufa,
			property.buildingPropertyType
		);
		const propertyWithYearInfo = usePropertyWithYearInfo(property);

		const sections = getPropertyAttributeSections(propertyAttributes);

		const sectionCount = sections.length;

		const { showCompstakEstimates } = useCompstakEstimatesData();

		return (
			<div
				style={{
					flex: 1,
					maxWidth:
						sectionCount !== 1
							? `${
									sectionCount * SECTION_WIDTH +
									(sectionCount - 1) * SECTION_GAP
								}px`
							: '',
				}}
			>
				<KeyInfoItems property={property} isMufa={isMufa} />
				<div>
					<AttributesTableHeader>
						Property Attributes{' '}
						{isMufa && showCompstakEstimates && <RealPage />}
					</AttributesTableHeader>

					<AttributesTableSectionsContainer>
						<PropertyAttributesSections
							isMufa={isMufa}
							property={propertyWithYearInfo}
							sections={sections}
						/>
					</AttributesTableSectionsContainer>
				</div>
				{isMufa && !showCompstakEstimates && <RealPage />}
			</div>
		);
	}
);

PropertyAttributes.displayName = 'PropertyAttributes';

type CommercialKeysArray = Array<KeysToDisplay>;
type MufaKeysArray = Array<MufaKeysToDisplay | KeysToDisplay>;

export const tableKeysCommon: CommercialKeysArray = [
	'buildingFloorsCount',
	'lotSize',
	'zoning',
];

export const tableKeysCommercial: Record<
	PropertyTypeName,
	CommercialKeysArray
> = {
	Office: [
		'propertyAverageTransactionSize',
		'opportunityZoneId',
		'parkingRatio',
		'parkingNotes',
	],
	Industrial: [
		'propertyAverageTransactionSize',
		'constructionType',
		'ceilingHeight',
		'loadingDocksCount',
		'groundLvlDoorsCount',
		'sprinkler',
		'parkingNotes',
		'amps',
		'volts',
	],
	Retail: ['propertyAverageTransactionSize'],
	'Mixed-Use': ['propertyAverageTransactionSize'],
	Hotel: ['numberOfUnits'],
	'Multi-Family': ['numberOfUnits'],
	Other: ['propertyAverageTransactionSize'],
	Land: [],
};

export const tableKeysMufa: MufaKeysArray = [
	...tableKeysCommon,
	'propertySubtype',
	'dailyPricing',
	'assetClassMarket',
	'assetClassSubmarket',
	'totalUnits',
	'marketRateUnits',
	'areaPerUnit',
	'unitMixType',
	'survey',
];

export const getPropertyAttributesKeys = (
	isMufa: boolean,
	propertyType?: PropertyTypeName
): (MufaKeysToDisplay | KeysToDisplay)[] => {
	if (isMufa) return tableKeysMufa;
	return propertyType
		? [...tableKeysCommon, ...tableKeysCommercial[propertyType]]
		: tableKeysCommon;
};

const PropertyAttributesSections = <
	T extends MufaKeysArray | CommercialKeysArray,
>({
	isMufa,
	property,
	sections,
}: {
	isMufa: boolean;
	property: PropertyWithYearInfo;
	sections: T[];
}) => {
	return (
		<>
			{sections.map((section, sectionIndex) => {
				return (
					<AttributesTableSection key={'section' + sectionIndex}>
						{section.map((key) => {
							const { name, formatter, tooltip } = isMufa
								? mufaLabels[key]
								: commercialLabels[key as KeysToDisplay];
							const rawValue = property[key];
							const isValidRecord = rawValue && !isVoid(rawValue);
							const verticalAlignment = shouldChangeAlignment(key, rawValue);

							return (
								<AttributesTableField key={key} vertical={verticalAlignment}>
									<PropertyAttributeLabel
										tooltip={tooltip}
										name={name}
										key={name}
									/>
									<PropertyAttributeValue vertical={verticalAlignment}>
										{isValidRecord ? (
											formatter ? (
												formatter(rawValue)
											) : (
												rawValue
											)
										) : (
											<Dash key={`${name}-empty`} />
										)}
									</PropertyAttributeValue>
								</AttributesTableField>
							);
						})}
					</AttributesTableSection>
				);
			})}
		</>
	);
};

const longAttributes: Array<OverviewKeysToDisplay> = [
	'constructionType',
	'parkingNotes',
];

export const shouldChangeAlignment = (key: OverviewKeysToDisplay, val: any) => {
	return (
		longAttributes.includes(key) &&
		!!val &&
		typeof val === 'string' &&
		(val.match(/ /g) || []).length > 2
	);
};

export const getPropertyAttributeSections = (
	propertyAttributes: (MufaKeysToDisplay | KeysToDisplay)[]
) => {
	const sections = [];
	for (let i = 0; i < propertyAttributes.length; i = i + ROW_COUNT) {
		sections.push(propertyAttributes.slice(i, i + ROW_COUNT));
	}
	return sections;
};

const PropertyAttributeValue = styled.div<{ vertical?: boolean }>`
	color: ${({ theme }) => theme.colors.gray.gray700};
	text-align: ${({ vertical }) => (vertical ? 'left' : 'right')};
`;

const PropertyAttributeLabel = styled(KeyValueTableLabel)`
	color: ${({ theme }) => theme.colors.neutral.n85};
	text-align: left;
	svg {
		height: 1rem;
		width: 1rem;
		path {
			fill: ${({ theme }) => theme.colors.gray.gray700};
		}
	}
`;
