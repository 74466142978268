export enum PortfolioNotificationFrequency {
	Never = 'never',
	RealTime = 'realTime',
	Daily = 'daily',
	Weekly = 'weekly',
}

export type WithPreferencesParams<T extends object> =
	| T
	| (T & {
			notificationFrequency: PortfolioNotificationFrequency;
	  });
