import {
	Flex,
	SimpleTableColumn,
	TooltipV2,
	renderTableValue,
} from '@compstak/ui-kit';
import { TableCellGapper } from 'Components/UI';
import {
	formatCompQuarter,
	formatMonths,
	formatObfuscatedSizeRange,
	formatRentWithPostfix,
} from 'format';
import { formatSqft } from 'format/formatSqft';
import { useIsExchange } from 'hooks';
import { LeaseComp } from 'types';
import { IconLockGrey } from 'ui/UI';
import IconHelp from 'ui/svg_icons/help_v2.svg';
import { getIsMonthlyMarket } from 'utils';
import { getShouldShowLockIcon } from '../../../../utils';
import { COMP_FIELD_TOOLTIPS } from '../../../../constants/tooltips';

export const useLeasesInCompsetTableColumns =
	(): SimpleTableColumn<LeaseComp>[] => {
		const isExchange = useIsExchange();

		return [
			{
				id: 'executionQuarter',
				header: () => (
					<Flex>
						<div style={{ width: 8 }} />
						<div>Trans. Qtr.</div>
					</Flex>
				),
				width: 90,
				body: ({ row }) => {
					return renderTableValue(row.executionQuarter, formatCompQuarter);
				},
				hAlign: 'center',
			},
			{
				id: 'buildingAddress',
				header: 'Address',
				width: 155,
				flex: 2,
				body: ({ row }) => {
					return renderTableValue(row.buildingAddress);
				},
			},
			{
				id: 'tenantName',
				header: 'Tenant',
				width: 200,
				flex: 2,
				body: ({ row }) => {
					return renderTableValue(row.tenantName);
				},
			},
			{
				id: 'transactionSize',
				header: () => (
					<TableCellGapper>
						Size
						<TooltipV2 content="Total SqFt reported. If Retail, total Main floor SqFt only.">
							<div>
								<IconHelp width={16} />
							</div>
						</TooltipV2>
					</TableCellGapper>
				),
				width: 100,
				body: ({ row }) => {
					return renderTableValue(row.transactionSize, (v) =>
						row.own ? formatSqft(v) : formatObfuscatedSizeRange(v)
					);
				},
			},
			{
				id: 'startingRent',
				header: () => (
					<TableCellGapper>
						Starting Rent
						<TooltipV2 content="$/SqFt on the first day of the lease, quoted in annual or monthly terms, depending on the market.">
							<div>
								<IconHelp width={16} />
							</div>
						</TooltipV2>
					</TableCellGapper>
				),
				width: 124,
				flex: 1,
				body: ({ row }) => {
					if (getShouldShowLockIcon(row.startingRent, row, isExchange)) {
						return <IconLockGrey />;
					}
					const isMonthly = getIsMonthlyMarket(row.marketId);
					return renderTableValue(row.startingRent, (v) =>
						formatRentWithPostfix(v, isMonthly)
					);
				},
			},
			{
				id: 'adjEffectiveRent',
				header: () => (
					<TableCellGapper>
						Net Effective Rent
						<TooltipV2 content={COMP_FIELD_TOOLTIPS.effectiveRent}>
							<div>
								<IconHelp width={16} />
							</div>
						</TooltipV2>
					</TableCellGapper>
				),
				width: 144,
				flex: 1,
				body: ({ row }) => {
					if (getShouldShowLockIcon(row.adjEffectiveRent, row, isExchange)) {
						return <IconLockGrey />;
					}
					const isMonthly = getIsMonthlyMarket(row.marketId);
					return renderTableValue(row.adjEffectiveRent, (v) =>
						formatRentWithPostfix(v, isMonthly)
					);
				},
			},
			{
				id: 'leaseTerm',
				header: 'Term',
				width: 80,
				body: ({ row }) => {
					if (getShouldShowLockIcon(row.leaseTerm, row, isExchange)) {
						return <IconLockGrey />;
					}
					return renderTableValue(row.leaseTerm, formatMonths);
				},
			},
			{
				id: 'spaceType',
				header: 'Space Type',
				width: 112,
				body: ({ row }) => {
					return renderTableValue(row.spaceType);
				},
			},
		];
	};
