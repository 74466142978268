import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Flex, TooltipV2 } from '@compstak/ui-kit';
import { SortDirection, useSearchLeasesQuery, useSearchSalesQuery } from 'api';
import { useNewCompsQuery } from 'api/newComps/useNewCompsQuery';
import { SavedSearch } from 'api/savedSearches/useSavedSearchesQuery';
import {
	filtersFromServerJSON,
	filtersToServerJSON,
} from 'models/filters/util';
import { useMarkets } from 'hooks/useMarkets';
import { PropertyFilters } from 'models/filters/property';
import { SavedSearchType } from 'api/savedSearches/types';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import styled from 'styled-components';
import { tooltipCss } from '../useSavedSearchesColumns';

type Props = {
	savedSearch: SavedSearch;
};
export const SavedSearchNewCount = ({ savedSearch }: Props) => {
	const markets = useMarkets();

	const savedSearchFilters = useMemo(() => {
		return filtersFromServerJSON(
			savedSearch.searchType,
			markets,
			savedSearch.filters
		);
	}, [savedSearch.searchType, savedSearch.filters, markets]);

	const params = useMemo(() => {
		return {
			filter: filtersToServerJSON(
				mergeFilters(savedSearchFilters, {
					dateCreated: {
						min: dayjs(savedSearch.dateSeen).toDate(),
						max: null,
					},
				})
			),
			offset: 0,
			limit: 1,
			order: 'asc' as SortDirection,
			sort: 'dateCreated',
		};
	}, [savedSearch.dateSeen, savedSearchFilters]);

	const { data: leaseData } = useSearchLeasesQuery(params, {
		enabled: savedSearch.searchType === SavedSearchType.LEASES,
	});

	const { data: saleData } = useSearchSalesQuery({
		params,
		enabled: savedSearch.searchType === SavedSearchType.SALES,
	});

	const { data: propertiesNewCompsData } = useNewCompsQuery(
		{
			newFrom: savedSearch.dateSeen,
			filters: savedSearchFilters as PropertyFilters,
		},
		{ enabled: savedSearch.searchType === SavedSearchType.PROPERTIES }
	);

	const newCompsCount = (() => {
		switch (savedSearch.searchType) {
			case SavedSearchType.LEASES:
				return leaseData?.totalCount;
			case SavedSearchType.SALES:
				return saleData?.totalCount;
			case SavedSearchType.PROPERTIES:
				return propertiesNewCompsData
					? propertiesNewCompsData.leases.total +
							propertiesNewCompsData.sales.total
					: undefined;
			default:
				return undefined;
		}
	})();

	return (
		<Flex gap="0.5rem">
			<Name>{savedSearch.name}</Name>
			{newCompsCount ? (
				<TooltipV2
					content="Shows the number of new comps added since your last visit to this Saved Search. The count is based on the 'Last Viewed' date."
					contentCSS={tooltipCss}
				>
					<Count>
						{abbreviateNumber(newCompsCount)}
						{savedSearch.searchType === SavedSearchType.PROPERTIES &&
						propertiesNewCompsData?.possiblyHasMore
							? '+'
							: ''}{' '}
						New
					</Count>
				</TooltipV2>
			) : null}
		</Flex>
	);
};

const Name = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;
const Count = styled.div`
	// TODO: add color to ui-kit
	border: 1px solid #1864b2;
	background-color: #f4f9ff;
	color: #1864b2;
	border-radius: 8px;
	padding: 0.25rem 0.5rem;
	font-size: 0.75rem;
	font-weight: 500;
	width: fit-content;
`;
