import { API, APIClientNotOkResponseError } from '@compstak/common';
import {
	UseMutationOptions,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { SavedSearch } from './useSavedSearchesQuery';
import { QUERY_KEYS } from 'api/constants';

export const useCreateSavedSearchMutation = (
	options?: UseMutationOptions<
		CreateSavedSearchResponse,
		APIClientNotOkResponseError,
		CreateSavedSearchParams
	>
) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (params: CreateSavedSearchParams) => {
			const res = await API.post<CreateSavedSearchResponse>(
				`/api/savedSearches`,
				params
			);
			return res.data;
		},
		...options,
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.SAVED_SEARCHES]);
		},
	});
};

export type CreateSavedSearchParams = Pick<
	SavedSearch,
	| 'name'
	| 'searchType'
	| 'marketId'
	| 'filters'
	| 'sortAsc'
	| 'sortProperty'
	| 'notifyByEmail'
	| 'emailFrequency'
	| 'digestEmailFrequency'
>;

type CreateSavedSearchResponse = SavedSearch[];
