import { Feature, Point } from '@turf/helpers';
import { TenantIndustryDataPoint } from 'api';
import number from 'util/comp-format/src/util/number';
import { MapAnalyticsMetric } from '../../types';
import {
	MapAnalyticsTooltipDescription,
	MapAnalyticsTooltipTitle,
} from '../UI';

type Props = {
	feature: Feature<Point, TenantIndustryDataPoint>;
	metric: MapAnalyticsMetric;
};

export const TenantIndustryTooltip = ({ feature, metric }: Props) => {
	return (
		<>
			<MapAnalyticsTooltipTitle>
				{feature.properties.industry}
			</MapAnalyticsTooltipTitle>
			<MapAnalyticsTooltipDescription>
				{number(feature.properties.sqftOrLeaseCount)}{' '}
				{metric === 'sqft'
					? 'SqFt'
					: feature.properties.sqftOrLeaseCount === 1
						? 'Lease'
						: 'Leases'}
			</MapAnalyticsTooltipDescription>
		</>
	);
};
