import { styled, css } from 'styled-components';
import {
	ModalButton,
	ModalButtons,
	ModalTitle,
	ModalParagraph,
} from 'Components/Modals/common/UI';
import { useId, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addFeedback } from 'Singletons/Feedback/actions';
import { usePortfolioDataRequestMutation } from 'api/portfolio/dataRequest/usePortfolioDataRequestMutation';
import { trimString } from 'util/trimString';

type Props = {
	closeDialog: () => void;
	portfolioId: number;
};

export const DataRequestDialog = ({ closeDialog, portfolioId }: Props) => {
	const [state, setState] = useState({ title: '', description: '' });

	const dispatch = useDispatch();

	const { mutate: sendADataRequest, isLoading } =
		usePortfolioDataRequestMutation({
			onSuccess: () => {
				closeDialog();
				dispatch(addFeedback('Data request sent!', 'success', null, 5000));
			},
			onError: () => {
				closeDialog();
				dispatch(addFeedback('Data request error!', 'error', null, 5000));
			},
		});

	const nameId = useId();
	const descriptionId = useId();

	const disabled = !trimString(state.description);

	return (
		<>
			<ModalTitle>Send a Data Request</ModalTitle>
			<ModalParagraph>
				Submit your data request using the form below. The more details you
				provide, the better we can assist you. Our Client Success Team will
				respond in 1-3 business days, though data collection times may very
				based on the complexity of the request.
			</ModalParagraph>
			<Spacer />
			<form
				onSubmit={(e) => {
					e.preventDefault();
					sendADataRequest({
						portfolioId,
						title: trimString(state.title),
						description: trimString(state.description),
					});
				}}
			>
				<InputContainer>
					<DialogFormLabel htmlFor={nameId}>Request Title</DialogFormLabel>
					<TextInput
						type="text"
						id={nameId}
						value={state.title}
						onChange={(e) =>
							setState((prevState) => ({ ...prevState, title: e.target.value }))
						}
						data-qa-id="title"
					/>
				</InputContainer>
				<Spacer />
				<InputContainer>
					<DialogFormLabel id={descriptionId}>
						Request Description
					</DialogFormLabel>
					<TextArea
						id={descriptionId}
						placeholder="What data would you like to request?"
						value={state.description}
						onChange={(e) =>
							setState((prevState) => ({
								...prevState,
								description: e.target.value,
							}))
						}
						data-qa-id="description"
					/>
				</InputContainer>
				<ModalButtons>
					<ModalButton variant="secondary" onClick={closeDialog}>
						Cancel
					</ModalButton>
					<ModalButton
						type="submit"
						variant="primary"
						disabled={disabled}
						isLoading={isLoading}
					>
						Submit
					</ModalButton>
				</ModalButtons>
			</form>
		</>
	);
};

const Spacer = styled.div`
	background-color: ${(p) => p.theme.colors.white.white};
	height: 1rem;
`;

const InputCss = css`
	&& {
		background-color: ${(p) => p.theme.colors.neutral.n30};
		border: 0;
		box-sizing: border-box;
		color: ${(p) => p.theme.colors.gray.gray700};
		font-size: 1rem;
		margin: 0;
		padding: 0;
		height: auto;
	}
`;

const TextInput = styled.input`
	${InputCss}
`;

const TextArea = styled.textarea`
	${InputCss};
	min-height: 140px;
`;

const InputContainer = styled.div`
	width: 100%;
	background-color: ${(p) => p.theme.colors.neutral.n30};
	border: 1px solid;
	border-color: ${(p) => p.theme.colors.neutral.n30};
	border-radius: 0.125rem;
	display: block;
	outline: 0;
	padding: 1rem;
`;

const DialogFormLabel = styled.label`
	font-size: 0.75rem;
	color: ${(p) => p.theme.colors.blue.blue400};
	font-weight: 400;
	display: block;
	text-align: left;
`;
