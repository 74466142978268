import { LayerColor } from '@compstak/maps';
import { MarketRentAnalyticsSpaceType } from 'api';
import { WHITE_RGBA_COLOR } from 'constants/colors';
import money from '../../../util/comp-format/src/util/money';

export const moneyFormat = (value: number) =>
	money(value.toFixed(2), undefined);

export const MONTHLY_POSTFIX = '/SqFt/MO';
export const YEARLY_POSTFIX = '/SqFt/YR';

export const MARKET_RENT_ANALYTIC_COLOR_SCALE: LayerColor[] = [
	[253, 209, 210],
	[251, 144, 147],
	[227, 87, 104],
	[184, 38, 81],
	[120, 7, 81],
];

export const PROPERTY_TYPE_MAP = {
	1: MarketRentAnalyticsSpaceType.OFFICE,
	7: MarketRentAnalyticsSpaceType.INDUSTRIAL,
};

export const DEFAULT_SPACE_TYPE_ID = 1;

export const MARKET_RENT_ANALYTIC_SUBMARKET_LINE_COLOR: LayerColor =
	WHITE_RGBA_COLOR;
