import {
	ListSortButtonsContainer,
	ListSortButton,
	ListSortRowInfoText,
} from 'PortfolioAnalytics/UI';
import styled from 'styled-components';
import { SortFieldButton } from './SortFieldButton';
import { SortField } from './types';
import { PortfolioShortWithAggregations } from './usePortfoliosWithAggregations';
import {
	ChevronDown as ChevronDownOriginal,
	ChevronUp as ChevronUpOriginal,
} from '@compstak/ui-kit';
import { SortDirection } from 'api';

type Props = {
	sortedPortfolios: PortfolioShortWithAggregations[];
	sortField: SortField;
	setSortField: React.Dispatch<React.SetStateAction<SortField>>;
	sortDirection: SortDirection;
	setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>;
};

export const PortfolioSidebarV2SortControl = ({
	sortedPortfolios,
	sortField,
	setSortField,
	sortDirection,
	setSortDirection,
}: Props) => {
	return (
		<ListSortContainer>
			<StyledListSortRowInfoText>
				{sortedPortfolios.length} portfolios
			</StyledListSortRowInfoText>
			<ListSortButtonsContainer>
				<SortFieldButton sortField={sortField} setSortField={setSortField} />
				<ListSortButton
					onClick={() => {
						setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
					}}
				>
					{sortDirection === 'desc' ? <ChevronDown /> : <ChevronUp />}
				</ListSortButton>
			</ListSortButtonsContainer>
		</ListSortContainer>
	);
};

const StyledListSortRowInfoText = styled(ListSortRowInfoText)`
	color: ${(p) => p.theme.colors.neutral.n50};
`;

const ListSortContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5em 1.25em;
`;

const ChevronUp = styled(ChevronUpOriginal)`
	color: ${({ theme }) => theme.colors.white.white};
`;

const ChevronDown = styled(ChevronDownOriginal)`
	color: ${({ theme }) => theme.colors.white.white};
`;
