import styled from 'styled-components';
import { MQB } from '@compstak/ui-kit';

const Container = styled.div`
	display: flex;
	gap: 0.5rem;
	@media only screen and (min-width: ${MQB.D_1536}) {
		gap: 0.75rem;
	}
`;

export const Styled = {
	Container,
};
